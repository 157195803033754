Translation = require 'components/mixins/translation'
{ vancouverFormat } = require 'lib/references_utils'

styles =
  reference:
    fontSize: '8pt'

ReferencesSummary = createReactClass
  displayName: 'ReferencesSummary'

  propTypes:
    references: PropTypes.instanceOf(Immutable.List).isRequired

  mixins: [
    Translation('es:recommendations.table')
  ]

  _getAuthorsString: (authors) ->
    authors.map (author) ->
      initial = author.getIn(['firstNames', 0])?.slice 0, 1
      lastName = author.get 'lastName'
      "#{lastName}, #{initial}"
    .join ', '

  renderReference: (ref, idx) ->
    authors = @_getAuthorsString ref.get 'authors'
    year = ref.get('year').match(/\d{4}/)?[0]
    title = ref.get 'title'
    publishedIn = ref.get 'publishedIn'

    <p key={idx} style={styles.reference}>
      <span>{idx + 1}. </span>
      <span>{vancouverFormat ref.toJS()}</span>
    </p>

  render: ->
    return null if @props.references.isEmpty()
    <div>
      <h1>{@i18n 'references_summary'}</h1>
      <div>
        {@props.references.map(@renderReference).toList()}
      </div>
    </div>

module.exports = ReferencesSummary
