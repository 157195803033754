var DbDoc, NMA_QUESTION_PREFIX, arrayPusher, nmaQuestionDocShape;

DbDoc = require('lib/db_docs/db_doc');

nmaQuestionDocShape = require('lib/db_docs/doc_shapes/nma_question_doc_shape');

NMA_QUESTION_PREFIX = require('lib/doc_prefixes').nmaQuestion;

arrayPusher = function(item) {
  return function(arr) {
    if (arr == null) {
      arr = [];
    }
    arr.push(item);
    return arr;
  };
};

module.exports = DbDoc(nmaQuestionDocShape, {
  dbSetup: {
    idPrefix: NMA_QUESTION_PREFIX
  },
  methods: {
    addOutcome: function(outcomeId) {
      return this.updateAt('outcomes')(arrayPusher(outcomeId));
    },
    deleteOutcome: function(outcomeId) {
      return this.updateAt('outcomes')(function(outcomes) {
        return _.without(outcomes, outcomeId);
      });
    },
    setOutcomes: function(outcomes) {
      return this.setAt('outcomes')(outcomes);
    },
    addIntervention: function(intervention) {
      return this.updateAt('interventions')(arrayPusher(intervention));
    },
    updateIntervention: function(interventionToUpdate) {
      return this.updateAt('interventions')(function(interventions) {
        return _.map(interventions, function(intervention) {
          if (intervention._id === interventionToUpdate._id) {
            return interventionToUpdate;
          } else {
            return intervention;
          }
        });
      });
    },
    deleteIntervention: function(interventionId) {
      return this.updateAt('interventions')(function(interventions) {
        return _.filter(interventions, function(intervention) {
          return intervention._id !== interventionId;
        });
      });
    },
    setInterventions: function(interventions) {
      return this.setAt('interventions')(interventions);
    },
    setExplanations: function(explanations) {
      return this.setAt('explanations')(explanations);
    },
    deleteExplanation: function(explanationId) {
      return this.updateAt('explanations')(function(currentExplanations) {
        return _.omit(currentExplanations, explanationId);
      });
    }
  }
});
