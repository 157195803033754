var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "      <tr class=\"outcome-diag-row\">\n        <th rowspan=\"3\" class=\"diagnostic-outcome-label\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.outcome", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"3\" class=\"no-of-studies-patients\">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_studies", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " (";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_patients", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ")\n        </th>\n        <th rowspan=\"3\" class=\"design-studies\">\n          <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.study_design", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"designStudies\"></span>\n        </th>\n        <th colspan=\"5\" rowspan=\"2\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.factors_decrease_coe", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"effect-head-cell\" colspan=\"6\"></th>\n        <th rowspan=\"3\" class=\"quality\">\n          <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.dta_coe", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"quality\"></span>\n        </th>\n        <th rowspan=\"3\" class=\"table-mode\">\n          <span class=\"content\">\n            <button class=\"table-mode\"></button>\n          </span>\n        </th>\n      </tr>\n      <tr class=\"outcome-diag-row\">\n          <th class=\"prevalence-1\" colspan=\"2\">\n            <span class=\"content\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n              <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence1 || (depth0 != null ? depth0.prevalence1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1","hash":{},"data":data}) : helper)))
    + "</span>%\n            </span>\n          </th>\n          <th class=\"prevalence-2\" colspan=\"2\">\n            <span class=\"content\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n              <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence2 || (depth0 != null ? depth0.prevalence2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2","hash":{},"data":data}) : helper)))
    + "</span>%\n            </span>\n          </th>\n          <th class=\"prevalence-3\" colspan=\"2\">\n            <span class=\"content\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n              <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence3 || (depth0 != null ? depth0.prevalence3 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3","hash":{},"data":data}) : helper)))
    + "</span>%\n            </span>\n          </th>\n      </tr>\n      <tr class=\"outcome-diag-row\">\n        <th class=\"risk-of-bias\">\n          <span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "\n          </span>\n          <span class=\"context-help-icon\" data-property=\"riskOfBias\"></span>\n        </th>\n        <th class=\"indirectness\">\n          <span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.indirectness", {"name":"i18n","hash":{},"data":data})))
    + "\n          </span>\n          <span class=\"context-help-icon\" data-property=\"indirectness\"></span>\n        </th>\n        <th class=\"inconsistency\">\n          <span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "\n          </span>\n          <span class=\"context-help-icon\" data-property=\"inconsistency\"></span>\n        </th>\n        <th class=\"imprecision\">\n          <span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.imprecision", {"name":"i18n","hash":{},"data":data})))
    + "\n          </span>\n          <span class=\"context-help-icon\" data-property=\"imprecision\"></span>\n        </th>\n        <th class=\"publication-bias\">\n          <span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.publication_bias", {"name":"i18n","hash":{},"data":data})))
    + "\n          </span>\n          <span class=\"context-help-icon\" data-property=\"publicationBias\"></span>\n        </th>\n          <th class=\"diagnostic-effect prevalence-1\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</th>\n          <th class=\"diagnostic-effect prevalence-1\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</th>\n          <th class=\"diagnostic-effect prevalence-2\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</th>\n          <th class=\"diagnostic-effect prevalence-2\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</th>\n          <th class=\"diagnostic-effect prevalence-3\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</th>\n          <th class=\"diagnostic-effect prevalence-3\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</th>\n      </tr>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "      <tr class=\"outcome-diag-row\">\n        <th rowspan=\"2\" class=\"diagnostic-outcome-label\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.outcome", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"no-of-studies-patients\">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_studies", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " (";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_patients", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ")\n        </th>\n        <th rowspan=\"2\" class=\"design-studies\">\n          <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.study_design", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"designStudies\"></span>\n        </th>\n        <th colspan=\"5\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.factors_decrease_coe", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"effect-head-cell\" colspan=\"3\"></th>\n        <th rowspan=\"2\" class=\"quality\">\n          <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.dta_coe", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"quality\"></span>\n        </th>\n        <th rowspan=\"2\" class=\"table-mode\">\n          <span class=\"content\">\n            <button class=\"table-mode\"></button>\n          </span>\n        </th>\n      </tr>\n      <tr class=\"outcome-diag-row\">\n        <th class=\"risk-of-bias\">\n          <span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "\n          </span>\n          <span class=\"context-help-icon\" data-property=\"riskOfBias\"></span>\n        </th>\n        <th class=\"indirectness\">\n          <span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.indirectness", {"name":"i18n","hash":{},"data":data})))
    + "\n          </span>\n          <span class=\"context-help-icon\" data-property=\"indirectness\"></span>\n        </th>\n        <th class=\"inconsistency\">\n          <span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "\n          </span>\n          <span class=\"context-help-icon\" data-property=\"inconsistency\"></span>\n        </th>\n        <th class=\"imprecision\">\n          <span>\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.imprecision", {"name":"i18n","hash":{},"data":data})))
    + "\n          </span>\n          <span class=\"context-help-icon\" data-property=\"imprecision\"></span>\n        </th>\n        <th class=\"other-considerations\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.other_considerations", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n          <th class=\"diagnostic-effect prevalence-1\">\n            <span class=\"content\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n              <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence1 || (depth0 != null ? depth0.prevalence1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1","hash":{},"data":data}) : helper)))
    + "</span>%\n            </span>\n          </th>\n          <th class=\"diagnostic-effect prevalence-2\">\n            <span class=\"content\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n              <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence2 || (depth0 != null ? depth0.prevalence2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2","hash":{},"data":data}) : helper)))
    + "</span>%\n            </span>\n          </th>\n          <th class=\"diagnostic-effect prevalence-3\">\n            <span class=\"content\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n              <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence3 || (depth0 != null ? depth0.prevalence3 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3","hash":{},"data":data}) : helper)))
    + "</span>%\n            </span>\n          </th>\n      </tr>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.diagnosticMeta, '', 'diagnosticMeta', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<table class=\"standard-table layer-one-single-3-prev-table outcomes-table outcomes-table-container\">\n  <thead>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </thead>\n\n  <tbody></tbody>\n</table>\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}