IconButton = require 'components/common/icon_button'
TextAreaWithApply = require 'components/common/text_area_with_apply'
{ useI18n } = require 'lib/react_utils'

EMPTY_MATERIAL = {
  text: '',
  description: '',
  link: ''
}

AdditionalResourceField = ({ onChange, value: resources, additionalProps }) ->

  i18n = useI18n('es:recommendations.metadataExtractionForm')

  onTextAreaApply = (index, key) -> (value) ->
    updatedPlr = resources[index]
    updatedPlr[key] = value
    onChange([resources.slice(0, index)..., updatedPlr, resources.slice(index + 1)...])

  onAddMaterial = ->
    onChange([resources..., _.clone(EMPTY_MATERIAL)])

  onMoveMaterial = (index, direction) -> ->
    if direction > 0
      if index < resources.length - 1
        onChange([resources.slice(0, index)..., resources[index + 1], resources[index], resources.slice(index + 2)...])
    else
      if index > 0
        onChange([resources.slice(0, index - 1)..., resources[index], resources[index - 1], resources.slice(index + 1)...])

  onDeleteMaterial = (index) -> ->
    onChange([resources.slice(0, index)..., resources.slice(index + 1)...])

  linkPlaceholder = i18n('placeholders.url')
  textfieldPlaceholder = i18n('placeholders.text')
  descriptionPlaceholder = i18n('placeholders.description')

  <div className="multiple-inputs">
    {_.map resources, ({ text, link, description }, index) ->
      <div className="multiple-inputs__input mb-20 p-5" key={index}>
        <div className="flex-grow">
        <div className="input__textarea mb-5">
            <TextAreaWithApply
              content={link}
              onApply={onTextAreaApply(index, 'link')}
              placeholder={linkPlaceholder}
            />
          </div>
          <div className="input__textarea mb-5">
            <TextAreaWithApply
              content={text}
              onApply={onTextAreaApply(index, 'text')}
              placeholder={textfieldPlaceholder}
            />
          </div>
          <div className="input__textarea mb-5">
            <TextAreaWithApply
              content={description}
              onApply={onTextAreaApply(index, 'description')}
              placeholder={descriptionPlaceholder}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <IconButton
            className="ml-10"
            iconName="arrow-up-icon"
            disabled={index is 0}
            onClick={onMoveMaterial(index, -1)} 
          />
          <IconButton
            className="ml-10"
            iconName="arrow-down-icon"
            disabled={index is resources.length - 1}
            onClick={onMoveMaterial(index, 1)}
          />
          <IconButton
            className="ml-10"
            iconName="remove"
            onClick={onDeleteMaterial(index)}
          />
        </div>
      </div>
    }
    <div className="multiple-inputs__add">
      <IconButton
        iconName="add"
        label={i18n additionalProps?.addBtnLabel || '/actions.add_resource'}
        labelPosition="right"
        onClick={onAddMaterial}
      />
    </div>
  </div>

AdditionalResourceField.propTypes =
  onChange: PropTypes.func.isRequired
  value: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string
  }))
  additionalProps: PropTypes.object

AdditionalResourceField.defaultProps =
  value: []
  additionalProps: {}

module.exports = AdditionalResourceField
