var ATTRS_TO_OMIT, FormsApiHelper, QuestionDoc, W, asyncHelper, calculateTitles, fetchOrCreateComparisonQuestionGroups, mediator;

QuestionDoc = require('lib/db_docs/question_doc');

mediator = require('mediator');

calculateTitles = require('lib/questions_helper').calculateTitles;

FormsApiHelper = require('base/lib/forms_api_helper').questionGeneration;

fetchOrCreateComparisonQuestionGroups = require('actions/async/comparisons_question_groups').fetchOrCreateComparisonQuestionGroups;

W = require('when/when');

ATTRS_TO_OMIT = ['adminComments', 'id', 'medianRating', 'memberComments', 'ratings'];

asyncHelper = {
  _apiHelper: FormsApiHelper,
  _formatRecipient: function(member) {
    var email, givenNames, lastName, name;
    givenNames = member.givenNames, lastName = member.lastName, email = member.email;
    name = ("" + (givenNames != null ? givenNames : '') + " " + lastName).trim();
    return "" + name + " <" + email + ">";
  },
  _createNotificationRequests: function(step, projectName, members, formIds) {
    var docHeader, emailTemplate, stepWithoutPrefix;
    stepWithoutPrefix = step.split('.')[1];
    emailTemplate = require("views/templates/emails/question_generation/" + stepWithoutPrefix);
    docHeader = {
      type: 'question_generation_notification',
      step: stepWithoutPrefix,
      status: 'new',
      subject: $.t("emails:question_generation." + stepWithoutPrefix + ".subject", {
        projectName: projectName
      }),
      body: emailTemplate({
        projectName: projectName
      })
    };
    return W.all(_.map(members.toList().toJS(), function(member, idx) {
      var additionalFields, formId, memberId, obligatoryFields, recipient, requestDoc;
      memberId = member._id;
      recipient = asyncHelper._formatRecipient(member);
      formId = _.isArray(formIds) ? formIds[idx] : formIds;
      obligatoryFields = {
        recipient: recipient,
        formId: formId
      };
      additionalFields = stepWithoutPrefix === 'brainstorming' ? {
        memberId: memberId
      } : {};
      requestDoc = _(docHeader).chain().clone().extend(obligatoryFields, additionalFields).value();
      return mediator.user.getRequests().create(requestDoc);
    }));
  },
  _oneDocumentPerMember: function(docHeader, members) {
    return members.toList().toJS().map(function(member) {
      var givenNames, lastName, _id;
      _id = member._id, givenNames = member.givenNames, lastName = member.lastName;
      return _(docHeader).chain().clone().extend({
        givenNames: givenNames,
        lastName: lastName,
        memberId: _id
      }).value();
    });
  },
  sendInitial: function(params) {
    var doc, members, projectName, questions, stepDocId, type;
    members = params.members, type = params.type, projectName = params.projectName, questions = params.questions;
    stepDocId = null;
    doc = {
      projectName: projectName,
      type: type,
      questions: questions,
      status: 'new'
    };
    return asyncHelper._apiHelper.saveFormDoc(doc).then(function(response) {
      stepDocId = response.id;
      return asyncHelper._createNotificationRequests(type, projectName, members, stepDocId);
    }).then(function() {
      return stepDocId;
    });
  },
  sendForRatingOrApproval: function(params) {
    var documents, members, projectName, questions, type;
    members = params.members, type = params.type, projectName = params.projectName, questions = params.questions;
    documents = asyncHelper._oneDocumentPerMember({
      type: type,
      questions: questions,
      projectName: projectName,
      status: 'new'
    }, members);
    return asyncHelper._apiHelper.bulkSaveFormDocs(documents).then(function(response) {
      var formsIds;
      formsIds = _(response).pluck('id');
      return asyncHelper._createNotificationRequests(type, projectName, members, formsIds).then(function() {
        return formsIds;
      });
    });
  },
  fetchBrainstormingResults: function(formsDocId) {
    return asyncHelper._apiHelper.getFormDoc(formsDocId).then(function(doc) {
      return doc.questions.map(function(q) {
        var brainstormingComments, memberComment, memberId, nonEmptyComments, _ref;
        if (!q.source) {
          q.source = 'brainstorming';
        }
        if (q.brainstormingMembersComments) {
          nonEmptyComments = {};
          _ref = q.brainstormingMembersComments;
          for (memberId in _ref) {
            memberComment = _ref[memberId];
            if (!_.isEmpty(memberComment)) {
              nonEmptyComments[memberId] = memberComment;
            }
          }
          if (!_.isEmpty(nonEmptyComments)) {
            q.memberComments = {
              'questions.brainstorming': {}
            };
            brainstormingComments = q.memberComments['questions.brainstorming'];
            for (memberId in nonEmptyComments) {
              memberComment = nonEmptyComments[memberId];
              brainstormingComments[memberId] = memberComment;
            }
          }
          delete q.brainstormingMembersComments;
        }
        return _.extend(q, calculateTitles(q));
      });
    });
  },
  fetchRatingResults: function(formsIds) {
    return asyncHelper._apiHelper.getFormDocs(formsIds).then(function(memberForms) {
      return memberForms.filter(function(form) {
        return form.status === 'completed' || form.userRatingStatus === 'completed';
      });
    }).then(function(memberForms) {
      return memberForms.reduce(function(result, form) {
        result[form.memberId] = form;
        return result;
      }, {});
    });
  },
  fetchApprovalResults: function(formsIds) {
    return asyncHelper._apiHelper.getFormDocs(formsIds).then(function(memberForms) {
      return memberForms.filter(function(form) {
        return form.status === 'completed' || form.userApproval === 'completed' || (form.approved != null);
      });
    });
  },
  closeBrainstorming: function(formsDocId) {
    return asyncHelper._apiHelper.getFormDoc(formsDocId).then(function(doc) {
      doc.status = 'closed';
      return asyncHelper._apiHelper.saveFormDoc(doc);
    }).then(function() {
      return asyncHelper.fetchBrainstormingResults(formsDocId);
    });
  },
  closeRating: function(formsDocId) {
    var completedForms;
    completedForms = [];
    return asyncHelper._apiHelper.getFormDocs(formsDocId).then(function(formDocs) {
      var doc, _i, _len;
      completedForms = formDocs.filter(function(doc) {
        return doc.status === 'completed' || doc.userRatingStatus === 'completed';
      });
      for (_i = 0, _len = formDocs.length; _i < _len; _i++) {
        doc = formDocs[_i];
        if (!doc.userRatingStatus) {
          doc.userRatingStatus = doc.status;
        }
        doc.status = 'closed';
      }
      return asyncHelper._apiHelper.bulkSaveFormDocs(formDocs);
    }).then(function() {
      return completedForms;
    });
  },
  closeApproval: function(formsIds, questions) {
    var projectId, submittedApprovals;
    projectId = mediator.project.id;
    submittedApprovals = {};
    return asyncHelper.fetchApprovalResults(formsIds).then(function(approvalResults) {
      submittedApprovals = approvalResults;
      return asyncHelper._apiHelper.getFormDocs(formsIds);
    }).then(function(formDocs) {
      var doc, _i, _len;
      for (_i = 0, _len = formDocs.length; _i < _len; _i++) {
        doc = formDocs[_i];
        doc.userApproval = doc.status;
        doc.status = 'closed';
      }
      return fetchOrCreateComparisonQuestionGroups().then(function(comparisonQuestionGroups) {
        var maxOrderNumber, oldQuestionsOrderingOrdering, questionsOrdering, questionsToSave;
        maxOrderNumber = Math.max(_.values(comparisonQuestionGroups.questionsOrdering) || []) || 1;
        questionsToSave = questions.map(function(question, idx) {
          return question.withMutations(function(q) {
            q.set('orderNumber', idx + maxOrderNumber);
            return q.set('_id', q.get('id'));
          });
        }).filter(function(q) {
          return q.get('type') !== 'freeform';
        }).map(function(q) {
          return R.omit(ATTRS_TO_OMIT, q.toJS());
        }).toJS();
        questionsOrdering = _.reduce(questionsToSave, function(acc, q) {
          acc[q._id] = q.orderNumber;
          return acc;
        }, {});
        oldQuestionsOrderingOrdering = comparisonQuestionGroups.questionsOrdering;
        comparisonQuestionGroups.questionsOrdering = _.extend({}, oldQuestionsOrderingOrdering, questionsOrdering);
        return QuestionDoc.at(mediator.project.id).createMany(questionsToSave).then(function() {
          return mediator.services.storePersistenceAdapter.save(projectId, comparisonQuestionGroups);
        }).then(function() {
          return asyncHelper._apiHelper.bulkSaveFormDocs(formDocs);
        });
      });
    }).then(function() {
      return submittedApprovals;
    });
  }
};

module.exports = asyncHelper;
