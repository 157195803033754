BaseTemplateSelection = require 'components/administration/templates/base_template_selection'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdTemplatesActions = require 'actions/etd_templates_actions'
EtdTemplatesStore = require 'stores/etd_templates_store'
FetchingSpinner = require 'components/mixins/fetching_spinner'
mediator = require 'mediator'
Modal = require 'components/common/modal'
SelectedTemplate = require 'components/administration/templates/selected_template'
Spinner = require 'components/common/spinner'
templateLanguages = require 'lib/etd_templates/templates_languages'
templatesDefinitions = require 'lib/etd_templates/templates_definitions'
TemplateSectionsSelection = require 'components/administration/templates/template_sections_selection'
Translation = require 'components/mixins/translation'
UserGuideDialog = require 'components/common/user_guide_dialog'
{ isAcpFeaturesOn } = require 'stores/utils/etd_template_helper'

EtdTemplates = createReactClass
  displayName: 'EtdTemplates'

  propTypes:
    dxTemplateName: PropTypes.string
    editingTemplate: PropTypes.string
    keyMessageTemplateName: PropTypes.string
    gpsTemplateName: PropTypes.string
    overarchingDxTemplateName: PropTypes.string
    overarchingTxTemplateName: PropTypes.string
    template: PropTypes.instanceOf(Immutable.Map)
    templateChanged: PropTypes.bool
    txTemplateName: PropTypes.string

  mixins: [
    CustomRenderMixin
    Translation('settings:etd_templates')
    FetchingSpinner
  ]

  getInitialState: ->
    templateId: ''
    isModalOpen: false

  changeBaseTemplate: (templateId, language) ->
    EtdTemplatesActions.prepareTemplate templateId, language if templateId
    @setState {templateId, language}

  useCurrentTemplate: ->
    EtdTemplatesActions.useTemplate @props.template.get('templateDef'),
      @props.template.get('templateData')

  isTemplateDefined: (templateType) ->
    if templateType
      @props["#{templateType}Template"].size > 0

  editTemplate: (templateType) ->
    template = @props["#{templateType}Template"]
    EtdTemplatesActions.prepareTemplate template
    EtdTemplatesActions.editTemplate templateType

  showTemplatesHelp: ->
    @setState isModalOpen: true

  hideTemplatesHelp: ->
    @setState isModalOpen: false

  mapTemplates: (templateType) ->
    acpFeaturesOn = isAcpFeaturesOn()
    templateType = switch templateType
      when 'goodPracticeStatement' then 'good-practice-statement'
      else templateType

    filter = (questionType) -> (templ) ->
      templateIdMatchesType = _.string.startsWith(templ.id, questionType)
      if acpFeaturesOn
        templateIdMatchesType
      else
        templateIdMatchesType and not _.string.endsWith templ.id, 'acp'
        
    mapper = (templ) =>
      {value: templ.id, text: @i18n "names.#{templ.id}"}
    _(templatesDefinitions()).filter(filter(templateType)).map(mapper)

  getTemplatesOptions: (showEmptyValue) ->
    initialOptions = if showEmptyValue
      ['']
    else
      []

    listTxTemplates = not @isTemplateDefined 'tx'
    listDxTemplates = not @isTemplateDefined 'dx'
    listGPSTemplates = mediator.services.switches.isServerSwitchOn 'gps' and
      not @isTemplateDefined 'goodPracticeStatement'

    listOverarchingDxTemplates = not @isTemplateDefined 'overarchingDx'
    listOverarchingTxTemplates = not @isTemplateDefined 'overarchingTx'

    cadthFeaturesOn = mediator.services.switches.isServerSwitchOn('cadthFeatures')
    listKeyMessageTemplates = cadthFeaturesOn and not @isTemplateDefined 'keyMessage'

    txTemplates = @mapTemplates('tx')
    dxTemplates = @mapTemplates('dx')
    overarchingTxTemplates = @mapTemplates('overarchingTx')
    overarchingDxTemplates = @mapTemplates('overarchingDx')
    keyMessageTemplates = @mapTemplates('keyMessage')
    gpsTemplates = @mapTemplates('goodPracticeStatement')

    acpFeaturesOn = isAcpFeaturesOn()

    filterer = (tpl) ->
      if acpFeaturesOn
        _.string.endsWith tpl.value, 'acp'
      else
        not _.string.endsWith tpl.value, 'acp'

    overarchingTxTemplates = _.filter overarchingTxTemplates, filterer
    overarchingDxTemplates = _.filter overarchingDxTemplates, filterer

    if listTxTemplates
      initialOptions = initialOptions.concat(
        txTemplates,
        {text: '────────', value: 0, disabled: true}
      )
    if listDxTemplates
      initialOptions = initialOptions.concat(
        dxTemplates
        {text: '────────', value: 1, disabled: true}
      )
    if listOverarchingTxTemplates
      initialOptions = initialOptions.concat(
        overarchingTxTemplates
        {text: '────────', value: 2, disabled: true}
      )
    if listOverarchingDxTemplates
      initialOptions = initialOptions.concat(
        overarchingDxTemplates
        {text: '────────', value: 3, disabled: true}
      )
    if listGPSTemplates
      initialOptions = initialOptions.concat(
        gpsTemplates
        {text: '────────', value: 4, disabled: true}
      )
    if listKeyMessageTemplates
      initialOptions = initialOptions.concat(
        keyMessageTemplates
      )
    initialOptions = if _.last(initialOptions).disabled
      initialOptions.slice(0, initialOptions.length - 1)
    else
      initialOptions

  renderFetched: ->

    dxTemplateDefined = @isTemplateDefined 'dx'
    overarchingDxTemplateDefined = @isTemplateDefined 'overarchingDx'
    overarchingTxTemplateDefined = @isTemplateDefined 'overarchingTx'
    txTemplateDefined = @isTemplateDefined 'tx'
    keyMessageTemplateDefined = @isTemplateDefined 'keyMessage'
    gpsTemplateDefined = @isTemplateDefined 'goodPracticeStatement'
    gpsFeatureOn = mediator.services.switches.isServerSwitchOn 'gps'
    cadthFeaturesOn = mediator.services.switches.isServerSwitchOn('cadthFeatures')

    <div className='etd-templates'>
      <p>
        {@i18n 'etd_templates_info'}
        <a className="etd-templates-help" onClick={@showTemplatesHelp}>
          {@i18n 'learn_about'}
        </a>
      </p>
      <div className="selected-templates">
      {if _.every([txTemplateDefined, dxTemplateDefined, overarchingDxTemplateDefined,
        overarchingTxTemplateDefined, keyMessageTemplateDefined, gpsTemplateDefined], (defined) -> defined is false)
          <p>{@i18n 'define_template_text'}</p>
        else
          commonProps =
            baseTemplateName: @props.templateName
            changeBaseTemplate: @changeBaseTemplate
            editingTemplate: @props.editingTemplate
            editTemplate: @editTemplate
            saveTemplateChanges: EtdTemplatesActions.saveTemplate
            SectionsSelectionComponent: TemplateSectionsSelection
            template: @props.template
            templateChanged: @props.templateChanged
            templateLanguages: templateLanguages

          <div>
            <SelectedTemplate
              templateType='tx'
              templateName={@props.txTemplateName}
              templateDescription={@i18n "template_for_tx"}
              templatesOptions={@mapTemplates('tx')}
              {...commonProps}
            />
            <SelectedTemplate
              templateType='dx'
              templateName={@props.dxTemplateName}
              templateDescription={@i18n "template_for_dx"}
              templatesOptions={@mapTemplates('dx')}
              {...commonProps}
            />
            <SelectedTemplate
              templateType='overarchingTx'
              templateName={@props.overarchingTxTemplateName}
              templateDescription={@i18n "template_for_overarchingTx"}
              templatesOptions={@mapTemplates('overarchingTx')}
              {...commonProps}
            />
            <SelectedTemplate
              templateType='overarchingDx'
              templateName={@props.overarchingDxTemplateName}
              templateDescription={@i18n "template_for_overarchingDx"}
              templatesOptions={@mapTemplates('overarchingDx')}
              {...commonProps}
            />
            {gpsFeatureOn and <SelectedTemplate
              templateType='goodPracticeStatement'
              templateName={@props.goodPracticeStatementTemplateName}
              templateDescription={@i18n "template_for_gps"}
              templatesOptions={@mapTemplates('goodPracticeStatement')}
              {...commonProps}
            />}
            {cadthFeaturesOn and <SelectedTemplate
              templateType='keyMessage'
              templateName={@props.keyMessageTemplateName}
              templateDescription={@i18n "template_for_keyMessage"}
              templatesOptions={@mapTemplates('keyMessage')}
              {...commonProps}
            />}
          </div>
      }
      {unless @props.editingTemplate
        <BaseTemplateSelection
          changeBaseTemplate={@changeBaseTemplate}
          listDxTemplates={not dxTemplateDefined}
          listKeyMessageTemplates={not keyMessageTemplateDefined and cadthFeaturesOn}
          listOverarchingDxTemplates={not overarchingDxTemplateDefined}
          listOverarchingTxTemplates={not overarchingTxTemplateDefined}
          listTxTemplates={not txTemplateDefined}
          listGPSTemplates={not gpsTemplateDefined and gpsFeatureOn}
          selectedTemplate={@state.templateId}
          selectedTemplateLanguage={@state.language}
          templateLanguages={templateLanguages}
          templatesOptions={@getTemplatesOptions(true)}
        />
      }
      {if @state.templateId and @props.template.size > 0 and not @props.editingTemplate
        <TemplateSectionsSelection
          autoUpdateName={true}
          changeBaseTemplate={@changeBaseTemplate}
          nameEditable={@props.templateChanged}
          template={@props.template}
          templateChanged={@props.templateChanged}
          templateName={@props.templateName}
          useCurrentTemplate={@useCurrentTemplate}
        />
      }
      </div>
      <Modal
        isOpen={@state.isModalOpen}
        closeButton={false}
        onClose={@hideTemplatesHelp}
        modalSize="large"
        title=""
        className="etd-templates-help-modal"
        >
        <UserGuideDialog onClose={@hideTemplatesHelp} section="etd-templates" />
      </Modal>
    </div>

module.exports = EtdTemplates
