Validation = require 'base/validation'
Tooltip = require 'components/common/tooltip'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ useRef } = React
{ string } = PropTypes


ValidationContainer = ({ children, className, errorMsg, tipJoint, targetJoint }) ->
  tooltipContainerRef = useRef()

  className = classNames 'validation-container', className, invalid: errorMsg

  <Tooltip
    targetJoint={targetJoint}
    tipJoint={tipJoint}
    tooltipContainer={tooltipContainerRef?.current}
    tooltipType="reactValidationError"
  >
    <div ref={tooltipContainerRef} className={className} title={errorMsg}>
      {children}
    </div>
  </Tooltip>

module.exports = ValidationContainer
