var PanelVoiceExportView, QuestionExportView, etdHelper, getAppendicesList, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

etdHelper = require('lib/etd_helper');

QuestionExportView = require('views/question_export_view');

template = require('views/templates/panel_voice_export');

getAppendicesList = require('stores/utils/panel_voice_helpers').getAppendicesList;

module.exports = PanelVoiceExportView = (function(_super) {
  __extends(PanelVoiceExportView, _super);

  function PanelVoiceExportView() {
    return PanelVoiceExportView.__super__.constructor.apply(this, arguments);
  }

  PanelVoiceExportView.prototype.id = 'panel-voice-export-dialog';

  PanelVoiceExportView.prototype.template = template;

  PanelVoiceExportView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  PanelVoiceExportView.prototype.initialize = function() {
    var _ref;
    PanelVoiceExportView.__super__.initialize.apply(this, arguments);
    this.exportDataOptions = (_ref = this.options.exportDataOptions) != null ? _ref : {};
    this.delegate('click', '.view-options input[type="radio"]', this.toggleSection);
    this.delegate('click', '.switcher--judgment-pane', this.togglePane);
    return this.delegate('click', '.switcher--results-pane', this.togglePane);
  };

  PanelVoiceExportView.prototype.toggleSection = function(e) {
    var checked, exportOptions, judgmentSwitcher, labelEl, resultsSwitcher, section;
    labelEl = $(e.target).closest('label');
    section = labelEl.attr('for');
    exportOptions = this.exportDataOptions[section];
    checked = !exportOptions.checked;
    judgmentSwitcher = labelEl.find('.switcher--judgment-pane');
    resultsSwitcher = labelEl.find('.switcher--results-pane');
    if (checked) {
      labelEl.addClass("selected");
    } else {
      labelEl.removeClass("selected");
    }
    labelEl.find('input[type="radio"]').attr('checked', checked);
    if (checked) {
      this.toggleSwitcher(judgmentSwitcher, checked, exportOptions.judgment.disabled);
      this.removeDisabled(resultsSwitcher);
    } else {
      this.toggleSwitcher(judgmentSwitcher, false, exportOptions.judgment.disabled);
      this.toggleSwitcher(resultsSwitcher, false, exportOptions.results.disabled);
    }
    _.extend(exportOptions, {
      checked: checked
    });
    return this.toggleDownloadButton();
  };

  PanelVoiceExportView.prototype.removeDisabled = function(switcher) {
    switcher.find('input[type="checkbox"]').prop('checked', false);
    return switcher.attr('disabled', false);
  };

  PanelVoiceExportView.prototype.toggleSwitcher = function(switcher, parentChecked, defaultDisabled) {
    if (defaultDisabled) {
      return;
    }
    switcher.find('input[type="checkbox"]').prop('checked', parentChecked);
    switcher.attr('disabled', !parentChecked);
    return this.updateExportOptions(switcher);
  };

  PanelVoiceExportView.prototype.isEtdExportViewChecked = function() {
    var etdViewInputs;
    if (this._isGPSExport()) {
      return true;
    }
    etdViewInputs = $('.view-options input[type="radio"]');
    return _(etdViewInputs).some(function(el) {
      return $(el).prop('checked');
    });
  };

  PanelVoiceExportView.prototype.togglePane = function(e) {
    var attr, key, section, sectionObj, sectionRadioInput, switcher, _ref;
    sectionRadioInput = $(e.target).closest('label.section-label').find('input[type="radio"]');
    if (!sectionRadioInput.prop("checked")) {
      return;
    }
    switcher = $(e.target).closest('label.switcher');
    if (!switcher || switcher.attr('disabled')) {
      return;
    }
    this.updateExportOptions(switcher);
    attr = switcher.attr('data-for');
    _ref = attr.split('-'), section = _ref[0], key = _ref[1];
    sectionObj = this.exportDataOptions[section];
    return switcher.find('input[type="checkbox"]').prop('checked', sectionObj[key].checked);
  };

  PanelVoiceExportView.prototype.updateExportOptions = function(switcher) {
    var attr, key, section, sectionObj, _ref;
    attr = switcher.attr('data-for');
    _ref = attr.split('-'), section = _ref[0], key = _ref[1];
    sectionObj = this.exportDataOptions[section];
    if (sectionObj[key].disabled) {
      return;
    }
    sectionObj[key].checked = !sectionObj[key].checked;
    return _.extend(this.exportDataOptions[section], sectionObj);
  };

  PanelVoiceExportView.prototype.download = function(e) {
    return PanelVoiceExportView.__super__.download.call(this, e, this.exportDataOptions);
  };

  PanelVoiceExportView.prototype.getTemplateData = function() {
    var additionalData, viewTypeName;
    viewTypeName = this._getViewType().name;
    additionalData = {
      appendices: getAppendicesList(this.model.get('_id')),
      etdViewOptions: this.exportDataOptions
    };
    return _(PanelVoiceExportView.__super__.getTemplateData.apply(this, arguments)).extend({
      viewTypeName: viewTypeName,
      votingStarted: this.options.votingStarted,
      isGps: this._isGPSExport(),
      title: $.t('projects:export_dialog.export_table_title', {
        viewName: $.t("es:outcome_view_type." + this.typeToi18nKeyMap[viewTypeName])
      })
    }, additionalData);
  };

  return PanelVoiceExportView;

})(QuestionExportView);
