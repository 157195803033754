var ArchieHelper, CellFootnotesAndReferencesView, DiagnosticAbsoluteDifferenceView, DiagnosticOutcomeView, Focusable, FullWypasCollectionView, HelpBoxView, HelpResources, ImportActions, OriginalEvidenceTableView, Outcome, OutcomeEditSofRowView, OutcomeLabelRowView, Outcomes, OutcomesView, QuestionView, TreatmentOutcomeExtraRowView, TreatmentOutcomeView, W, WysiwygFootnotes, mediator, narrativeModeInfoTemplate, outcomeCellTypes, outcomesKeyboardSupport,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ArchieHelper = require('lib/archie_helper');

CellFootnotesAndReferencesView = require('views/evidence_syntheses/cell_footnotes_and_references_view');

DiagnosticAbsoluteDifferenceView = require('views/evidence_syntheses/diagnostic_absolute_difference_view');

DiagnosticOutcomeView = require('views/evidence_syntheses/diagnostic_outcome_view');

Focusable = require('base/lib/traits/focusable');

FullWypasCollectionView = require('base/views/full_wypas_collection_view');

HelpBoxView = require('views/help_box_view');

HelpResources = require('lib/help_resources');

ImportActions = require('actions/import_actions');

mediator = require('mediator');

narrativeModeInfoTemplate = require('views/templates/hopscotch-narrative-mode');

OriginalEvidenceTableView = require('views/evidence_syntheses/original_evidence_table_view');

Outcome = require('models/outcome');

outcomeCellTypes = require('models/outcome/outcome_cell_types');

OutcomeEditSofRowView = require('views/evidence_syntheses/outcome_edit_sof_row_view');

OutcomeLabelRowView = require('views/evidence_syntheses/outcome_label_row_view');

Outcomes = require('models/outcomes');

outcomesKeyboardSupport = require('lib/outcomes_keyboard_support');

QuestionView = require('views/evidence_syntheses/question_view');

TreatmentOutcomeExtraRowView = require('views/evidence_syntheses/treatment_outcome_extra_row_view');

TreatmentOutcomeView = require('views/evidence_syntheses/treatment_outcome_view');

W = require('when');

WysiwygFootnotes = require('lib/traits/wysiwyg_footnotes');

module.exports = OutcomesView = (function(_super) {
  __extends(OutcomesView, _super);

  function OutcomesView() {
    this.showContextHelp = __bind(this.showContextHelp, this);
    this.outcomeDeleted = __bind(this.outcomeDeleted, this);
    this.itemAdded = __bind(this.itemAdded, this);
    this._toggleNarrativeRegularHeader = __bind(this._toggleNarrativeRegularHeader, this);
    this._tableModeChanged = __bind(this._tableModeChanged, this);
    this._keepRegularTableMode = __bind(this._keepRegularTableMode, this);
    return OutcomesView.__super__.constructor.apply(this, arguments);
  }

  OutcomesView.prototype.animationDuration = 10;

  OutcomesView.prototype.className = 'outcomes';

  OutcomesView.prototype.listSelector = '.outcomes-table tbody';

  OutcomesView.prototype.itemSelector = 'tr.outcome-row:not(.extra-item)';

  OutcomesView.prototype.extraItemSelector = "tr." + OutcomesView.prototype.extraItemClass;

  OutcomesView.prototype.autoRender = false;

  OutcomesView.prototype.listenToParentKeyEvents = true;

  OutcomesView.prototype.tableRedrawingProperties = ['designStudies', 'calculatedControl', 'lowControl', 'moderateControl', 'highControl'];

  OutcomesView.prototype.footnotesBindings = function() {
    var bindings, i, _i;
    bindings = {};
    for (i = _i = 1; _i <= 3; i = ++_i) {
      bindings["prevalence" + i] = [
        {
          selector: ".prevalence-" + i + " .content",
          method: 'after'
        }
      ];
    }
    return bindings;
  };

  OutcomesView.prototype.prevalenceBind = function(prevalenceNo) {
    return {
      observe: "prevalence" + prevalenceNo,
      onGet: function() {
        this.togglePrevalencesColumns();
        return " " + this.options.questionModel.get("prevalence" + prevalenceNo);
      }
    };
  };

  OutcomesView.prototype.prevalenceCharacteristicsBind = function(prevalenceNo) {
    return {
      observe: "prevalence" + prevalenceNo + "Characteristics",
      onGet: function() {
        return this.options.questionModel.get("prevalence" + prevalenceNo + "Characteristics");
      }
    };
  };

  OutcomesView.prototype.questionBindings = {
    'thead .prevalence-1 .prev-value': OutcomesView.prototype.prevalenceBind(1),
    'thead .prevalence-2 .prev-value': OutcomesView.prototype.prevalenceBind(2),
    'thead .prevalence-3 .prev-value': OutcomesView.prototype.prevalenceBind(3),
    'thead .prevalence-1 .prev-desc': OutcomesView.prototype.prevalenceCharacteristicsBind(1),
    'thead .prevalence-2 .prev-desc': OutcomesView.prototype.prevalenceCharacteristicsBind(2),
    'thead .prevalence-3 .prev-desc': OutcomesView.prototype.prevalenceCharacteristicsBind(3),
    'thead .effect-head-cell': {
      observe: 'absDenominator',
      onGet: function(value) {
        var absDenominator, labelKey, _ref;
        absDenominator = Number(value || 1000).toLocaleString();
        labelKey = (_ref = this.options.viewType.name) === 'PrintoutLayerOneSof' || _ref === 'PrintoutLayerTwoSof' ? 'es:outcome.diagnostic.no_of_results_per_patients' : 'es:outcome.diagnostic.effect_per_patients';
        return $.t(labelKey, {
          absDenominator: absDenominator
        });
      }
    }
  };

  OutcomesView.prototype.headerBindings = {
    'tr.outcome-one-row th.control .value, tr.outcome-sof-accp-row th.control .value': {
      observe: 'comparison',
      onGet: function(value) {
        return _.string.capitalize(value);
      }
    },
    'tr.outcome-one-row th.intervention .value': {
      observe: 'intervention',
      onGet: function(value) {
        return _.string.capitalize(value);
      }
    },
    'tr.outcome-sof-row-v4 th.intervention .value': {
      observe: 'intervention',
      onGet: function(value) {
        return _.string.capitalize(value);
      }
    },
    'tr.outcome-sof-row-v4 th.control .value': {
      observe: 'comparison',
      onGet: function(value) {
        return _.string.capitalize(value);
      }
    },
    'th.corresponding-risk .value': {
      observe: 'intervention',
      onGet: function(value) {
        return "" + ($.t('es:outcome.risk_with')) + " " + value;
      }
    },
    'tr.outcome-sof-row th.control .value, th.risk-control': {
      observe: 'comparison',
      onGet: function(value) {
        return "" + ($.t('es:outcome.risk_with')) + " " + value;
      }
    },
    'tr.outcome-sof-row.v3 th.with-intervention .value': {
      observe: 'intervention',
      onGet: function(value) {
        return "" + ($.t('es:outcome.with')) + " " + value;
      }
    },
    'tr.outcome-sof-row.v3 th.without-intervention .value': {
      observe: 'intervention',
      onGet: function(value) {
        return "" + ($.t('es:outcome.without')) + " " + value;
      }
    },
    'th.risk-difference-with-intervention .value': {
      observe: 'intervention',
      onGet: function(value) {
        return "" + ($.t('es:outcome.risk_difference_with')) + " " + value;
      }
    },
    'th.event-rates-with-control': {
      observe: 'comparison',
      onGet: function(value) {
        return "" + ($.t('es:outcome.risk_with')) + " " + value;
      }
    },
    'th.event-rates-with-intervention': {
      observe: 'intervention',
      onGet: function(value) {
        return "" + ($.t('es:outcome.risk_with')) + " " + value;
      }
    },
    'tr.outcome-accp-row th.risk-with-control .value': {
      observe: 'comparison',
      onGet: function(value) {
        return "" + ($.t('es:outcome.risk_with')) + " " + value;
      }
    }
  };

  OutcomesView.prototype.shortcuts = {
    "tab": "setNextActiveCell",
    "shift+tab": "setPrevActiveCell",
    "left": "setPrevActiveCell",
    "right": "setNextActiveCell",
    "up": "setRowUp",
    "down": "setRowDown",
    "enter": "editActiveCell",
    "ctrl+a": "addOutcome",
    "ctrl+e": "editActiveOutcome",
    "ctrl+u": "undoDeleteOutcome",
    "alt+f": "editActiveCellFootnotes"
  };

  _(OutcomesView.prototype).extend(outcomesKeyboardSupport);

  OutcomesView.prototype._narrativeModeInfo = function() {
    window.hopscotch.templates.narrativeModeInfo = narrativeModeInfoTemplate;
    window.hopscotch.setRenderer('narrativeModeInfo');
    hopscotch.startTour({
      id: 'narrative',
      showCloseBtn: false,
      i18n: {
        doneBtn: $.t('es:outcome.mode.change_to_narrative')
      },
      steps: [
        {
          title: $.t('es:outcome.mode.change_to_narrative'),
          content: $.t('es:outcome.mode.change_to_narrative_desc'),
          target: '.table-mode',
          placement: 'bottom',
          xOffset: -375,
          yOffset: -10,
          arrowOffset: 380,
          width: 400,
          showCTAButton: true,
          ctaLabel: $.t('actions.skip'),
          onCTA: this._keepRegularTableMode
        }
      ],
      onEnd: this._toggleNarrativeRegularHeader
    });
    return this.narrativeTourStarted = true;
  };

  OutcomesView.prototype.initialize = function() {
    var _ref;
    if (!this.options.viewType) {
      throw new Error("OutcomeViewType is not defined.");
    }
    if (!this.options.questionModel) {
      throw new Error("QuestionModel is not defined.");
    }
    this.viewType = this.options.viewType;
    this.template = require(this.viewType.tableTemplate);
    this.itemViewOptions = {
      template: require(this.viewType.rowTemplate),
      viewTypeName: this.viewType.name,
      previousViewTypeName: (_ref = this.options.previousViewType) != null ? _ref.name : void 0,
      questionModel: this.options.questionModel,
      short: this.options.short,
      className: "outcome-row " + ("" + (this.viewType.rowClassName ? this.viewType.rowClassName : ''))
    };
    this.collection = this.options.questionModel.get('outcomes');
    this.subscribeEvent('cellEditorOpened', this.cellEditorOpened);
    if (this.options.questionModel.isDiagnostic()) {
      this.itemView = DiagnosticOutcomeView;
    } else {
      this.itemView = TreatmentOutcomeView;
    }
    OutcomesView.__super__.initialize.apply(this, arguments);
    this.enable(Focusable);
    this.enable(WysiwygFootnotes);
    this.delegate('click', 'button.add-outcome', this.addOutcome);
    this.delegate('click', 'button.import-outcome', this._importOutcome);
    this.delegate('click', 'table thead', this.showContextHelp);
    if (typeof this.options.showTableViewsMenu === 'function') {
      this.delegate('click', 'button.table-mode', this.options.showTableViewsMenu);
    }
    this.on('!fixLayout', (function(_this) {
      return function() {
        _this.options.parentView.trigger('!fixLayout');
        if (_this.currentOutcomeEditView) {
          return _this.scrollPage(_this.currentOutcomeEditView.$el);
        }
      };
    })(this));
    this.modelBind('add', this.setCellsMatrix);
    this.modelBind('change:type', this.setCellsMatrix);
    this.modelBind('remove', this.setCellsMatrix);
    this.modelBind('reset', this.setCellsMatrix);
    this.modelBind(_(this.tableRedrawingProperties).map(function(prop) {
      return "change:" + prop;
    }).join(' '), this.setCellsMatrix);
    return this.options.questionModel.on('change:$tableMode', this._tableModeChanged);
  };

  OutcomesView.prototype.getTemplateData = function() {
    var data, outcomes, _ref, _ref1, _ref2, _ref3;
    data = OutcomesView.__super__.getTemplateData.apply(this, arguments);
    data.activeWorkspace = mediator.activeWorkspace;
    data.notes = this.options.questionModel.getFootnotesObjectsArray();
    data.short = (_ref = this.options) != null ? _ref.short : void 0;
    data.conjunctions = (_ref1 = this.options) != null ? _ref1.conjunctions : void 0;
    data.tableTypeName = this.viewType.name;
    data.importDisabled = mediator.activeWorkspace === ArchieHelper.COCHRANE_ORGANIZATION_ID;
    data.acpServer = mediator.services.switches.isServerSwitchOn('acpFeatures');
    _(data).extend(_(this.options.questionModel.attributes).pick(['date', 'question', 'settings', 'authors', 'intervention', 'comparison', 'healthProblemOrPopulation', 'indexTest', 'comparatorTest', 'comparatorTestPresent', 'comparatorTestCutOff', 'referenceTest', 'referenceTestThreshold', 'tableTitle', 'bibliography', 'sourceOfDxData', 'pooledSensitivity', 'pooledSensitivityFrom', 'pooledSensitivityTo', 'pooledSpecificity', 'pooledSpecificityFrom', 'pooledSpecificityTo', 'pooledSensitivityComparison', 'pooledSensitivityComparisonFrom', 'pooledSensitivityComparisonTo', 'pooledSpecificityComparison', 'pooledSpecificityComparisonFrom', 'pooledSpecificityComparisonTo', 'pooledSensitivityRangeFrom', 'pooledSensitivityRangeTo', 'pooledSensitivityComparisonRangeFrom', 'pooledSensitivityComparisonRangeTo', 'pooledSpecificityRangeFrom', 'pooledSpecificityRangeTo', 'pooledSpecificityComparisonRangeFrom', 'pooledSpecificityComparisonRangeTo', 'targetCondition', 'prevalence1', 'prevalence2', 'prevalence3', 'prevalence1Characteristics', 'prevalence2Characteristics', 'prevalence3Characteristics', '$tableMode']));
    if ((_ref2 = data.tableTypeName) === 'PrintoutLayerOneSof' || _ref2 === 'PrintoutLayerTwoSof') {
      outcomes = this.options.questionModel.get('outcomes').toArray();
      if (data.tableTypeName === 'PrintoutLayerOneSof') {
        outcomes = outcomes.slice(0, 5);
      }
      data.noComments = _.every(outcomes, function(o) {
        return _.isEmpty(o.get('comments'));
      });
    }
    if ((_ref3 = data.tableTypeName) === 'PrintoutSOFv4') {
      data.tableTitle = $.t('es:question.templates.SOF_V4', {
        intervention: data.intervention,
        comparison: data.comparison,
        healthProblemOrPopulation: data.healthProblemOrPopulation
      });
    }
    return data;
  };

  OutcomesView.prototype._keepRegularTableMode = function() {
    this.options.questionModel.set({
      '$tableMode': 'regular'
    });
    return hopscotch.endTour();
  };

  OutcomesView.prototype._tableModeChanged = function(question, newMode, options) {
    if (options.auto) {
      switch (newMode) {
        case 'narrative':
          if (mediator.user.wasNarrativeInfoShownForQuestion(this.options.questionModel)) {
            return question.set('$tableMode', question.previous('$tableMode'));
          } else {
            this._narrativeModeInfo();
            return mediator.user.narrativeInfoShownForQuestion(this.options.questionModel);
          }
          break;
        case 'regular':
          return this._toggleNarrativeRegularHeader();
      }
    } else {
      return this._toggleNarrativeRegularHeader();
    }
  };

  OutcomesView.prototype._toggleNarrativeRegularHeader = function(saveQuestion) {
    if (saveQuestion == null) {
      saveQuestion = true;
    }
    if (this.options.questionModel.get('$tableMode') === 'narrative') {
      this.$('.regular-header').hide();
      this.$('.narrative-header').show();
      this.$('.standard-table').addClass('narrative');
      this.$('.standard-table').removeClass('regular');
    } else {
      this.$('.regular-header').show();
      this.$('.narrative-header').hide();
      this.$('.standard-table').addClass('regular');
      this.$('.standard-table').removeClass('narrative');
    }
    if (saveQuestion) {
      return this.options.questionModel.save().otherwise(mediator.dialogs.fatalError);
    }
  };

  OutcomesView.prototype.afterRender = function() {
    var outcomeView, questionView, _i, _len, _ref;
    OutcomesView.__super__.afterRender.apply(this, arguments);
    _ref = this.subviews;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      outcomeView = _ref[_i];
      if (typeof outcomeView.fixLayout === "function") {
        outcomeView.fixLayout();
      }
    }
    this.setCellsMatrix();
    if (!this.activeCell) {
      this.setActiveCell(0, 0);
    }
    this.stickit(this.options.questionModel, _.extend({}, this.questionBindings, this.headerBindings));
    if (this.viewType.questionPresent) {
      questionView = new QuestionView({
        model: this.options.questionModel,
        container: '.questions-view ul',
        tableTitle: true
      });
      questionView.parentView = this;
      this.subview('question', questionView);
      questionView.render();
    }
    mediator.publish('outcomesViewRendered');
    this._toggleNarrativeRegularHeader(false);
    window.gdt.storage.get('gdtLanguage').then((function(_this) {
      return function(lng) {
        return _this.$('table').addClass("lang-" + lng);
      };
    })(this));
    if (this.options.adolopedModel) {
      return this.subview('originalEvidenceTableView', new OriginalEvidenceTableView({
        adolopedModel: this.options.adolopedModel,
        parentView: this.options.parentView
      }));
    }
  };

  OutcomesView.prototype.itemAdded = function(item, collection, options) {
    if (options == null) {
      options = {};
    }
    this.newItem = item;
    this.renderAndInsertItem(item, options.index);
    return this.afterItemAdded(item, options);
  };

  OutcomesView.prototype.afterItemAdded = function(item, options) {
    var _ref;
    if (!(item.id || options.external)) {
      if ((_ref = this.currentOutcomeEditView) != null) {
        _ref.saveAndHideEdit();
      }
      switch (this.viewType.name) {
        case 'OneRow':
        case 'ACCP':
        case 'Prognosis':
          this.extraItemSubview(item, 'labelRow').showOutcomeEditView();
          break;
        case 'SOF':
        case 'SOF_ACCP':
        case 'SOF_v3':
        case 'SOF_v4':
          this.getItemView(item).editSofOutcome();
      }
    }
    return this.options.parentView.trigger('!fixLayout');
  };

  OutcomesView.prototype.renderLabelRow = function(item, itemView, colspan) {
    var labelViewOptions;
    labelViewOptions = {
      model: item,
      colspan: colspan,
      questionModel: this.options.questionModel,
      viewTypeName: this.viewType.name
    };
    return this.renderExtraItemSubview(item, 'labelRow', OutcomeLabelRowView, labelViewOptions, 'before');
  };

  OutcomesView.prototype.renderEditSofOutcomeRow = function(item, itemView, colspan) {
    var viewOptions;
    if (!(this.viewType.name.toLowerCase().indexOf('printout') > -1)) {
      viewOptions = {
        model: item,
        colspan: colspan,
        hidden: true,
        questionModel: this.options.questionModel,
        viewTypeName: this.viewType.name
      };
      return this.renderExtraItemSubview(item, 'editSofOutcomeRow', OutcomeEditSofRowView, viewOptions, 'after');
    }
  };

  OutcomesView.prototype.renderAbsuluteDifferenceForDiagnosticOutcome = function(item, view) {
    var _ref;
    if (this.options.questionModel.get('comparatorTestPresent') && ((_ref = item.get('name')) === 'TP' || _ref === 'FN' || _ref === 'TN' || _ref === 'FP')) {
      return this.renderExtraItemSubview(item, 'diagnosticAbsoluteDifferenceView', DiagnosticAbsoluteDifferenceView, {
        model: item,
        questionModel: this.options.questionModel
      });
    }
  };

  OutcomesView.prototype.renderExtraItemViews = function(item, itemView) {
    var _ref, _ref1, _ref2, _ref3;
    switch (this.viewType.name) {
      case 'OneRow':
      case 'PrintoutOneRow':
        this.renderLabelRow(item, itemView, 13);
        if ((_ref = item.get('metaType')) !== 'NOT_REPORTED' && _ref !== 'NOT_MEASURED') {
          return this.renderExtraControlCells(item, itemView);
        } else {
          return this.removeAllExtraControlRows(item);
        }
        break;
      case 'SOF':
      case 'SOF_ACCP':
      case 'PrintoutSOFACCP':
      case 'PrintoutSOF':
      case 'SOF_v3':
      case 'PrintoutSOFv3':
        this.renderEditSofOutcomeRow(item, itemView, 8);
        if ((_ref1 = item.get('metaType')) !== 'NOT_REPORTED' && _ref1 !== 'NOT_MEASURED') {
          return this.renderExtraControlCells(item, itemView);
        } else {
          return this.removeAllExtraControlRows(item);
        }
        break;
      case 'SOF_v4':
      case 'PrintoutSOFv4':
        this.renderEditSofOutcomeRow(item, itemView, 9);
        if ((_ref2 = item.get('metaType')) !== 'NOT_REPORTED' && _ref2 !== 'NOT_MEASURED') {
          return this.renderExtraControlCells(item, itemView);
        } else {
          return this.removeAllExtraControlRows(item);
        }
        break;
      case 'ACCP':
      case 'PrintoutGRADEACCP':
      case 'Prognosis':
      case 'PrintoutPrognosis':
        this.renderLabelRow(item, itemView, 12);
        if ((_ref3 = item.get('metaType')) !== 'NOT_REPORTED' && _ref3 !== 'NOT_MEASURED') {
          return this.renderExtraControlCells(item, itemView);
        } else {
          return this.removeAllExtraControlRows(item);
        }
        break;
      case 'PrintoutLayerOne':
      case 'PrintoutLayerTwo':
      case 'PrintoutLayerOneSof':
      case 'PrintoutLayerTwoSof':
      case 'LayerOne':
      case 'LayerTwo':
      case 'LayerOneSof':
      case 'LayerTwoSof':
        return this.renderAbsuluteDifferenceForDiagnosticOutcome(item, itemView);
    }
  };

  OutcomesView.prototype.removeAllExtraControlRows = function(item) {
    var control, controlList, _i, _len, _results;
    controlList = ['calculatedControl', 'lowControl', 'moderateControl', 'highControl'];
    _results = [];
    for (_i = 0, _len = controlList.length; _i < _len; _i++) {
      control = controlList[_i];
      if (item.get(control) === true) {
        _results.push(this.removeExtraControlRow(item, control));
      }
    }
    return _results;
  };

  OutcomesView.prototype.removeExtraControlRow = function(item, control) {
    this.removeSubview(this.getExtraItemSubviewName(item, "" + control));
    return this.removeSubview(this.getExtraItemSubviewName(item, "" + control + "Label"));
  };

  OutcomesView.prototype.firstControlToDisplay = function(item) {
    var controlList, first, viewName;
    viewName = this.viewType.name;
    if (item.get('type') === 'time_to_event' && (viewName === 'OneRow' || viewName === 'PrintoutOneRow')) {
      return 'calculatedControl';
    }
    controlList = ['calculatedControl', 'lowControl', 'moderateControl', 'highControl'];
    first = _([item.get('calculatedControl'), item.get('lowControl'), item.get('moderateControl'), item.get('highControl')]).indexOf(true);
    return controlList[first];
  };

  OutcomesView.prototype.renderExtraControlCells = function(item, itemView) {
    var calculatedControlView, displayControl, lowControlView, moderateControlView, renderFunction, _ref;
    renderFunction = (function() {
      switch (this.viewType.name) {
        case 'SOF':
        case 'PrintoutSOF':
          itemView._template = "sof_control_extra_row";
          return this.renderExtraControlRowWithLabel;
        case 'SOF_ACCP':
        case 'PrintoutSOFACCP':
          itemView._template = "sof_accp_risk_extra_row";
          return this.renderExtraControlRowWithLabel;
        case 'SOF_v3':
        case 'PrintoutSOFv3':
          itemView._template = "sof_v3_control_extra_row";
          return this.renderExtraControlRowWithLabel;
        case 'SOF_v4':
        case 'PrintoutSOFv4':
          itemView._template = "sof_v4_control_extra_row";
          return this.renderExtraControlRowWithLabel;
        case 'OneRow':
        case 'PrintoutOneRow':
        case 'Prognosis':
        case 'PrintoutPrognosis':
          return this.renderExtraControlRow;
        case 'ACCP':
        case 'PrintoutGRADEACCP':
          itemView._template = "accp_control_extra_row";
          return this.renderExtraControlRowWithLabel;
      }
    }).call(this);
    displayControl = (function(_this) {
      return function(controlName, prevView) {
        var calculatedControlOnly, dichOrTimeOutcome, _ref;
        calculatedControlOnly = itemView.calculatedControlOnly();
        dichOrTimeOutcome = (_ref = item.get('type')) === 'dich' || _ref === 'time_to_event';
        if (item.get(controlName) && dichOrTimeOutcome && !calculatedControlOnly) {
          renderFunction.call(_this, item, itemView, controlName, prevView);
          return controlName;
        } else {
          _this.removeExtraControlRow(item, controlName);
          return null;
        }
      };
    })(this);
    calculatedControlView = displayControl('calculatedControl', null);
    lowControlView = displayControl('lowControl', calculatedControlView);
    moderateControlView = displayControl('moderateControl', lowControlView != null ? lowControlView : calculatedControlView);
    return displayControl('highControl', (_ref = moderateControlView != null ? moderateControlView : lowControlView) != null ? _ref : calculatedControlView);
  };

  OutcomesView.prototype.renderExtraControlRowWithLabel = function(item, itemView, control, prev) {
    var firstControlToDisplay, labelParent, preFix, viewOptions;
    if (prev == null) {
      prev = null;
    }
    preFix = _.string.rtrim(control, "Control");
    preFix = preFix === 'calculated' ? 'main' : preFix;
    this.fixRowspans(itemView);
    viewOptions = {
      model: item,
      viewTypeName: this.viewType.name,
      preFix: preFix,
      template: require("views/templates/evidence_syntheses/" + itemView._template),
      questionModel: this.options.questionModel,
      tagName: 'tr',
      className: "outcome-row " + (this.viewType.rowClassName ? this.viewType.rowClassName : void 0) + " " + control
    };
    this.renderExtraItemSubview(item, control, TreatmentOutcomeExtraRowView, viewOptions, 'after', prev);
    labelParent = control;
    firstControlToDisplay = this.firstControlToDisplay(item);
    if (firstControlToDisplay !== control) {
      return this.renderExtraControlLabelRow(item, itemView, control, labelParent);
    } else {
      return this.removeSubview(this.getExtraItemSubviewName(item, "" + control + "Label"));
    }
  };

  OutcomesView.prototype.renderExtraControlLabelRow = function(item, itemView, control, prev) {
    var controlLabel, viewOptions;
    if (prev == null) {
      prev = null;
    }
    controlLabel = _.string.rtrim(control, "Control");
    this.fixRowspans(itemView);
    viewOptions = {
      model: item,
      controlLabel: controlLabel,
      template: require("views/templates/evidence_syntheses/control_label_extra_row"),
      questionModel: this.options.questionModel,
      tagName: 'tr',
      className: "outcome-row " + (this.viewType.rowClassName ? this.viewType.rowClassName : void 0) + " " + control + "Label",
      viewTypeName: this.viewType.name
    };
    return this.renderExtraItemSubview(item, "" + control + "Label", TreatmentOutcomeExtraRowView, viewOptions, 'before', prev);
  };

  OutcomesView.prototype.isCaseControlType = function(item) {
    var designStudies, _ref;
    designStudies = (_ref = item.get('designStudies')) != null ? _ref.value : void 0;
    return designStudies === 'case_control_studies' || designStudies === 'case_control_and_other_combined';
  };

  OutcomesView.prototype.renderExtraControlRow = function(item, itemView, control, prev) {
    var caseControl, firstControlToDisplay, preFix, viewOptions;
    if (prev == null) {
      prev = null;
    }
    firstControlToDisplay = this.firstControlToDisplay(item);
    caseControl = this.isCaseControlType(item);
    if (prev === firstControlToDisplay && caseControl === !true) {
      prev = null;
    }
    if (firstControlToDisplay !== control || caseControl === true) {
      preFix = _.string.rtrim(control, "Control");
      this.fixRowspans(itemView);
      viewOptions = {
        model: item,
        viewTypeName: "OneRow",
        preFix: preFix,
        template: require("views/templates/evidence_syntheses/one_row_control_extra_row"),
        questionModel: this.options.questionModel,
        tagName: 'tr',
        className: "outcome-row " + (this.viewType.rowClassName ? this.viewType.rowClassName : void 0) + " " + control
      };
      return this.renderExtraItemSubview(item, "" + control, TreatmentOutcomeExtraRowView, viewOptions, 'after', prev);
    } else {
      return this.removeExtraControlRow(item, control);
    }
  };

  OutcomesView.prototype.fixRowspans = function(itemView) {
    var calculatedControlOnly, rowspanClasses, _ref;
    rowspanClasses = ['case-control', 'case-control-absolute', 'control', 'control-label', 'absolute-effect', 'corresponding-risk', 'risk-with-control', 'risk-difference-with-intervention', 'difference'];
    calculatedControlOnly = itemView.calculatedControlOnly();
    if (calculatedControlOnly) {
      return;
    }
    if ((_ref = this.viewType.name) === 'ACCP' || _ref === 'PrintoutGRADEACCP') {
      rowspanClasses = _.without(rowspanClasses, 'case-control');
    }
    if (itemView.model.get('type') === 'time_to_event') {
      rowspanClasses.push('intervention');
    }
    return _(itemView.$el.find('td')).map(function(td) {
      if (!_.intersection($(td).attr('class').split(' '), rowspanClasses).length) {
        return $(td).prop('rowspan', $(td).prop('rowspan') + 1);
      }
    });
  };

  OutcomesView.prototype.insertView = function(item, view, index, enableAnimation) {
    if (index == null) {
      index = null;
    }
    if (enableAnimation == null) {
      enableAnimation = true;
    }
    OutcomesView.__super__.insertView.apply(this, arguments);
    if (this.newItem) {
      return this.scrollPage(this.getItemViewByCid(this.newItem.cid).$el);
    }
  };

  OutcomesView.prototype.renderOutcomeRowsForItem = function(item) {
    var itemView, _ref;
    itemView = this.getItemView(item);
    itemView.render();
    if ((_ref = item.get('metaType')) !== 'NOT_REPORTED' && _ref !== 'NOT_MEASURED') {
      return this.renderExtraControlCells(item, itemView);
    } else {
      return this.removeAllExtraControlRows(item);
    }
  };

  OutcomesView.prototype.filterer = function(item) {
    var _ref, _ref1;
    if (this.options.questionModel.isDiagnostic() && ((_ref = this.viewType.name) === 'LayerOne' || _ref === 'LayerOneSof' || _ref === 'PrintoutLayerOneSof' || _ref === 'PrintoutLayerOne')) {
      return (_ref1 = item.get('name')) === 'TP' || _ref1 === 'FN' || _ref1 === 'TN' || _ref1 === 'FP';
    } else {
      return true;
    }
  };

  OutcomesView.prototype.togglePrevalencesColumns = function() {
    var i, prev, prevalencesColspan, prevsToHide, prevsToShow, pth, pts, qm, _i, _j, _k, _len, _len1, _len2, _ref, _ref1;
    qm = this.options.questionModel;
    prevsToShow = [];
    prevsToHide = [];
    _ref = [qm.get("prevalence1"), qm.get("prevalence2"), qm.get("prevalence3")];
    for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
      prev = _ref[i];
      if (prev || _(prev).isNumber()) {
        prevsToShow.push(i + 1);
      } else {
        prevsToHide.push(i + 1);
      }
    }
    prevalencesColspan = qm.get('comparatorTestPresent') ? prevsToShow.length * 2 : prevsToShow.length;
    this.$('thead .effect-head-cell').prop('colspan', prevalencesColspan);
    this.$('tr .inconclusive-prevalences-desc').prop('colspan', prevalencesColspan);
    this.$('tr .complications-prevalences-desc').prop('colspan', prevalencesColspan);
    this.$('table').removeClass('prevs-count-1 prevs-count-2 prevs-count-3').addClass("prevs-count-" + prevsToShow.length);
    for (_j = 0, _len1 = prevsToShow.length; _j < _len1; _j++) {
      pts = prevsToShow[_j];
      this.$(".prevalence-" + pts).show();
    }
    for (_k = 0, _len2 = prevsToHide.length; _k < _len2; _k++) {
      pth = prevsToHide[_k];
      this.$(".prevalence-" + pth).hide();
    }
    return (_ref1 = this.options.parentView) != null ? _ref1.trigger('!fixLayout') : void 0;
  };

  OutcomesView.prototype.editActiveCellFootnotes = function() {
    this.getItemViewByIndex(this.activeCell.row - 1).showCellFootnotesAndReferencesView();
    return false;
  };

  OutcomesView.prototype.addOutcome = function() {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        if (_this.undoNotifier) {
          _this.undoNotifier.destroy();
        }
        if (_this.options.questionModel.get('$tableMode') === 'narrative') {
          _this.collection.create({
            'type': 'narrative'
          });
        } else {
          _this.collection.create();
        }
        if (!_this.activeCell) {
          _this.setActiveCell(0, 0);
        }
        return false;
      };
    })(this));
  };

  OutcomesView.prototype._importOutcome = function() {
    var promise;
    if (this.undoNotifier) {
      this.undoNotifier.destroy();
    }
    promise = this.options.questionModel.get('$tableMode') === 'narrative' ? this._switchToRegularForImport() : W.resolve(true);
    return promise.then((function(_this) {
      return function(doContinue) {
        if (!doContinue) {
          return;
        }
        return ImportActions.openImportModal({
          model: _this.options.questionModel,
          importType: 'outcome'
        });
      };
    })(this));
  };

  OutcomesView.prototype._switchToRegularForImport = function() {
    return W.promise((function(_this) {
      return function(resolve) {
        return mediator.dialogs.confirm({
          title: $.t('es:outcome.mode.change_to_regular'),
          message: $.t('es:outcome.mode.change_to_regular_import_desc'),
          buttons: [
            {
              'data-role': 'no',
              text: $.t('es:outcome.mode.keep_narrative_mode_import')
            }, {
              'data-role': 'yes',
              text: $.t('es:outcome.mode.change_to_regular_import')
            }
          ]
        }, function(change) {
          if (change) {
            _this.options.questionModel.set('$tableMode', 'regular', {
              auto: true
            });
          }
          return resolve(change);
        });
      };
    })(this));
  };

  OutcomesView.prototype.outcomeDeleted = function(e) {
    if (this.undoNotifier) {
      this.undoNotifier.destroy();
    }
    this.undoActive = true;
    this.newItem = void 0;
    this.undoNotifier = mediator.dialogs.undo($.t('es:outcome.deleted')).on('click:undo', (function(_this) {
      return function() {
        return _this.undoDeleteOutcome();
      };
    })(this));
    return mediator.setFocus();
  };

  OutcomesView.prototype.undoDeleteOutcome = function() {
    var colNo, model, outcomeIds, questionModel, revManWebDeletedOutcomeIds, rowNo, _i, _len, _ref, _ref1;
    if (!this.undoActive) {
      return;
    }
    this.undoNotifier.destroy();
    rowNo = this.activeCell.row;
    colNo = this.activeCell.col;
    _ref = this.collection.models;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      model = _ref[_i];
      model.unsubscribeAllEvents();
    }
    this.collection.restore();
    this.collection.trigger('restore');
    this.undoActive = false;
    questionModel = this.collection.parentModel;
    if (questionModel.get('revManWebReviewId') != null) {
      outcomeIds = this.collection.map(function(outcome) {
        return outcome.id;
      });
      revManWebDeletedOutcomeIds = (_ref1 = questionModel.get('revManWebDeletedOutcomeIds')) != null ? _ref1 : [];
      revManWebDeletedOutcomeIds = _.filter(revManWebDeletedOutcomeIds, function(outcomeId) {
        return !_.contains(outcomeIds, outcomeId);
      });
      questionModel.set('revManWebDeletedOutcomeIds', revManWebDeletedOutcomeIds);
    }
    questionModel.set('outcomes', this.collection);
    questionModel.save();
    this.setActiveCell(rowNo, colNo);
    this.options.parentView.trigger('!fixLayout');
    mediator.dialogs.success($.t('es:outcome.delete_undone'));
    return false;
  };

  OutcomesView.prototype.showCellFootnotesAndReferencesView = function(outcome, cell, property, options) {
    var footnotesView;
    footnotesView = new CellFootnotesAndReferencesView({
      questionModel: this.options.questionModel,
      footnotesBindingModel: outcome,
      property: property
    });
    options = options != null ? options : {};
    return this.options.parentView.showFootnotesSubview(footnotesView, _(options).extend({
      cell: cell,
      container: this.container
    }));
  };

  OutcomesView.prototype.cellEditorOpened = function(editView, $cell) {
    var _ref;
    this.subview("editView-" + editView.cid, editView);
    if ((_ref = this.currentEditView) != null) {
      _ref.off('showHelpInfo', this.showContextHelp);
    }
    this.currentEditView = editView;
    this.currentEditView.on('showHelpInfo', this.showContextHelp);
    return this.setActiveCell($cell);
  };

  OutcomesView.prototype.dispose = function() {
    var _ref, _ref1;
    if (this.undoNotifier) {
      this.undoNotifier.destroy();
    }
    if ((_ref = this.editView) != null) {
      _ref.dispose();
    }
    this.unstickit(this.options.questionModel);
    this.options.questionModel.off('change:$tableMode', this._tableModeChanged);
    if (this.narrativeTourStarted) {
      hopscotch.endTour();
    }
    this.cellsMapping = null;
    this.activeCell = null;
    if ((_ref1 = this.currentEditView) != null) {
      _ref1.off('showHelpInfo', this.showContextHelp);
    }
    this.currentEditView = null;
    return OutcomesView.__super__.dispose.apply(this, arguments);
  };

  OutcomesView.prototype.editActiveOutcome = function(e) {
    e.preventDefault();
    this.extraItemSubview((this.getSubviewByCid(this.getActiveCellViewCid())).model, 'labelRow').showOutcomeEditView(e);
    return false;
  };

  OutcomesView.prototype.editActiveCell = function(e) {
    (this.getSubviewByCid(this.getActiveCellViewCid())).editCell(e, this.activeCell.el);
    return false;
  };

  OutcomesView.prototype.getActiveCellViewCid = function() {
    return this.activeCell.el.closest('tr').data().viewCid;
  };

  OutcomesView.prototype.setActiveCell = function() {
    var $cell, colNo, newCell, rowAndCol, rowNo, _ref, _ref1;
    switch (arguments.length) {
      case 1:
        $cell = arguments[0];
        rowAndCol = this.cellRowAndCol(_(this.cellsMapping).invert()[this.constructSelectorForCell($cell)]);
        rowNo = rowAndCol.row;
        colNo = rowAndCol.col;
        break;
      case 2:
        rowNo = arguments[0];
        colNo = arguments[1];
    }
    if ((_ref = this.activeCell) != null) {
      _ref.el.removeClass('active');
    }
    newCell = this.$(this.cellsMapping[this.cellsMappingKey(rowNo, colNo)]);
    if (!newCell.length) {
      return;
    }
    newCell.addClass('active');
    this.activeCell = {
      el: newCell,
      row: rowNo,
      col: colNo,
      rowspan: newCell.prop('rowspan'),
      colspan: newCell.prop('colspan')
    };
    if (!this.currentEditView || ((_ref1 = this.currentEditView) != null ? _ref1.disposed : void 0)) {
      return this._publishActiveProperty(this.activeCell.el.data('property'));
    }
  };

  OutcomesView.prototype._publishActiveProperty = function(property) {
    return this.publishEvent('activatedProperty', property, this.activeCell.el, this.options.questionModel, this.getSubviewByCid(this.getActiveCellViewCid()).model);
  };

  OutcomesView.prototype.getActiveCellEditType = function() {
    if (outcomeCellTypes[this.activeCell.el.data('property')]) {
      return outcomeCellTypes[this.activeCell.el.data('property')].editType;
    }
  };

  OutcomesView.prototype.showContextHelp = function(evt) {
    var $target, helpBoxView, property;
    $target = $(evt.target);
    if (!$target.hasClass('context-help-icon')) {
      return;
    }
    property = $target.data('property');
    if (!HelpResources.prototype.helpEnabled(property)) {
      return;
    }
    evt.stopPropagation();
    helpBoxView = new HelpBoxView({
      parentView: this,
      property: property
    });
    this.subview('helpBoxView', helpBoxView);
    return helpBoxView.show($target.offset());
  };

  return OutcomesView;

})(FullWypasCollectionView);
