var CONFIG_OBJECT_MAP, CommonComparatorTableWithI18n, DbHelper, NMAExportService, NmaTablePrintout, TableSettingsContext, W, alt, createPrintoutContainer, exportToFile, gdtDataToNmaData, getOrganizationName, lift, mapConfigObject, mediator, nmaDataToVizData, printout_template, removeHTMLNode, _ref, _ref1;

alt = require('alt');

DbHelper = require('base/lib/db_helper');

mediator = require('mediator');

W = require('when');

lift = require('when/function').lift;

printout_template = require('views/templates/printouts/nma');

_ref = require('lib/nma_helper'), gdtDataToNmaData = _ref.gdtDataToNmaData, nmaDataToVizData = _ref.nmaDataToVizData;

getOrganizationName = require('lib/app_utils').getOrganizationName;

exportToFile = require('lib/export_to_file').exportToFile;

_ref1 = require('base/lib/utils'), removeHTMLNode = _ref1.removeHTMLNode, createPrintoutContainer = _ref1.createPrintoutContainer;

CommonComparatorTableWithI18n = NmaComponents.CommonComparatorTableWithI18n, TableSettingsContext = NmaComponents.TableSettingsContext;

NmaTablePrintout = require('components/nma/nma_table_printout');

CONFIG_OBJECT_MAP = {
  'comparatorId': 'initialComparatorId',
  'denominator': 'initialDenominator',
  'selectedInterventionIds': 'initialInterventionIds',
  'selectedOutcomeIds': 'initialOutcomeIds',
  'transposed': 'initialTransposed'
};

mapConfigObject = function(props) {
  return _.chain(props).pairs().map(function(_arg) {
    var key, value, _ref2;
    key = _arg[0], value = _arg[1];
    return [(_ref2 = CONFIG_OBJECT_MAP[key]) != null ? _ref2 : key, value];
  }).object().value();
};

module.exports = NMAExportService = (function() {
  function NMAExportService(adapter) {
    this.adapter = adapter;
  }

  NMAExportService.prototype._getNmaDataForPresentations = function(question, outcomes) {
    return gdtDataToNmaData(question, outcomes);
  };

  NMAExportService.prototype.exportToLink = function() {
    var data, organizationName, outcomes, presentationId, question, vizConfig;
    question = alt.stores.NMAVizStore.getQuestion();
    outcomes = alt.stores.NMAVizStore.getOutcomes();
    vizConfig = mapConfigObject(alt.stores.NMAVizStore.getVizConfig().toJS());
    data = this._getNmaDataForPresentations(question, outcomes);
    presentationId = question.get('_id');
    organizationName = getOrganizationName();
    return this.adapter.updateOrCreate(DbHelper.getNmaDbUrl(), presentationId, function(doc) {
      return _.extend({}, doc, {
        _id: presentationId,
        data: data,
        organizationName: organizationName,
        vizConfig: vizConfig
      });
    }).then(function() {
      return DbHelper.getNmaUrl(presentationId);
    });
  };

  NMAExportService.prototype.exportToIframe = function() {
    return this.exportToLink().then(function(url) {
      var attrsString;
      attrsString = 'frameborder=\"0\" width=\"100%\" height=\"100%\" allowfullscreen';
      return "<iframe src=\"" + url + "\" " + attrsString + "></iframe>";
    });
  };

  NMAExportService.prototype.createHtmlPrintout = function() {
    var comparatorId, comparatorTableMode, comparisons, data, denominator, doc, explanations, interventions, nmaComponent, outcomes, outcomesFromStore, outcomesToPass, printoutHTML, props, question, selectedInterventionIds, selectedInterventions, selectedOutcomeIds, showThresholds, spliceIdx, spliceTag, tempContainer, transposed, _ref2;
    comparatorId = alt.stores.NMAVizStore.getComparatorId();
    comparatorTableMode = alt.stores.NMAVizStore.getComparatorTableMode();
    denominator = alt.stores.NMAVizStore.getDenominator();
    outcomesFromStore = alt.stores.NMAVizStore.getOutcomes();
    question = alt.stores.NMAVizStore.getQuestion();
    selectedInterventionIds = alt.stores.NMAVizStore.getSelectedInterventionIds();
    selectedOutcomeIds = alt.stores.NMAVizStore.getSelectedOutcomeIds();
    showThresholds = alt.stores.NMAVizStore.getShowThresholds();
    transposed = alt.stores.NMAVizStore.isTransposed();
    _ref2 = gdtDataToNmaData(question, outcomesFromStore), interventions = _ref2.interventions, comparisons = _ref2.comparisons, outcomes = _ref2.outcomes, explanations = _ref2.explanations;
    outcomesToPass = outcomes.filter(function(outcome) {
      return selectedOutcomeIds.includes(outcome.id);
    });
    selectedInterventions = interventions.filter(function(intervention) {
      return selectedInterventionIds.includes(intervention.id);
    });
    data = nmaDataToVizData(interventions, selectedInterventions, comparisons, comparatorId, comparatorTableMode);
    props = {
      comparatorTableMode: comparatorTableMode,
      data: data,
      expandedRows: [],
      explanations: explanations,
      i18nInstance: i18next,
      outcomes: outcomesToPass,
      showCoeInsufficientLabel: mediator.services.switches.isServerSwitchOn('acpFeatures'),
      transposed: transposed,
      thresholdType: question.get('thresholdOptions')
    };
    tempContainer = createPrintoutContainer();
    nmaComponent = React.createElement(NmaTablePrintout, {
      props: props,
      settings: {
        denominator: denominator,
        showThresholds: showThresholds,
        outcomes: outcomes
      }
    });
    ReactDOM.render(nmaComponent, tempContainer);
    $(tempContainer).find('button').each(function(_idx, el) {
      return removeHTMLNode(el);
    });
    $(tempContainer).find('.p-datatable-reorderablerow-handle').each(function(_idx, el) {
      return removeHTMLNode(el);
    });
    spliceTag = '<div id="page-container">';
    printoutHTML = printout_template({
      orientation: 'landscape',
      margins: {}
    }, {});
    spliceIdx = printoutHTML.indexOf(spliceTag) + spliceTag.length;
    doc = [printoutHTML.slice(0, spliceIdx), tempContainer.innerHTML, printoutHTML.slice(spliceIdx)].join('');
    return doc;
  };

  NMAExportService.prototype.exportToFile = function(exportFormat) {
    var fileName, question;
    question = alt.stores.NMAVizStore.getQuestion();
    fileName = "" + (question.get('question').substring(0, 100));
    return lift(this.createHtmlPrintout)().then(function(content) {
      return exportToFile(exportFormat, content, {
        fileName: fileName
      });
    }).then(function() {
      return mediator.dialogs.success({
        message: $.t('projects:export_dialog.table_export_successful', {
          'tablename': question.get('question')
        }),
        ms: 10000,
        closeBtn: true
      });
    })["catch"](function(reason) {
      if (reason !== 'cancelled') {
        return mediator.dialogs.error(reason);
      }
    });
  };

  return NMAExportService;

})();
