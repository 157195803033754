var arrayOf, boolean, docShape, docTypes, documentId, initial, number, oneOf, optional, outcomeDataType, outcomeDocShape, shape, string, _ref;

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

initial = require('lib/db_docs/field_types/type_decorators').initial;

oneOf = require('lib/db_docs/field_types/one_of');

shape = require('lib/db_docs/field_types/shape');

_ref = require('lib/db_docs/field_types/built_in_types'), boolean = _ref.boolean, number = _ref.number, string = _ref.string;

optional = shape.typeDecorators.optional;

outcomeDataType = require('lib/db_docs/field_types/nma_outcome_data_type');

outcomeDocShape = docShape({
  baselineRisk: optional(number),
  baselineRiskType: optional(oneOf(['outcome', 'comparator'])),
  docType: initial(docTypes.NMA_OUTCOME)(string),
  description: optional(string),
  fileName: optional(string),
  name: string,
  shortName: optional(string),
  units: optional(string),
  higherIsBetter: optional(boolean),
  type: initial('dich')(oneOf(['dich', 'cont', 'timeToEvent'])),
  data: arrayOf(outcomeDataType),
  questionId: documentId,
  utility: optional(number),
  thresholdsAutoRange: optional(arrayOf(string)),
  T1: optional(number),
  T2: optional(number),
  T3: optional(number)
});

module.exports = outcomeDocShape;
