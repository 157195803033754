var EFFECT_CLASSIFICATIONS, LEGACY_EFFECT_CLASSIFICATIONS, advancedData, advancedDataOption, armData, arrayOf, boolean, calculated, certaintyAssessment, documentId, effectType, estimatesEnabledType, mapOf, number, oneOf, optional, outcomeDataShape, overallRatingType, removeLegacyEffectClassification, shape, string, _ref, _ref1,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

mapOf = require('lib/db_docs/field_types/map_of');

oneOf = require('lib/db_docs/field_types/one_of');

shape = require('lib/db_docs/field_types/shape');

_ref = require('lib/db_docs/field_types/built_in_types'), boolean = _ref.boolean, number = _ref.number, string = _ref.string;

_ref1 = shape.typeDecorators, calculated = _ref1.calculated, optional = _ref1.optional;

overallRatingType = oneOf(['High', 'Moderate', 'Low', 'Very Low']);

certaintyAssessment = shape({
  rct: optional(boolean),
  riskOfBias: optional(oneOf(['Not serious', 'Serious', 'Very serious', 'Extremely serious'])),
  inconsistency: optional(oneOf(['Not serious', 'Serious', 'Very serious'])),
  indirectness: optional(oneOf(['Not serious', 'Serious', 'Very serious'])),
  imprecision: optional(oneOf(['Not serious', 'Serious', 'Very serious', 'Extremely serious'])),
  publicationBias: optional(oneOf(['Undetected', 'Strongly suspected'])),
  largeEffect: optional(oneOf(['No', 'Large', 'Very large'])),
  plausibleConfounding: optional(oneOf(['No', 'Would reduce demonstrated effect', 'Would suggest spurious effect'])),
  doseResponseGradient: optional(oneOf(['No', 'Yes'])),
  overall: optional(overallRatingType),
  overrideScore: optional(boolean),
  startingEvidence: optional(oneOf(['Direct', 'Indirect'])),
  startingRating: optional(overallRatingType),
  incoherence: optional(oneOf(['Not serious', 'Serious', 'Very serious'])),
  intransitivity: optional(oneOf(['Not serious', 'Serious', 'Very serious']))
});

effectType = shape({
  value: optional(number),
  ciLow: optional(number),
  ciHigh: optional(number),
  ciLevel: optional(number),
  effectMeasure: optional(oneOf(['OR', 'RR', 'Rate Ratio', 'HR', 'RD', 'MD', 'SMD', 'Other'])),
  effectMeasureOther: optional(string),
  certaintyAssessment: optional(certaintyAssessment),
  pValue: optional(number),
  i2: optional(number),
  incoherence: optional(number),
  autoCalculateAbsoluteEffect: optional(boolean),
  absoluteEffectValue: optional(number),
  absoluteEffectCiLow: optional(number),
  absoluteEffectCiHigh: optional(number),
  absoluteEffectCiLevel: optional(number),
  directionLabels: optional(oneOf(['LowerHigher', 'FewerMore'])),
  effectVCGSummary: optional(string),
  reverseEffectVCGSummary: optional(string),
  absoluteEffectVCGSummary: optional(string),
  reverseAbsoluteEffectVCGSummary: optional(string)
});

advancedDataOption = shape({
  effectToUseOverride: optional(oneOf(['network', 'direct', 'indirect'])),
  certaintyOfEffectOverride: optional(oneOf(['High', 'Moderate', 'Low', 'Very Low'])),
  noDataOverride: optional(boolean)
});

advancedData = shape({
  absolute: optional(advancedDataOption),
  relative: optional(advancedDataOption)
});

armData = shape({
  numberOfParticipants: optional(number),
  numberOfEvents: optional(number),
  mean: optional(number)
});

estimatesEnabledType = shape({
  network: optional(boolean),
  direct: optional(boolean),
  indirect: optional(boolean)
});

LEGACY_EFFECT_CLASSIFICATIONS = ['Decreased', 'Increased', 'InsufficientData', 'NoEffect'];

EFFECT_CLASSIFICATIONS = ['TrivialOrNoEffect', 'SmallPositive', 'ModeratePositive', 'LargePositive', 'SmallNegative', 'ModerateNegative', 'LargeNegative', 'NoEffect', 'Positive', 'Negative'];

removeLegacyEffectClassification = function(_arg) {
  var effectClassification;
  effectClassification = _arg.effectClassification;
  if (__indexOf.call(LEGACY_EFFECT_CLASSIFICATIONS, effectClassification) >= 0) {
    return;
  }
  return effectClassification;
};

outcomeDataShape = shape({
  interventionId: documentId,
  comparatorId: documentId,
  outcomeId: documentId,
  nmaEffect: optional(effectType),
  directEffect: optional(effectType),
  indirectEffect: optional(effectType),
  interpretation: optional(string),
  reverseInterpretation: optional(string),
  effectToUse: optional(oneOf(['network', 'direct', 'indirect'])),
  effectClassification: calculated(removeLegacyEffectClassification)(optional(oneOf(EFFECT_CLASSIFICATIONS))),
  interventionData: optional(armData),
  comparisonData: optional(armData),
  numberOfStudies: optional(number),
  totalNumberOfParticipants: optional(number),
  advancedOptions: optional(advancedData),
  useAdvancedOptions: optional(boolean),
  rct: optional(boolean),
  estimatesEnabled: optional(estimatesEnabledType),
  explanations: optional(mapOf(arrayOf(string)))
});

module.exports = outcomeDataShape;
