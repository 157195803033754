{ useState } = React
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
Modal = require 'components/common/modal'
ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'
{ isAcpFeaturesOn } = require 'stores/utils/etd_template_helper'
RangeInput = require 'components/common/range_input'

getVisualizationForm = ->
  [
    fieldKey: 'backlink'
    type: 'inputText'
    placeholder: 'backlink'
    placeholderFromI18n: true
    showClear: true
    initialValue: ''
  ,
    fieldKey: 'enableZoom'
    type: 'switcher'
    initialValue: !isAcpFeaturesOn()
    showClear: false
  , 
    fieldKey: 'showArrows'
    type: 'switcher'
    initialValue: isAcpFeaturesOn()
    showClear: false
  ,
    fieldKey: 'rrRange'
    type: 'custom'
    component: RangeInput
    initialValue: [0, 2]
    showClear: false
  ,
    fieldKey: 'ardRange'
    type: 'custom'
    component: RangeInput
    initialValue: [-100, 100]
    showClear: false
  ,
    fieldKey: 'mdRange'
    type: 'custom'
    component: RangeInput
    initialValue: [-2, 2]
    showClear: false
  ,
    fieldKey: 'smdRange'
    type: 'custom'
    component: RangeInput
    initialValue: [0, 1]
    showClear: false
  ,
    fieldKey: 'positiveOutcomesFirst'
    type: 'switcher'
    initialValue: false
    showClear: false

  ].filter (field) -> 
    return true unless isAcpFeaturesOn() 
    field.fieldKey not in ['enableZoom', 'showArrows']

SettingsModal = ({ onClose, onSave, isOpen, settings }) ->

  i18n = useI18n('vi:settings')

  _onSave = useCoffeeCallback [settings], ->
    onSave(settings)

  onUpdate = useCoffeeCallback [], (field, value) ->
    ACPVisualGuidelinesActions.updateSettings({ field, value })

  form = getVisualizationForm()

  <Modal
    isOpen={isOpen}
    className="vi-guidelines-settings-modal"
    closeButton={true}
    title={i18n 'title'}
    onClose={onClose}
  >
    <div className="mb-20">
      <ExtractionForm
        className='vi-settings-form'
        data={settings.toJS()}
        form={main: form}
        i18n={i18n}
        onChange={onUpdate}
      />
    </div>
    <ApplyCancelButtons 
      onCancel={onClose}
      onApply={_onSave}
    />
  </Modal>
  
module.exports = SettingsModal
