var NMAListActions, NMAQuestionActions, NMA_OUTCOME_PREFIX, NMA_QUESTION_PREFIX, NmaOutcomeDoc, W, appUtils, generateActions, _ref;

appUtils = require('lib/app_utils');

generateActions = require('actions/utils/generate_actions');

_ref = require('lib/doc_prefixes'), NMA_OUTCOME_PREFIX = _ref.nmaOutcome, NMA_QUESTION_PREFIX = _ref.nmaQuestion;

NMAListActions = require('actions/nma_list_actions');

NMAQuestionActions = require('actions/nma_question_actions');

NmaOutcomeDoc = require('lib/db_docs/nma_outcome_doc');

W = require('when/when');

module.exports = generateActions({
  autoGenerate: ['closeExportModal', 'closeForestPlot', 'closeNetworkGraph', 'fetchError', 'fetchSuccess', 'openExportModal', 'openForestPlot', 'openNetworkGraph'],
  name: 'NMAVizActions',
  actions: {
    dbChange: function(change, _pending, _lastSeq) {
      var isOutcome, isQuestion;
      isOutcome = change.id.indexOf(NMA_OUTCOME_PREFIX) === 0;
      isQuestion = change.id.indexOf(NMA_QUESTION_PREFIX) === 0;
      if (!(isOutcome || isQuestion)) {
        return;
      }
      return this.dispatch(change);
    },
    fetch: function(_arg) {
      var dbId, docId;
      dbId = _arg.dbId, docId = _arg.docId;
      this.dispatch();
      return W.all([
        NMAListActions.fetch({
          dbId: dbId
        }), NmaOutcomeDoc.at(dbId).fetchMany()
      ]).then((function(_this) {
        return function(_arg1) {
          var outcomes, outcomesResult, question, questions;
          questions = _arg1[0], outcomesResult = _arg1[1];
          question = _.find(questions, function(doc) {
            return doc._id === docId;
          });
          outcomes = _.chain(outcomesResult.rows).pluck('doc').compact().filter(function(doc) {
            return doc.questionId === docId;
          }).value();
          if (!question) {
            throw new Error("No question " + docId);
          }
          NMAQuestionActions.fetchQuestionSuccess({
            setCurrentQuestionId: true,
            question: question
          });
          NMAQuestionActions.fetchOutcomesSuccess(outcomes);
          return _this.actions.fetchSuccess({
            outcomes: outcomes,
            question: question
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('nma:viz.fetch_error'));
          return _this.actions.fetchError(err);
        };
      })(this));
    }
  }
});
