SelectCustom = require 'components/common/select_custom'
{ useI18n } = require 'lib/react_utils'
{ useMemo } = React

THRESHOLD_OPTIONS = ['magnitudes_of_effect', 'minimally_important_differences']

OPTIONS_COLOR_MAP = {
  magnitudes_of_effect: [
    '#ff8484',
    '#ffbebe',
    '#fee3e3',
    '#ededed',
    '#deffdf',
    '#b0eab2',
    '#5ddf62'
  ],
  minimally_important_differences: [
    '#ff8484',
    '#ededed',
    '#5ddf62'
  ]
}

QuestionThresholdOptions = ({ value, onChange }) ->
  i18n = useI18n 'nma:questions_list.modal'

  options = useMemo ->
    THRESHOLD_OPTIONS.map (option) -> { value: option, text: i18n("threshold_options.#{option}") }
  , []

  <div>
    <SelectCustom
      className='threshold-options'
      options={options}
      selected={value}
      onChange={onChange}
      selectValueComponent={(option) ->
        <div dangerouslySetInnerHTML={{__html: option.text}} />
      }
      itemRenderer={(props) -> <Option {...props} />}
    />
  </div>


Option = ({ onClick, disabled, title, value }) ->
  i18n = useI18n 'nma:questions_list.modal'
  <li
    className="threshold-option"
    onClick={onClick}
    disabled={disabled}
    title={title}
  >
    <div>
      <strong>{i18n("threshold_options.#{value}")}</strong>
      <div className="flex flex-row items-center">
        <span>{i18n("threshold_options.worst_possible_effect")}</span>
        <div className="threshold-options__container">
          {OPTIONS_COLOR_MAP[value].map (color) ->
            <div
              key={color}
              className="threshold-options__color" style={{backgroundColor: color}}
            />
          }
        </div>
        <span>{i18n("threshold_options.best_possible_effect")}</span>
      </div>
    </div>
  </li>

module.exports = QuestionThresholdOptions
