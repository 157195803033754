var DiagnosticOutcomeView, OutcomeView, formatters, statisticalUtils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OutcomeView = require('views/evidence_syntheses/outcome_view');

statisticalUtils = require('lib/statistical_utils');

formatters = require('lib/formatters');

module.exports = DiagnosticOutcomeView = (function(_super) {
  __extends(DiagnosticOutcomeView, _super);

  function DiagnosticOutcomeView() {
    this.editClosed = __bind(this.editClosed, this);
    return DiagnosticOutcomeView.__super__.constructor.apply(this, arguments);
  }

  DiagnosticOutcomeView.prototype.containerMethod = 'after';

  DiagnosticOutcomeView.prototype.prevalencesObserve = ['prevalence1', 'prevalence2', 'prevalence3'];

  DiagnosticOutcomeView.prototype.indexTestObserveFields = _(['pooledSensitivity', 'pooledSensitivityFrom', 'pooledSensitivityTo', 'pooledSpecificity', 'pooledSpecificityFrom', 'pooledSpecificityTo', 'pooledSensitivityRangeFrom', 'pooledSensitivityRangeTo', 'pooledSpecificityRangeFrom', 'pooledSpecificityRangeTo', 'sourceOfDxData']).union(DiagnosticOutcomeView.prototype.prevalencesObserve, ['absDenominator']);

  DiagnosticOutcomeView.prototype.comparisonTestObserveFields = _(['pooledSensitivityComparison', 'pooledSensitivityComparisonFrom', 'pooledSensitivityComparisonTo', 'pooledSpecificityComparison', 'pooledSensitivityComparisonRangeFrom', 'pooledSensitivityComparisonRangeTo', 'pooledSpecificityComparisonRangeFrom', 'pooledSpecificityComparisonRangeTo', 'sourceOfDxData']).union(DiagnosticOutcomeView.prototype.prevalencesObserve, ['absDenominator']);

  DiagnosticOutcomeView.prototype.questionBindings = {
    '.diagnostic-effect.prevalence-1 div.cell-content[data-test=index]': {
      observe: DiagnosticOutcomeView.prototype.indexTestObserveFields,
      updateMethod: 'html',
      onGet: function() {
        return this.bindPrevalenceOnGet(1);
      }
    },
    '.diagnostic-effect.prevalence-1 div.cell-content[data-test=comparison]': {
      observe: DiagnosticOutcomeView.prototype.comparisonTestObserveFields,
      updateMethod: 'html',
      onGet: function() {
        return this.bindPrevalenceOnGet(1, true);
      }
    },
    '.diagnostic-effect.prevalence-2 div.cell-content[data-test=index]': {
      observe: DiagnosticOutcomeView.prototype.indexTestObserveFields,
      updateMethod: 'html',
      onGet: function() {
        return this.bindPrevalenceOnGet(2);
      }
    },
    '.diagnostic-effect.prevalence-2 div.cell-content[data-test=comparison]': {
      observe: DiagnosticOutcomeView.prototype.comparisonTestObserveFields,
      updateMethod: 'html',
      onGet: function() {
        return this.bindPrevalenceOnGet(2, true);
      }
    },
    '.diagnostic-effect.prevalence-3 div.cell-content[data-test=index]': {
      observe: DiagnosticOutcomeView.prototype.indexTestObserveFields,
      updateMethod: 'html',
      onGet: function() {
        return this.bindPrevalenceOnGet(3);
      }
    },
    '.diagnostic-effect.prevalence-3 div.cell-content[data-test=comparison]': {
      observe: DiagnosticOutcomeView.prototype.comparisonTestObserveFields,
      updateMethod: 'html',
      onGet: function() {
        return this.bindPrevalenceOnGet(3, true);
      }
    }
  };

  DiagnosticOutcomeView.prototype.bindPrevalenceOnGet = function(prevalenceNo, comparison) {
    if (comparison == null) {
      comparison = false;
    }
    return formatters.outcome.diagnosticEffect(this.options.questionModel.attributes, this.model.get('name'), prevalenceNo, comparison);
  };

  DiagnosticOutcomeView.prototype.isDiagnosticRowspan = function() {
    return this.model.get('name') === 'FN' || this.model.get('name') === 'FP';
  };

  DiagnosticOutcomeView.prototype.initialize = function() {
    DiagnosticOutcomeView.__super__.initialize.apply(this, arguments);
    return this.editViewTemplateData.isDiagnostic = true;
  };

  DiagnosticOutcomeView.prototype.getTemplateData = function() {
    var data, outcomes, _ref, _ref1;
    data = DiagnosticOutcomeView.__super__.getTemplateData.apply(this, arguments);
    _(data).extend(_(this.options.questionModel.attributes).pick(['indexTest', 'comparatorTest', 'comparatorTestPresent', 'prevalence1', 'prevalence2', 'prevalence3']));
    if ((_ref = this.options.viewTypeName) === 'PrintoutLayerOneSof' || _ref === 'PrintoutLayerTwoSof') {
      outcomes = this.options.questionModel.get('outcomes').toArray();
      if (this.options.viewTypeName === 'PrintoutLayerOneSof') {
        outcomes = outcomes.slice(0, 5);
      }
      data.noComments = _.every(outcomes, function(o) {
        return _.isEmpty(o.get('comments'));
      });
    }
    if (this.isDiagnosticRowspan()) {
      data.dontDisplay = ['diagnostic/no_of_studies_patients', 'design_studies', 'risk_of_bias', 'indirectness', 'inconsistency', 'imprecision', 'diagnostic/publication_bias', 'other_considerations', 'quality'];
      if ((_ref1 = this.options.viewTypeName) === 'LayerOneSof' || _ref1 === 'PrintoutLayerOneSof' || _ref1 === 'LayerTwoSof' || _ref1 === 'PrintoutLayerTwoSof') {
        data.dontDisplay = _(data.dontDisplay).union(['diagnostic/no_of_participants_studies', 'quality']);
      }
    }
    data.short = this.options.short;
    return data;
  };

  DiagnosticOutcomeView.prototype.render = function() {
    var i, prev, prevsHidden, qm;
    DiagnosticOutcomeView.__super__.render.apply(this, arguments);
    qm = this.options.questionModel;
    this.stickit(qm, this.questionBindings);
    prevsHidden = (function() {
      var _i, _len, _ref, _results;
      _ref = [qm.get("prevalence1"), qm.get("prevalence2"), qm.get("prevalence3")];
      _results = [];
      for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
        prev = _ref[i];
        if (!prev) {
          _results.push(this.$(".prevalence-" + (i + 1)).hide());
        } else {
          _results.push(void 0);
        }
      }
      return _results;
    }).call(this);
    if (_(prevsHidden).any()) {
      return this.parentView.trigger('!fixLayout');
    }
  };

  DiagnosticOutcomeView.prototype.afterRender = function() {
    DiagnosticOutcomeView.__super__.afterRender.apply(this, arguments);
    this.fixRowspanForAbsoluteDifference();
    return this.el.classList.add(_.str.dasherize(this.model.get('name').toLowerCase()));
  };

  DiagnosticOutcomeView.prototype.editClosed = function(accepted) {
    var _ref;
    if (accepted == null) {
      accepted = false;
    }
    DiagnosticOutcomeView.__super__.editClosed.apply(this, arguments);
    return (_ref = this.parentView) != null ? _ref.togglePrevalencesColumns() : void 0;
  };

  DiagnosticOutcomeView.prototype.fixRowspanForAbsoluteDifference = function() {
    var $cell, cell, _i, _len, _ref, _ref1, _results;
    if (this.options.questionModel.get('type') === 'diagnostic' && this.options.questionModel.get('comparatorTestPresent') && this.options.viewTypeName !== 'PrintoutSOF') {
      if ((_ref = this.model.get('name')) === 'TP' || _ref === 'FN' || _ref === 'TN' || _ref === 'FP') {
        _ref1 = this.$('td');
        _results = [];
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          cell = _ref1[_i];
          $cell = $(cell);
          if (!$cell.hasClass('diagnostic-effect')) {
            _results.push($cell.prop('rowspan', $cell.prop('rowspan') * 2));
          } else {
            _results.push(void 0);
          }
        }
        return _results;
      }
    }
  };

  DiagnosticOutcomeView.prototype.dispose = function() {
    this.unstickit(this.options.questionModel, this.questionBindings);
    return DiagnosticOutcomeView.__super__.dispose.apply(this, arguments);
  };

  return DiagnosticOutcomeView;

})(OutcomeView);
