AdolopmentSectionSeparator = require 'components/etd/adolopment_section_separator'
ConclusionsRow = require 'components/etd/conclusions_row'
UserProjectDataActions = require 'actions/user_project_data_actions'


toggleOriginalSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleOriginalDataSection etdId, sectionId

toggleAdolopmentSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleAdolopmentDataSection etdId, sectionId

ConclusionsSectionAdolopment = ({
  adolopments
  assessmentSections
  contentCellClass
  contentColSpan
  etdId
  etdViewSettings
  isConsensus
  i18n
  renderMode
  sectionId
  templateId
}) ->
  originalData = not etdViewSettings.getIn(['collapsedOriginalSections', sectionId], false)

  <tbody>
    <AdolopmentSectionSeparator
      i18n={i18n}
      section="original"
      type="conclusions"
      onClick={toggleOriginalSection(etdId, sectionId)}
    />
    {originalData and adolopments.map (adolopment) ->
      adolopedSection = adolopment.getIn ['templateData', 'conclusions', 'sections', sectionId]
      <ConclusionsRow
        assessmentSections={assessmentSections}
        contentCellClass={contentCellClass}
        contentColSpan={contentColSpan}
        editable={false}
        key={adolopment.get('_id')}
        isConsensus={isConsensus}
        renderMode={renderMode}
        readOnly={true}
        section={adolopedSection}
        sectionId={sectionId}
        templateId={templateId}
      />
    .toList()}
    <AdolopmentSectionSeparator
      i18n={i18n}
      section="adolopment"
      type="conclusions"
      onClick={toggleAdolopmentSection(etdId, sectionId)}
    />
  </tbody>

ConclusionsSectionAdolopment.propTypes = {
  adolopments: PropTypes.instanceOf(Immutable.Map).isRequired,
  assessmentSections: PropTypes.instanceOf(Immutable.Map).isRequired,
  contentCellClass: PropTypes.string,
  contentColSpan: PropTypes.number,
  etdId: PropTypes.string.isRequired,
  isConsensus: PropTypes.bool,
  renderMode: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired
}

ConclusionsSectionAdolopment.defaultProps = {
  contentCellClass: '',
  contentColSpan: 1,
  isConsensus: false
}

module.exports = ConclusionsSectionAdolopment
