ChapterManagementMixin = require 'components/mixins/chapter_management_mixin'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
DropdownMenu = require 'components/common/dropdown_menu'
EditAppendicesModal = require './edit_appendices_modal'
IconButton = require 'components/common/icon_button'
MdgRecommendationSectionContent = require './mdg_recommendation_section_content'
mediator = require 'mediator'
RecommendationSectionContent = require './recommendation_section_content'
Router = require 'router'
{ getSettingsOptions, createNewRecommendationComment } = require 'lib/document_sections_v2_helper'

settingsOrder = [
  'edit_appendices'
  'delete'
  'insert_recommendation'
  'add_new_text_field'
  'open_question'
  'open_recommendation'
]

RecommendationSection = createReactClass
  displayName: 'RecommendationSection'

  propTypes:
    section: PropTypes.instanceOf(Immutable.Map).isRequired
    recommendation: PropTypes.instanceOf(Immutable.Map).isRequired
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    onInsertRecommendation: PropTypes.func.isRequired
    onDelete: PropTypes.func.isRequired

  mixins: [ChapterManagementMixin]

  getInitialState: ->
    editAppendicesDialogOpen: false

  handleSettingSelect: (selectedSetting) ->
    sectionId = @props.section.get('_id')
    parentSectionId = @props.section.get('parent')
    switch selectedSetting
      when 'add_new_text_field'
        DocumentSectionsV2Actions.addSection(mediator.project.id, parentSectionId, sectionId)
      when 'delete'
        @props.onDelete(sectionId)
      when 'edit_appendices'
        @setState editAppendicesDialogOpen: true
      when 'insert_recommendation'
        @props.onInsertRecommendation({ sectionId })
      when 'open_question', 'open_recommendation'
        isMdg = mediator.services.switches.isOn 'mdgTables'
        submodule = switch selectedSetting
          when 'open_question'
            if isMdg then 'mdg-evidence-table' else 'quality-of-evidence'
          when 'open_recommendation'
            if isMdg then 'mdg-recommendations' else 'recommendations'
        questionId = @props.question.get('_id')
        route = Router::routeForSubmodule 'evidence_syntheses', submodule, { questionId }
        mediator.publish '!router:route', route

  closeEditAppendicesDialog: ->
    @setState editAppendicesDialogOpen: false

  saveAppendices: (appendices) ->
    sectionId = @props.section.get('_id')
    @setState editAppendicesDialogOpen: false
    DocumentSectionsV2Actions.updateSectionAppendices {
      projectId: mediator.project.id,
      sectionId,
      selectedAppendices: appendices.toJS()
    }

  handleAddComment: ->
    author = { id: mediator.user.id, name: mediator.user.getFullName() }
    newComment = createNewRecommendationComment(author)
    projectId = mediator.project.id
    sectionId = @props.section.get('_id')
    newCommentId = newComment.id
    oldAnnotations = @props.section.getIn(['content', 'annotations'], Immutable.Map())
    updatedAnnotations = oldAnnotations.set(newCommentId, newComment).toJS()

    DocumentSectionsV2Actions.saveChapterContent {
      projectId,
      chapterId: @props.chapterId,
      sectionId,
      content: { annotations: updatedAnnotations }
    }
    # this part is for optimistic update
    DocumentSectionsV2Actions.addDiscussion {
      id: newCommentId,
      sectionId,
      discussionType: 'comments',
      data: newComment
    }
    DocumentSectionsV2Actions.editDiscussion { id: newCommentId, isNew: true }

  render: ->
    {
      section,
      recommendation,
      question,
      connectDragSource
    } = @props
    { editAppendicesDialogOpen } = @state

    mdgTablesOn = mediator.services.switches.isOn 'mdgTables'
    ContentComponent = if mdgTablesOn
      MdgRecommendationSectionContent
    else
      RecommendationSectionContent
    contentProps = {
      selectedAppendices: if mdgTablesOn then undefined else section.get 'selectedAppendices'
      question
      recommendation
      onAddComment: @handleAddComment
    }

    <React.Fragment>
      <div className="chapter-section-header">
        {@showChapterManagement() and <div className="chapter-section-management">
          <DropdownMenu
            opener={(
              <IconButton iconName="more" className="settings-toggle" />
            )}
            options={getSettingsOptions settingsOrder}
            onSelect={@handleSettingSelect}
          />
        </div>}
      </div>
      {connectDragSource(
        <div>
          <ContentComponent {...contentProps} />
        </div>
      )}
      <EditAppendicesModal
        isOpen={editAppendicesDialogOpen}
        onApply={@saveAppendices}
        onClose={@closeEditAppendicesDialog}
        selectedAppendices={section.get('selectedAppendices') ? Immutable.Map()}
      />
    </React.Fragment>

module.exports = RecommendationSection
