var MDGTopicDoc, MDG_TOPIC_PREFIX, appUtils, callMethod, docTypes, generateActions, generateGUID, goToLastRow, mediator, prepareCodeWithSanitizedValue, _ref;

appUtils = require('lib/app_utils');

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, generateGUID = _ref.generateGUID;

docTypes = require('lib/db_docs/doc_types');

generateActions = require('actions/utils/generate_actions');

MDGTopicDoc = require('lib/db_docs/mdg_topic_doc');

MDG_TOPIC_PREFIX = require('lib/doc_prefixes').mdgTopic;

mediator = require('mediator');

prepareCodeWithSanitizedValue = require('lib/questions_helper').prepareCodeWithSanitizedValue;

goToLastRow = function() {
  var elements, lastEl;
  elements = document.getElementsByClassName('mdg-topics__topic');
  lastEl = _.last(elements);
  if (!lastEl) {
    return;
  }
  return lastEl.scrollIntoView({
    behavior: 'smooth',
    block: 'end'
  });
};

module.exports = generateActions({
  autoGenerate: ['fetchError', 'fetchSuccess', 'toggleEditForRow'],
  name: 'MDGTopicActions',
  actions: {
    create: function(projectId) {
      var id;
      id = "" + MDG_TOPIC_PREFIX + (generateGUID());
      this.dispatch(id);
      return MDGTopicDoc.at(projectId).fetchMany().then(function(_arg) {
        var orderNumber, orderNumberFromDocs, rows;
        rows = _arg.rows;
        orderNumberFromDocs = _.chain(rows).pluck('doc').pluck('orderNumber').max().value();
        orderNumber = orderNumberFromDocs < 0 ? 1 : orderNumberFromDocs + 1;
        return MDGTopicDoc.at(projectId, id).create({
          _id: id,
          orderNumber: orderNumber
        });
      }).then(function() {
        return goToLastRow();
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('settings:mdg_topics.create_failed')));
    },
    dbChange: function(change, _pending, _lastSeq) {
      var deleted, doc;
      deleted = change.deleted, doc = change.doc;
      if (!(doc.docType === docTypes.MDG_TOPIC || deleted)) {
        return;
      }
      return this.dispatch(change);
    },
    fetch: function(_arg) {
      var dbId;
      dbId = _arg.dbId;
      this.dispatch();
      return MDGTopicDoc.at(dbId).fetchMany().then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
    },
    deleteTopic: function(projectId, topicId) {
      return MDGTopicDoc.at(projectId, topicId)["delete"]().then(function() {
        return mediator.dialogs.success($.t('settings:mdg_topics.delete_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('settings:mdg_topics.delete_failed')));
    },
    update: function(projectId, topicId, newData) {
      this.dispatch(topicId);
      return MDGTopicDoc.at(projectId, topicId).update(newData)["catch"](appUtils.errorHandlerWithMsgCurried($.t('settings:mdg_topics.update_failed')));
    },
    addCodes: function(projectId, topicId, codes) {
      var newCodesMap;
      newCodesMap = codes.reduce(function(acc, code) {
        var codeSanitized;
        codeSanitized = prepareCodeWithSanitizedValue(code);
        acc[codeSanitized.id] = codeSanitized;
        return acc;
      }, {});
      return MDGTopicDoc.at(projectId, topicId).updateAsInstance(callMethod('addCodes', newCodesMap))["catch"](appUtils.errorHandlerWithMsgCurried($.t('settings:mdg_topics.add_codes_failed')));
    },
    removeCode: function(projectId, topicId, code) {
      return MDGTopicDoc.at(projectId, topicId).updateAsInstance(callMethod('removeCode', code.get('id')))["catch"](appUtils.errorHandlerWithMsgCurried($.t('settings:mdg_topics.remove_code_failed')));
    }
  }
});
