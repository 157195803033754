var ChapterDoc, DiscardChangesActions, DocumentSectionsCommonActions, DocumentSectionsV2Actions, MdaTableDoc, MdaTopicDoc, QuestionGroupsActions, QuestionsActions, ReferencesActions, RootDoc, SECTION_SEARCH_VIEW, SECTION_SEARCH_VIEW_NAME, SectionDoc, W, alt, callMethod, chapterDocShape, concatChaptersIdsDeep, convertRowsToArray, createChapter, createRootIfNotExists, createSections, createSubchapter, deleteMdaConnectionsIfNeeded, discussionsDbView, docInstSetAt, docInstUpdateAt, errorHandler, fetchDocs, flatMap, generateGUID, getChapterAndSectionIds, getDefaultSectionDoc, maybeResolveSectionsConflicts, mediator, pluck, prepareCodeWithSanitizedValue, removeHighlightMarks, removeItemById, saveChapterDocs, sectionDocShape, _ref, _ref1, _ref2, _ref3,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

mediator = require('mediator');

W = require('when');

flatMap = require('lodash').flatMap;

RootDoc = require('lib/db_docs/document_sections_v2_root_doc');

ChapterDoc = require('lib/db_docs/document_sections_v2_chapter_doc');

SectionDoc = require('lib/db_docs/document_sections_v2_section_doc');

MdaTableDoc = require('lib/db_docs/mda_table_doc');

MdaTopicDoc = require('lib/db_docs/mda_topic_doc');

chapterDocShape = require('lib/db_docs/doc_shapes/document_sections_v2_chapter_doc_shape');

sectionDocShape = require('lib/db_docs/doc_shapes/document_sections_v2_section_doc_shape');

ReferencesActions = require('actions/references_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

QuestionsActions = require('actions/questions_actions');

DiscardChangesActions = require('actions/discard_changes_actions');

DocumentSectionsCommonActions = require('actions/document_sections_common_actions');

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, generateGUID = _ref.generateGUID;

_ref1 = require('lib/app_utils'), convertRowsToArray = _ref1.convertRowsToArray, errorHandler = _ref1.errorHandler;

pluck = require('lib/immutable_utils').pluck;

prepareCodeWithSanitizedValue = require('lib/questions_helper').prepareCodeWithSanitizedValue;

getChapterAndSectionIds = require('lib/mda_helper').getChapterAndSectionIds;

_ref2 = require('lib/db_docs/document_instance_helpers'), docInstUpdateAt = _ref2.docInstUpdateAt, docInstSetAt = _ref2.docInstSetAt, removeItemById = _ref2.removeItemById;

_ref3 = require('lib/document_sections_v2_helper'), discussionsDbView = _ref3.discussionsDbView, maybeResolveSectionsConflicts = _ref3.maybeResolveSectionsConflicts;

removeHighlightMarks = require('lib/epiditor_utils').removeHighlightMarks;

SECTION_SEARCH_VIEW = {
  map: function(doc) {
    var mdaTopicId, _ref4;
    if (doc.type !== 'document_sections_section') {
      return;
    }
    mdaTopicId = (_ref4 = doc.mdaTopicId) != null ? _ref4 : null;
    return emit(mdaTopicId, {
      id: doc._id,
      title: doc.title,
      type: doc.type,
      parent: doc.parent
    });
  }
};

SECTION_SEARCH_VIEW_NAME = 'docsec_title_with_parent_search_view';

DocumentSectionsV2Actions = (function() {
  function DocumentSectionsV2Actions() {
    this.generateActions('addDiscussion', 'applyDraftChangeDecision', 'closeBatchEditorOptionsModal', 'collapseChapterSections', 'deleteDiscussion', 'fetchChapterSectionsFailure', 'fetchChapterSectionsSuccess', 'fetchFailure', 'fetchSectionsForSearchSuccess', 'fetchSuccess', 'flushDraftDiscussions', 'moveChapter', 'movePopulationGroup', 'moveRecommendation', 'moveSection', 'removeDraftDiscussion', 'reopenDiscussion', 'resetAcceptedOrRejectedChange', 'resetUpdatedDraftDiscussion', 'resolveDiscussion', 'sectionEditStarted', 'setActiveSidebarTab', 'setBatchEditorOptions', 'setDiscussionsFilters', 'setEditorContent', 'setScrollToChapter', 'setSuggesting', 'stopEditSection', 'updateDiscussion', 'updateDraftDiscussion', 'updateDraftDiscussions');
  }

  DocumentSectionsV2Actions.prototype.fetch = function(_arg) {
    var adapter, chaptersPromise, discussionsPromise, mdaTablesPromise, projectId, recommendationsPromise, rootPromise;
    projectId = _arg.dbId;
    adapter = mediator.services.storePersistenceAdapter;
    ReferencesActions.fetch();
    QuestionGroupsActions.fetch();
    DocumentSectionsCommonActions.fetch({
      dbId: projectId
    });
    recommendationsPromise = QuestionsActions.fetch().then(function() {
      var recommendationsIds;
      recommendationsIds = alt.stores.QuestionsStore.getQuestions().reduce(function(acc, question) {
        return acc.concat(question.get('recommendationIds').toJS());
      }, []);
      return fetchDocs(projectId, recommendationsIds);
    });
    rootPromise = createRootIfNotExists(projectId);
    chaptersPromise = ChapterDoc.at(projectId).fetchMany();
    discussionsPromise = adapter.fetchWithView(projectId, 'doc_sec_v2_discussions', discussionsDbView);
    mdaTablesPromise = MdaTableDoc.at(projectId).fetchMany();
    return W.all([rootPromise, chaptersPromise, recommendationsPromise, discussionsPromise, mdaTablesPromise]).then((function(_this) {
      return function(_arg1) {
        var chapters, discussions, mdaTablesRows, recommendations, root, _ref4;
        root = _arg1[0], chapters = _arg1[1], recommendations = _arg1[2], discussions = _arg1[3], (_ref4 = _arg1[4], mdaTablesRows = _ref4.rows);
        return _this.actions.fetchSuccess({
          chapters: chapters,
          discussions: discussions,
          mdaTables: convertRowsToArray(mdaTablesRows),
          recommendations: recommendations,
          root: root
        });
      };
    })(this))["catch"](this.actions.fetchFailure);
  };

  DocumentSectionsV2Actions.prototype.editDiscussion = function(payload) {
    this.dispatch(payload);
    return DiscardChangesActions.setHasChangesInComments(true);
  };

  DocumentSectionsV2Actions.prototype.closeDiscussionEdit = function(payload) {
    var editingDiscussionItems, hasCommentsInEditMode;
    this.dispatch(payload);
    editingDiscussionItems = alt.stores.DocumentSectionsV2Store.getEditingDiscussionItems();
    hasCommentsInEditMode = editingDiscussionItems.some(_.identity);
    if (!hasCommentsInEditMode) {
      return DiscardChangesActions.setHasChangesInComments(false);
    }
  };

  DocumentSectionsV2Actions.prototype.fetchSectionsForSearch = function(_arg) {
    var mdaTopicId, projectId;
    projectId = _arg.projectId, mdaTopicId = _arg.mdaTopicId;
    this.dispatch();
    return mediator.services.storePersistenceAdapter.fetchWithView(projectId, SECTION_SEARCH_VIEW_NAME, SECTION_SEARCH_VIEW, mdaTopicId != null ? mdaTopicId : null).then(this.actions.fetchSectionsForSearchSuccess)["catch"](this.actions.fetchFailure);
  };

  DocumentSectionsV2Actions.prototype.expandChapterSections = function(_arg) {
    var chapter, chapterId, projectId, sectionIds, sections;
    chapterId = _arg.chapterId, projectId = _arg.projectId;
    sections = alt.stores.DocumentSectionsV2Store.getChapterSections(chapterId);
    if (sections) {
      return W.resolve(this.dispatch(chapterId));
    } else {
      chapter = alt.stores.DocumentSectionsV2Store.getChapter(chapterId);
      sectionIds = chapter.get('sections').toArray();
      return this.actions.expandAndFetchChapterSections(projectId, chapterId, sectionIds);
    }
  };

  DocumentSectionsV2Actions.prototype.expandAllChapters = function() {
    var allChapters, projectId;
    allChapters = alt.stores.DocumentSectionsV2Store.getChapters();
    projectId = mediator.project.id;
    return allChapters.forEach((function(_this) {
      return function(chapter) {
        return _this.actions.expandChapterSections({
          chapterId: chapter.get('_id'),
          projectId: projectId
        });
      };
    })(this));
  };

  DocumentSectionsV2Actions.prototype.expandAndFetchChapterSections = function(projectId, chapterId, sectionsIds) {
    this.dispatch(chapterId);
    return this.actions.fetchChapterSections(projectId, chapterId, sectionsIds);
  };

  DocumentSectionsV2Actions.prototype.fetchChapterSections = function(projectId, chapterId, sectionsIds) {
    this.dispatch(chapterId);
    return fetchDocs(projectId, sectionsIds, {
      conflicts: true
    }).then(maybeResolveSectionsConflicts(projectId)).then((function(_this) {
      return function(sections) {
        return _this.actions.fetchChapterSectionsSuccess({
          chapterId: chapterId,
          sections: sections
        });
      };
    })(this))["catch"]((function(_this) {
      return function(error) {
        return _this.actions.fetchChapterSectionsFailure({
          chapterId: chapterId,
          error: error
        });
      };
    })(this));
  };

  DocumentSectionsV2Actions.prototype.setChapterProperty = function(projectId, chapterId, key, value) {
    this.dispatch({
      chapterId: chapterId,
      key: key,
      value: value
    });
    return ChapterDoc.at(projectId, chapterId).updateAsInstance(callMethod('setProperty', key, value))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.setChapterData = function(projectId, chapterId, data) {
    this.dispatch({
      chapterId: chapterId,
      data: data
    });
    return ChapterDoc.at(projectId, chapterId).update(data)["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.createChapter = function(projectId, title, slug) {
    var doc;
    doc = chapterDocShape.defaults({
      title: title,
      slug: slug
    });
    this.dispatch(doc);
    return createChapter(projectId, doc);
  };

  DocumentSectionsV2Actions.prototype.insertSubchapter = function(projectId, parentChapter) {
    var doc, id, parentId, parentPrefix;
    parentId = parentChapter.get('_id');
    parentPrefix = parentId.split('_').slice(0, -1).join('_');
    id = _.isEmpty(parentPrefix) ? generateGUID() : "" + parentPrefix + "_" + (generateGUID());
    doc = chapterDocShape.defaults(_.extend({
      _id: id,
      parent: parentId,
      title: '',
      slug: ''
    }, _.pick(parentChapter.toJS(), ['visualizationId', 'mdaTopicId', 'chapterType', 'isFromAcoem', 'markedAsImportant'])));
    this.dispatch(doc);
    return createSubchapter(projectId, parentId, doc);
  };

  DocumentSectionsV2Actions.prototype.changeParentGroupId = function(_arg) {
    var newParentId, populationId, section;
    section = _arg.section, populationId = _arg.populationId, newParentId = _arg.newParentId;
    this.dispatch({
      section: section,
      populationId: populationId,
      newParentId: newParentId
    });
    return this.actions.saveSection(section);
  };

  DocumentSectionsV2Actions.prototype.saveSection = function(section) {
    var sectionData;
    sectionData = this.alt.stores.DocumentSectionsV2Store.getSection(section.get('parent'), section.get('_id')).toJS();
    return SectionDoc.at(mediator.project.id, section.get('_id')).update(sectionData)["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.deleteChapter = function(projectId, deletedChapterId) {
    var adapter, chapters, deletedChapter, deletedChapters, deletedChaptersIds, deletedSectionsIds, parentChapter, rootDoc, updatedParentOrRoot;
    adapter = mediator.services.storePersistenceAdapter;
    chapters = this.alt.stores.DocumentSectionsV2Store.getChapters().toJS();
    rootDoc = this.alt.stores.DocumentSectionsV2Store.getRoot();
    deletedChapter = chapters[deletedChapterId];
    deletedChaptersIds = concatChaptersIdsDeep([deletedChapterId], chapters);
    deletedChapters = deletedChaptersIds.map(function(chapterId) {
      var chapter;
      chapter = chapters[chapterId];
      return {
        _id: chapterId,
        _rev: chapter._rev,
        _deleted: true
      };
    });
    updatedParentOrRoot = deletedChapter.parent ? (parentChapter = chapters[deletedChapter.parent], _.assign({}, parentChapter, {
      subchapters: parentChapter.subchapters.filter(function(subchapterId) {
        return subchapterId !== deletedChapterId;
      })
    })) : rootDoc.update('chaptersOrder', R.filter(function(chapterId) {
      return chapterId !== deletedChapterId;
    })).toJS();
    deletedSectionsIds = flatMap(deletedChaptersIds, function(chapterId) {
      return chapters[chapterId].sections;
    });
    this.dispatch({
      deletedChaptersIds: deletedChaptersIds,
      deletedSectionsIds: deletedSectionsIds
    });
    return SectionDoc.at(projectId, deletedSectionsIds).fetch().then((function(_this) {
      return function(_arg) {
        var mdaTopicId, promise, rows, tableIds;
        rows = _arg.rows;
        mdaTopicId = _this.alt.stores.DocumentSectionsV2Store.getMdaTopicId();
        tableIds = _.chain(rows).pluck('doc').pluck('mdaTableId').compact().value();
        promise = (mdaTopicId != null) && tableIds.length > 0 ? deleteMdaConnectionsIfNeeded(projectId, rootDoc.toJS(), tableIds, mdaTopicId) : W.resolve();
        return promise.then(function() {
          var allDocs, deletedSections;
          deletedSections = rows.map(function(_arg1) {
            var doc;
            doc = _arg1.doc;
            if (doc) {
              return {
                _id: doc._id,
                _rev: doc._rev,
                _deleted: true
              };
            }
          });
          allDocs = [updatedParentOrRoot].concat(deletedChapters, _.compact(deletedSections));
          return adapter.bulkDocs(projectId, allDocs);
        });
      };
    })(this))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.setSectionProperty = function(projectId, chapterId, sectionId, key, value) {
    this.dispatch({
      chapterId: chapterId,
      sectionId: sectionId,
      key: key,
      value: value
    });
    return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('setProperty', key, value))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.addSectionAndExpand = function(projectId, chapterId, sectionsIds, prevSectionId) {
    if (prevSectionId == null) {
      prevSectionId = null;
    }
    return this.actions.addSection(projectId, chapterId, prevSectionId).then((function(_this) {
      return function(_arg) {
        var addedSectionsDocs, addedSectionsIds, allSectionsIds;
        addedSectionsDocs = _arg[0];
        addedSectionsIds = addedSectionsDocs.map(function(_arg1) {
          var id;
          id = _arg1.id;
          return id;
        });
        allSectionsIds = _.uniq(addedSectionsIds.concat(sectionsIds));
        return _this.actions.expandAndFetchChapterSections(projectId, chapterId, allSectionsIds);
      };
    })(this))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.addSection = function(projectId, chapterId, prevSectionId) {
    var doc;
    if (prevSectionId == null) {
      prevSectionId = null;
    }
    doc = getDefaultSectionDoc(chapterId);
    return this.actions.createSections(projectId, chapterId, prevSectionId, [doc]);
  };

  DocumentSectionsV2Actions.prototype.addRecommendationSectionsAndExpand = function(projectId, chapterId, sectionsIds, data, prevSectionId) {
    if (prevSectionId == null) {
      prevSectionId = null;
    }
    return this.actions.addRecommendationSections(projectId, chapterId, data, prevSectionId).then((function(_this) {
      return function(_arg) {
        var addedSectionsDocs, addedSectionsIds, allSectionsIds;
        addedSectionsDocs = _arg[0];
        addedSectionsIds = _.pluck(addedSectionsDocs, 'id');
        allSectionsIds = _.uniq(addedSectionsIds.concat(sectionsIds));
        return _this.actions.expandAndFetchChapterSections(projectId, chapterId, allSectionsIds);
      };
    })(this))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.addRecommendationSections = function(projectId, chapterId, data, prevSectionId) {
    var docs;
    if (prevSectionId == null) {
      prevSectionId = null;
    }
    docs = data.map(function(item) {
      return sectionDocShape.defaults(_.assign({
        parent: chapterId
      }, item));
    });
    return this.actions.createSections(projectId, chapterId, prevSectionId, docs);
  };

  DocumentSectionsV2Actions.prototype.duplicateSection = function(projectId, chapterId, originalSectionId) {
    var data, doc, originalSection;
    originalSection = alt.stores.DocumentSectionsV2Store.getChapterSections(chapterId).find(function(section) {
      return section.get('_id') === originalSectionId;
    }).toJS();
    data = _.pick(originalSection, ['title', 'parent', 'content', 'status']);
    doc = sectionDocShape.defaults(data);
    return this.actions.createSections(projectId, chapterId, originalSectionId, [doc]);
  };

  DocumentSectionsV2Actions.prototype.createSections = function(projectId, chapterId, prevSectionId, docs) {
    var docsWithMaybeTopicId, mdaTopicId;
    mdaTopicId = alt.stores.DocumentSectionsV2Store.getMdaTopicId();
    docsWithMaybeTopicId = mdaTopicId ? _.map(docs, function(doc) {
      return _.extend({
        mdaTopicId: mdaTopicId
      }, doc);
    }) : docs;
    this.dispatch({
      chapterId: chapterId,
      docs: docsWithMaybeTopicId
    });
    return createSections(projectId, chapterId, prevSectionId, docsWithMaybeTopicId);
  };

  DocumentSectionsV2Actions.prototype.deleteSection = function(projectId, chapterId, sectionId) {
    var mdaTableId, mdaTablePromise, mdaTopicId, rootDoc;
    mdaTableId = this.alt.stores.DocumentSectionsV2Store.getSections().getIn([chapterId, sectionId, 'mdaTableId']);
    mdaTopicId = this.alt.stores.DocumentSectionsV2Store.getMdaTopicId();
    mdaTablePromise = (mdaTableId != null) && (mdaTopicId != null) ? (rootDoc = this.alt.stores.DocumentSectionsV2Store.getRoot().toJS(), deleteMdaConnectionsIfNeeded(projectId, rootDoc, [mdaTableId], mdaTopicId)) : W.resolve();
    this.dispatch({
      chapterId: chapterId,
      sectionId: sectionId
    });
    return mdaTablePromise.then(function() {
      return W.all([SectionDoc.at(projectId, sectionId)["delete"](), ChapterDoc.at(projectId, chapterId).updateAsInstance(callMethod('deleteSection', sectionId))]);
    }).then(_.noop)["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.saveMovedChapter = function(projectId, chapterId, currentParentId) {
    var localChapter;
    localChapter = alt.stores.DocumentSectionsV2Store.getChapter(chapterId).toJS();
    return saveChapterDocs(projectId, chapterId, currentParentId, localChapter.parent);
  };

  DocumentSectionsV2Actions.prototype.changeChapterParentId = function(projectId, chapterId, currentParentId, newParentId) {
    this.dispatch({
      chapterId: chapterId,
      newParentId: newParentId
    });
    return saveChapterDocs(projectId, chapterId, currentParentId, newParentId);
  };

  DocumentSectionsV2Actions.prototype.saveMovedSection = function(projectId, chapterId) {
    var chapter;
    chapter = alt.stores.DocumentSectionsV2Store.getChapter(chapterId).toJS();
    return ChapterDoc.at(projectId, chapterId).updateAsInstance(callMethod('setProperty', 'sections', _.uniq(chapter.sections)))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.changeSectionParentId = function(projectId, chapterId, sectionId, newParentId) {
    var adapter, chapter, docs, newParent, section;
    adapter = mediator.services.storePersistenceAdapter;
    chapter = alt.stores.DocumentSectionsV2Store.getChapter(chapterId).toJS();
    newParent = alt.stores.DocumentSectionsV2Store.getChapter(newParentId).toJS();
    section = alt.stores.DocumentSectionsV2Store.getChapterSection(chapterId, sectionId).toJS();
    docs = [
      _.assign({}, chapter, {
        sections: chapter.sections.filter(function(sId) {
          return sId !== sectionId;
        })
      }), _.assign({}, newParent, {
        sections: _.uniq(newParent.sections.concat(sectionId))
      }), _.assign({}, section, {
        parent: newParentId
      })
    ];
    adapter.bulkDocs(projectId, docs).then(_.noop)["catch"](errorHandler);
    return this.dispatch({
      chapterId: chapterId,
      sectionId: sectionId,
      newParentId: newParentId
    });
  };

  DocumentSectionsV2Actions.prototype.saveChapterContent = function(payload) {
    var content, directSave, newContent, projectId, sectionId, store;
    projectId = payload.projectId, sectionId = payload.sectionId, content = payload.content, directSave = payload.directSave;
    store = alt.stores.DocumentSectionsV2Store;
    newContent = directSave ? content : store.getEditingSections().has(sectionId) ? store.getEditingSections().getIn([sectionId, 'content']) : (store.getSections() || Immutable.Map()).getIn([sectionId, 'content'], '');
    this.dispatch(payload);
    return SectionDoc.at(projectId, sectionId).updateAsInstance(R.compose(callMethod('setProperty', 'content', newContent), callMethod('setProperty', 'editingBy', null)))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.updateSectionEditingInfo = function(_arg) {
    var chapterId, editing, editingBy, projectId, sectionId, user;
    projectId = _arg.projectId, chapterId = _arg.chapterId, sectionId = _arg.sectionId, editing = _arg.editing, user = _arg.user;
    return;
    editingBy = editing ? {
      user: user,
      timestamp: Date.now()
    } : null;
    return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('setProperty', 'editingBy', editingBy));
  };

  DocumentSectionsV2Actions.prototype.dbChange = function(change, _pending, _lastSeq) {
    this.dispatch(change);
    return DocumentSectionsCommonActions.dbChange(change);
  };

  DocumentSectionsV2Actions.prototype.saveDiscussionItem = function(payload) {
    var discussionType, id, isDraft, mdaTableId, projectId, saveChapterPromise, sectionId, updatedData;
    this.dispatch(payload);
    projectId = payload.projectId, isDraft = payload.isDraft, id = payload.id, sectionId = payload.sectionId, discussionType = payload.discussionType, updatedData = payload.updatedData, mdaTableId = payload.mdaTableId;
    saveChapterPromise = isDraft ? this.actions.saveChapterContent({
      projectId: projectId,
      sectionId: sectionId,
      content: null,
      directSave: false
    }) : W.resolve();
    return saveChapterPromise.then(function() {
      var instanceUpdateMethod;
      DiscardChangesActions.clear();
      if (mdaTableId != null) {
        if (discussionType !== 'comments') {
          return;
        }
        return MdaTableDoc.at(projectId, mdaTableId).updateAsInstance(callMethod('updateAnnotation', id, updatedData))["catch"](errorHandler);
      } else {
        instanceUpdateMethod = discussionType === 'comments' ? 'updateAnnotation' : 'updateChange';
        return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod(instanceUpdateMethod, id, updatedData))["catch"](errorHandler);
      }
    });
  };

  DocumentSectionsV2Actions.prototype.updateSectionCodes = function(payload) {
    var codes, newCodes, projectId, sectionId;
    projectId = mediator.project.id;
    sectionId = payload.sectionId, codes = payload.codes;
    newCodes = codes.map(function(c) {
      return Immutable.fromJS(prepareCodeWithSanitizedValue(c));
    });
    return SectionDoc.at(projectId, sectionId).updateAsInstance(docInstSetAt('codes')(newCodes.toJS()))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.addReferenceToDoc = function(payload) {
    var Doc, chapterId, docId, projectId, refIds, references, referencesToInsert, sectionId;
    projectId = mediator.project.id;
    sectionId = payload.sectionId, chapterId = payload.chapterId, referencesToInsert = payload.referencesToInsert;
    Doc = sectionId ? SectionDoc : ChapterDoc;
    docId = sectionId != null ? sectionId : chapterId;
    refIds = referencesToInsert.refIds, references = referencesToInsert.references;
    return Doc.at(projectId, docId).updateAsInstance(R.compose(callMethod('setProperty', 'refIds', refIds || []), callMethod('setProperty', 'references', references || {})))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.removeCodeFromSection = function(payload) {
    var code, projectId, sectionId;
    projectId = mediator.project.id;
    sectionId = payload.sectionId, code = payload.code;
    return SectionDoc.at(projectId, sectionId).updateAsInstance(docInstUpdateAt('codes')(removeItemById(code.get('id'), 'id')))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.removeDiscussionItem = function(payload) {
    var discussionType, doc, item, mdaTableId, projectId, sectionId;
    projectId = payload.projectId, item = payload.item, sectionId = payload.sectionId, discussionType = payload.discussionType, mdaTableId = payload.mdaTableId;
    if (discussionType !== 'comments') {
      return;
    }
    this.dispatch(payload);
    doc = mdaTableId != null ? MdaTableDoc.at(projectId, mdaTableId) : SectionDoc.at(projectId, sectionId);
    return doc.updateAsInstance(callMethod('removeAnnotation', item))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.addDiscussionReply = function(payload) {
    var doc, mdaTableId, newId, parent, projectId, sectionId, text;
    sectionId = payload.sectionId, parent = payload.parent, mdaTableId = payload.mdaTableId, text = payload.text;
    projectId = mediator.project.id;
    doc = mdaTableId != null ? MdaTableDoc.at(projectId, mdaTableId) : SectionDoc.at(projectId, sectionId);
    newId = generateGUID().slice(0, 5);
    return doc.updateAsInstance(callMethod('addAnnotationReply', newId, _.extend({}, _.omit(parent, 'replies'), {
      isDraft: true,
      data: {
        text: text,
        context: {}
      },
      id: newId,
      parentId: parent.id,
      author: {
        id: mediator.user.id,
        name: mediator.user.getFullName()
      },
      time: Date.now()
    })))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.saveChangeDecision = function(decisionData) {
    var accepted, changeId, projectId, sectionId;
    this.dispatch(decisionData);
    projectId = decisionData.projectId, changeId = decisionData.changeId, sectionId = decisionData.sectionId, accepted = decisionData.accepted;
    return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('applyChangeDecision', changeId, accepted))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.setActiveDiscussionItem = function(current, prev) {
    var contentPos, id, projectId, scrollToContent, sectionId, storeValue;
    if (current) {
      this.dispatch(current);
      scrollToContent = current.scrollToContent, projectId = current.projectId, id = current.id;
      if (scrollToContent) {
        sectionId = scrollToContent.sectionId, contentPos = scrollToContent.contentPos;
        return this.actions.setScrollToSectionContent({
          sectionId: sectionId,
          contentPos: contentPos,
          projectId: projectId
        });
      }
    } else {
      if (prev) {
        storeValue = alt.stores.DocumentSectionsV2Store.getActiveDiscussionItem();
        if (prev.id === storeValue) {
          return this.dispatch(null);
        }
      } else {
        return this.dispatch(null);
      }
    }
  };

  DocumentSectionsV2Actions.prototype.setScrollToSectionContent = function(payload) {
    var chapter, chapterId, isChapterExpanded, projectId, sectionId;
    if (payload) {
      sectionId = payload.sectionId, projectId = payload.projectId;
      chapter = alt.stores.DocumentSectionsV2Store.findSectionChapter(sectionId);
      if (chapter) {
        chapterId = chapter.get('_id');
        isChapterExpanded = alt.stores.DocumentSectionsV2Store.isChapterExpanded(chapterId);
        if (isChapterExpanded) {
          return this.dispatch(payload);
        } else {
          this.actions.expandChapterSections({
            projectId: projectId,
            chapterId: chapterId
          }).then((function(_this) {
            return function() {
              return _.delay((function() {
                return _this.dispatch(payload);
              }), 500);
            };
          })(this));
        }
      }
    } else {
      return this.dispatch(null);
    }
  };

  DocumentSectionsV2Actions.prototype.setScrollToSection = function(payload) {
    var chapter, chapterId, isChapterExpanded, projectId, sectionId;
    if (payload) {
      sectionId = payload.sectionId, projectId = payload.projectId;
      chapter = alt.stores.DocumentSectionsV2Store.findSectionChapter(sectionId);
      if (chapter) {
        chapterId = chapter.get('_id');
        isChapterExpanded = alt.stores.DocumentSectionsV2Store.isChapterExpanded(chapterId);
        if (isChapterExpanded) {
          return this.dispatch(payload);
        } else {
          this.actions.expandChapterSections({
            projectId: projectId,
            chapterId: chapterId
          }).then((function(_this) {
            return function() {
              return _.delay((function() {
                return _this.dispatch(payload);
              }), 500);
            };
          })(this));
        }
      }
    } else {
      return this.dispatch(null);
    }
  };

  DocumentSectionsV2Actions.prototype.updateSectionAppendices = function(_arg) {
    var projectId, sectionId, selectedAppendices;
    sectionId = _arg.sectionId, projectId = _arg.projectId, selectedAppendices = _arg.selectedAppendices;
    return SectionDoc.at(projectId, sectionId).updateAsInstance(callMethod('setProperty', 'selectedAppendices', selectedAppendices))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.toggleMark = function(_arg) {
    var chapterId, checked, projectId;
    chapterId = _arg.chapterId, checked = _arg.checked;
    projectId = mediator.project.id;
    return ChapterDoc.at(projectId, chapterId).updateAsInstance(callMethod('setProperty', 'markedAsImportant', !Boolean(checked)))["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.removeAllHighlights = function(chapterId) {
    var adapter, chapters, sectionIds;
    if (chapterId == null) {
      chapterId = null;
    }
    adapter = mediator.services.storePersistenceAdapter;
    chapters = alt.stores.DocumentSectionsV2Store.getChapters();
    sectionIds = chapterId ? chapters.get(chapterId, Immutable.Map()).get('sections', Immutable.List()).toJS() : pluck(chapters, 'sections', Immutable.List()).valueSeq().flatten().toSet().toJS();
    return fetchDocs(mediator.project.id, sectionIds).then(function(docs) {
      var updatedDocs;
      updatedDocs = _.chain(docs).compact().map(function(doc) {
        var content;
        content = doc.content;
        return R.modifyPath(['content', 'doc', 'content'], removeHighlightMarks, doc);
      }).value();
      return adapter.bulkDocs(mediator.project.id, updatedDocs);
    })["catch"](errorHandler);
  };

  DocumentSectionsV2Actions.prototype.resolveSuggestions = function(chapterId, accept) {
    var chapters, sectionIds;
    if (chapterId == null) {
      chapterId = null;
    }
    chapters = alt.stores.DocumentSectionsV2Store.getChapters();
    sectionIds = chapterId ? chapters.get(chapterId, Immutable.Map()).get('sections', Immutable.List()).toJS() : pluck(chapters, 'sections', Immutable.List()).valueSeq().flatten().toSet().toJS();
    return W.all(_.map(sectionIds, function(sectionId) {
      return SectionDoc.at(mediator.project.id, sectionId).updateAsInstance(callMethod('applyAllChanges', accept));
    })).then((function(_this) {
      return function() {
        return _this.dispatch({
          sectionIds: sectionIds
        });
      };
    })(this))["catch"](errorHandler);
  };

  return DocumentSectionsV2Actions;

})();

createRootIfNotExists = function(projectId) {
  return RootDoc.at(projectId).fetch()["catch"](function(err) {
    if (err.status === 404) {
      return RootDoc.at(projectId).create();
    } else {
      throw err;
    }
  });
};

createChapter = function(projectId, doc) {
  var rootDocId;
  rootDocId = alt.stores.DocumentSectionsV2Store.getRoot().get('_id');
  return W.all([ChapterDoc.at(projectId).create(doc), RootDoc.at(projectId, rootDocId).updateAsInstance(callMethod('addChapter', doc._id))]).then(_.noop)["catch"](errorHandler);
};

createSubchapter = function(projectId, parentId, doc) {
  return W.all([ChapterDoc.at(projectId).create(doc), ChapterDoc.at(projectId, parentId).updateAsInstance(callMethod('addSubchapter', doc._id))]).then(_.noop)["catch"](errorHandler);
};

concatChaptersIdsDeep = function(chaptersIds, chapters) {
  var nextChaptersIds;
  if (_.isEmpty(chaptersIds)) {
    return [];
  }
  nextChaptersIds = flatMap(chaptersIds, function(subchapterId) {
    return chapters[subchapterId].subchapters;
  });
  return chaptersIds.concat(concatChaptersIdsDeep(nextChaptersIds, chapters));
};

getDefaultSectionDoc = function(chapterId) {
  return sectionDocShape.defaults({
    title: 'New section',
    parent: chapterId
  });
};

createSections = function(projectId, chapterId, prevSectionId, docs) {
  return W.all([SectionDoc.at(projectId).createMany(docs), ChapterDoc.at(projectId, chapterId).updateAsInstance(callMethod('addSections', _.pluck(docs, '_id'), prevSectionId))]).then(function(res) {
    return res;
  })["catch"](errorHandler);
};

fetchDocs = function(projectId, docsIds, options) {
  var adapter;
  adapter = mediator.services.storePersistenceAdapter;
  return adapter.fetch(projectId, docsIds, options).then(function(res) {
    return _.pluck(res.rows, 'doc');
  });
};

saveChapterDocs = function(projectId, chapterId, currentParentId, newParentId) {
  var adapter, docs, hasParentChanged;
  docs = [];
  hasParentChanged = newParentId !== currentParentId;
  adapter = mediator.services.storePersistenceAdapter;
  if (hasParentChanged) {
    docs.push(alt.stores.DocumentSectionsV2Store.getChapter(chapterId).toJS());
  }
  if (!currentParentId || !newParentId) {
    docs.push(alt.stores.DocumentSectionsV2Store.getRoot().toJS());
  }
  if (newParentId) {
    docs.push(alt.stores.DocumentSectionsV2Store.getChapter(newParentId).toJS());
  }
  if (currentParentId && hasParentChanged) {
    docs.push(alt.stores.DocumentSectionsV2Store.getChapter(currentParentId).toJS());
  }
  return adapter.bulkDocs(projectId, docs);
};

deleteMdaConnectionsIfNeeded = function(projectId, rootDoc, tableIds, topicId) {
  var adapter;
  adapter = mediator.services.storePersistenceAdapter;
  return getChapterAndSectionIds(projectId, rootDoc).then(function(_arg) {
    var sectionIds;
    sectionIds = _arg.sectionIds;
    if (sectionIds.length === 0) {
      return W.resolve();
    }
    return adapter.fetch(projectId, sectionIds).then(function(_arg1) {
      var promises, rows, tableIdsWithConnectionToRemove, tableSectionNums;
      rows = _arg1.rows;
      tableSectionNums = _.chain(rows).pluck('doc').compact().filter(function(_arg2) {
        var mdaTableId;
        mdaTableId = _arg2.mdaTableId;
        return __indexOf.call(tableIds, mdaTableId) >= 0;
      }).reduce(function(acc, _arg2) {
        var mdaTableId, _ref4;
        mdaTableId = _arg2.mdaTableId;
        acc[mdaTableId] = ((_ref4 = acc[mdaTableId]) != null ? _ref4 : 0) + 1;
        return acc;
      }, {}).value();
      tableIdsWithConnectionToRemove = _.chain(tableIds).map(function(tableId) {
        var sectionsNumWithTable, _ref4;
        sectionsNumWithTable = (_ref4 = tableSectionNums[tableId]) != null ? _ref4 : 0;
        if (sectionsNumWithTable <= 1) {
          return tableId;
        } else {
          return null;
        }
      }).compact().value();
      if (tableIdsWithConnectionToRemove.length > 0) {
        promises = _.chain(tableIdsWithConnectionToRemove).map(function(tableId) {
          return MdaTableDoc.at(projectId, tableId).updateAsInstance(callMethod('removeTopic', topicId));
        }).union([MdaTopicDoc.at(projectId, topicId).updateAsInstance(callMethod('removeTables', tableIdsWithConnectionToRemove))]).value();
        return W.all(promises).then(_.noop);
      } else {
        return W.resolve();
      }
    });
  });
};

module.exports = alt.createActions(DocumentSectionsV2Actions);
