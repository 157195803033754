appUtils = require 'lib/app_utils'
ConnectStore = require 'components/enhancers/connect_store'
ExportDialog = require 'components/common/export_dialog'
ExportEmbeddableCodeDialog = require 'components/common/export_embeddable_code_dialog'
mediator = require 'mediator'
Modal = require 'components/common/modal'
NMAVizActions = require 'actions/nma_viz_actions'
NMAVizStore = require 'stores/nma_viz_store'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'
{ useState } = React
W = require 'when/when'

storeConnector =
  NMAVizStore: (Store) ->
    isExportModalOpen: Store.isExportModalOpen()

OUTPUT_FORMATS = ['doc', 'html', 'external_link', 'external_embeddable_code']

NMAVizExportModal = ({ isExportModalOpen }) ->
  i18n = useI18n('nma:export_dialog')
  [iframeCode, setIframeCode] = useState null

  onExport = useCoffeeCallback [i18n, setIframeCode], ({ outputFormat }) ->
    promise = switch outputFormat
      when 'external_link'
        mediator.services.nmaExport.exportToLink().then (url) ->
          newTab = window.open 'about:blank', '_blank'
          newTab.location.href = url
      when 'external_embeddable_code'
        mediator.services.nmaExport.exportToIframe().then (iframeCode) ->
          setIframeCode iframeCode
      when 'html', 'doc'
        mediator.services.nmaExport.exportToFile(outputFormat)
      else
        W.reject "Unknown export format: #{outputFormat}"
    promise
      .catch (error) ->
        appUtils.errorHandlerWithMsg error, i18n 'export_failed'
      .finally NMAVizActions.closeExportModal

  clearIframeCode = useCoffeeCallback [setIframeCode], ->
    setIframeCode null

  <React.Fragment>
    <Modal
      isOpen={isExportModalOpen}
      modalSize="medium"
      title={i18n 'title'}
    >
      <ExportDialog
        onClose={NMAVizActions.closeExportModal}
        onDownload={onExport}
        outputFormats={OUTPUT_FORMATS}
      />
    </Modal>
    <ExportEmbeddableCodeDialog
      code={iframeCode}
      isOpen={not _.isEmpty iframeCode}
      onClose={clearIframeCode}
      title={i18n 'embeddable_code_title'}
    />
  </React.Fragment>

NMAVizExportModal.propTypes =
  isExportModalOpen: PropTypes.bool.isRequired

module.exports = ConnectStore NMAVizExportModal, NMAVizStore, storeConnector
