ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
DBEPPublicationActions = require 'actions/dbep_publication_actions'
DBEPPublicationStore = require 'stores/dbep_publication_store'
Modal = require 'components/common/modal'
RadioWithLabel = require 'components/common/radio_with_label'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

storeConnector =
  DBEPPublicationStore: (Store) ->
    isOpen: Store.isAdolopmentSettingsModalOpened()
    isAdolopedOnlyChecked: Store.isAdolopedOnlyChecked()
    mode: Store.getAdolopmentSettingsModalMode()
    questionId: Store.getAdolopmentSettingsModalQuestionId()

DBEPAdolopmentSettingsModal = ({ isAdolopedOnlyChecked, isOpen, mode, questionId }) ->
  i18n = useI18n 'dbep:adolopment_settings_modal'

  onSubmit = useCoffeeCallback [isAdolopedOnlyChecked, mode, questionId], ->
    isPreview = mode is 'preview'
    if Boolean questionId
      DBEPPublicationActions.openModalForOneQuestion isPreview, questionId, isAdolopedOnlyChecked
    else
      DBEPPublicationActions.openModal isPreview

  onOptionChange = useCoffeeCallback [], (itemValue) -> (e) ->
    e.preventDefault()
    DBEPPublicationActions.setAdolopedOnly itemValue

  <Modal
    className="dbep-adolopment-settings-modal"
    isOpen={isOpen}
    modalSize="medium"
    title={i18n 'title'}
  >
    <p>{i18n 'description'}</p>
    <div className="dbep-adolopment-settings-modal__options">
      <div>
        <RadioWithLabel
          checked={not isAdolopedOnlyChecked}
          label={i18n 'adoloped_with_original'}
          name="adoloped_only"
          onChange={onOptionChange false}
        />
      </div>
      <div>
        <RadioWithLabel
          checked={isAdolopedOnlyChecked}
          label={i18n 'adoloped_only'}
          name="adoloped_only"
          onChange={onOptionChange true}
        />
      </div>
    </div>
    <ApplyCancelButtons
      applyLabel={i18n '/actions.continue'}
      cancelLabel={i18n '/actions.cancel'}
      onCancel={DBEPPublicationActions.closeAdolopmentSettingsModal}
      onApply={onSubmit}
    />
  </Modal>

module.exports = ConnectStore DBEPAdolopmentSettingsModal, DBEPPublicationStore, storeConnector
