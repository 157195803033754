AccessRightsFormGroup = require 'components/team/form/access_rights_form_group'
PanelMemberFormGroup = require 'components/team/form/panel_member_form_group'
{
  getMemberAccessRights,
  getMemberPendingAccessRights,
  getMemberFullName
} = require 'lib/members_helper'


OrganizationMemberFormRow = createReactClass

  displayName: "OrganizationMemberFormRow"

  propTypes:
    adminsLimit: PropTypes.number.isRequired
    disabledAccessRights: PropTypes.instanceOf(Array)
    member: PropTypes.instanceOf(Immutable.Map).isRequired
    updateAttr: PropTypes.func.isRequired
    errors: PropTypes.instanceOf(Immutable.Map)

  getDefaultProps: ->
    disabledAccessRights: []
    errors: null

  updateAccessRights: (value) ->
    isPendingInvitation = @props.member.get 'pendingInvitation'
    attributeToUpdate = if isPendingInvitation
      'pendingInvitationAccessRights'
    else
      'accessRights'
    @props.updateAttr attributeToUpdate, Immutable.List([value])

  _getDisplayedAccessRights: (member) ->
    isPendingInvitation = member.get 'pendingInvitation'
    if isPendingInvitation
      getMemberPendingAccessRights member
    else
      getMemberAccessRights member

  togglePanelMemberRole: ->
    @props.updateAttr 'panelMember', not @props.member.get('panelMember', false)

  render: ->
    { adminsLimit, disabledAccessRights, errors, member } = @props

    <div className="organization-member-form-row">
      <h3>{getMemberFullName(member)}</h3>
      <div>
        <AccessRightsFormGroup
          adminsLimit={adminsLimit}
          disabledAccessRights={disabledAccessRights}
          errors={errors}
          member={member}
          selected={@_getDisplayedAccessRights member}
          updateAccessRights={@updateAccessRights}
        />
      </div>
      <div>
        <PanelMemberFormGroup
          member={member}
          onUpdate={@togglePanelMemberRole}
        />
      </div>
    </div>

module.exports = OrganizationMemberFormRow
