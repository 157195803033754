var CI_LEVEL, EMPTY_DATA, addDataToOutcome, addMissingInterventions, comparatorMapper, flipOutcomeData, gdtDataToNmaData, gdtInterventionToNmaVizIntervention, gdtOutcomeToNmaComparison, gdtOutcomeToNmaVizOutcomeForImport, gdtOutcomeTypeToDefaultEffectMeasure, gdtOutcomeTypeToNmaVizOutcomeType, get, getAllGdtDataForOutcome, getMappingStepData, getOutcomesData, getTranslationKeyPostFix, importNMAData, interventionMapper, isAcpFeaturesOn, nmaDataToGdtData, nmaDataToVizData, nmaOutcomeDataToGdtData, nmaVizInterventionToGdtIntervention, parseEstimate, parseImportResultsToGdtOutcome, partition, pickOneToOneGdtAndNmaOutcomeDataProps, pluck, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

get = require('lodash').get;

flipOutcomeData = NmaComponents.flipOutcomeData, getOutcomesData = NmaComponents.getOutcomesData;

_ref = require('lib/immutable_utils'), partition = _ref.partition, pluck = _ref.pluck;

isAcpFeaturesOn = require('stores/utils/etd_template_helper').isAcpFeaturesOn;

CI_LEVEL = 0.95;

gdtInterventionToNmaVizIntervention = function(interventionImm) {
  return {
    id: interventionImm.get('_id'),
    name: interventionImm.get('name')
  };
};

gdtOutcomeTypeToNmaVizOutcomeType = function(outcomeType) {
  switch (outcomeType) {
    case 'dich':
      return 'Dichotomous';
    case 'cont':
      return 'Continuous';
    case 'timeToEvent':
      return 'TimeToEvent';
    default:
      return 'Other';
  }
};

gdtOutcomeToNmaVizOutcomeForImport = function(outcomeImm) {
  return {
    id: outcomeImm.get('_id'),
    higherIsBetter: Boolean(outcomeImm.get('higherIsBetter')),
    name: outcomeImm.get('name'),
    type: gdtOutcomeTypeToNmaVizOutcomeType(outcomeImm.get('type'))
  };
};

gdtOutcomeTypeToDefaultEffectMeasure = function(outcomeType) {
  switch (outcomeType) {
    case 'dich':
      return 'RR';
    case 'cont':
      return 'MD';
    case 'timeToEvent':
      return 'HR';
    default:
      return 'Other';
  }
};

parseEstimate = function(estimates, estimateType) {
  var estimate, estimateObj;
  estimate = estimates[estimateType];
  if (!estimate) {
    return;
  }
  estimateObj = {
    ciLevel: CI_LEVEL,
    absoluteEffectCiLevel: CI_LEVEL
  };
  return _.extend(estimateObj, _.pick(estimate, 'ciHigh', 'ciLow', 'effectMeasure', 'i2', 'incoherence', 'value', 'absoluteEffectValue', 'absoluteEffectCiLow', 'absoluteEffectCiHigh', 'autoCalculateAbsoluteEffect'));
};

parseImportResultsToGdtOutcome = function(_arg, outcomeId) {
  var comparisons, data, interventions, usedInterventionIds;
  comparisons = _arg.comparisons, interventions = _arg.interventions;
  data = _.map(comparisons, function(_arg1) {
    var comparatorId, estimates, interventionId, outcomes;
    comparatorId = _arg1.comparatorId, interventionId = _arg1.interventionId, outcomes = _arg1.outcomes;
    estimates = get(outcomes, "" + outcomeId + ".estimates", []);
    return {
      comparatorId: comparatorId,
      interventionId: interventionId,
      outcomeId: outcomeId,
      numberOfStudies: get(outcomes, "" + outcomeId + ".numberOfStudies"),
      totalNumberOfParticipants: get(outcomes, "" + outcomeId + ".totalNumberOfParticipants"),
      nmaEffect: parseEstimate(estimates, 'network'),
      directEffect: parseEstimate(estimates, 'direct'),
      indirectEffect: parseEstimate(estimates, 'indirect')
    };
  });
  usedInterventionIds = _.chain(data).map(function(comparison) {
    return [comparison.interventionId, comparison.comparatorId];
  }).flatten().uniq().value();
  return {
    interventions: interventions,
    data: data,
    usedInterventionIds: usedInterventionIds
  };
};

nmaVizInterventionToGdtIntervention = function(_arg) {
  var id, name;
  id = _arg.id, name = _arg.name;
  return {
    _id: id,
    name: name,
    namesFromFiles: [name]
  };
};

addMissingInterventions = function(question, interventionsToImport) {
  return question.update('interventions', function(currentInterventions) {
    if (currentInterventions == null) {
      currentInterventions = Immutable.List();
    }
    interventionsToImport = _.chain(interventionsToImport).map(nmaVizInterventionToGdtIntervention).reject(function(interventionToCheck) {
      return currentInterventions.some(function(intervention) {
        return intervention.get('_id') === interventionToCheck._id;
      });
    }).map(function(intervention) {
      return Immutable.fromJS(intervention);
    }).value();
    return currentInterventions.concat(interventionsToImport);
  });
};

addDataToOutcome = function(outcome, data, interventionsMapping, interventionIdsToImport) {
  return outcome.update('data', function(currentData) {
    var dataToImport;
    if (currentData == null) {
      currentData = Immutable.List();
    }
    dataToImport = _.chain(data).map(function(dataItem) {
      var comparatorId, comparatorMappingValue, interventionId, interventionMappingValue;
      interventionId = dataItem.interventionId, comparatorId = dataItem.comparatorId;
      interventionMappingValue = interventionsMapping.get(interventionId, interventionId);
      comparatorMappingValue = interventionsMapping.get(comparatorId, comparatorId);
      if (interventionMappingValue !== '_add' && interventionMappingValue !== '_delete' && interventionMappingValue !== '_assign') {
        dataItem.interventionId = interventionMappingValue;
      }
      if (comparatorMappingValue !== '_add' && comparatorMappingValue !== '_delete' && comparatorMappingValue !== '_assign') {
        dataItem.comparatorId = comparatorMappingValue;
      }
      return dataItem;
    }).filter(function(_arg) {
      var comparatorId, interventionId;
      interventionId = _arg.interventionId, comparatorId = _arg.comparatorId;
      return __indexOf.call(interventionIdsToImport, interventionId) >= 0 && __indexOf.call(interventionIdsToImport, comparatorId) >= 0;
    }).map(function(dataItem) {
      return Immutable.fromJS(dataItem);
    }).value();
    return currentData.concat(dataToImport);
  });
};

importNMAData = function(data, usedInterventions, question, outcome, interventionsMapping, fileName) {
  var interventionsToImport;
  interventionsToImport = _.chain(usedInterventions).map(function(intervention) {
    var mappingValue;
    mappingValue = interventionsMapping.get(intervention.id);
    if ((mappingValue == null) || mappingValue === '_delete') {
      return;
    }
    if (mappingValue === '_add') {
      return intervention;
    }
    return {
      id: mappingValue,
      name: intervention.name
    };
  }).compact().value();
  question = addMissingInterventions(question, interventionsToImport);
  outcome = addDataToOutcome(outcome, data, interventionsMapping, _.pluck(interventionsToImport, 'id'));
  outcome = outcome.set('fileName', fileName);
  return {
    question: question,
    outcome: outcome
  };
};

getMappingStepData = function(question, parsedData) {
  var data, isNewImport, mapping, matchedInterventions, questionInterventionIds, unmatchedInterventions, usedInterventionIds, usedInterventions, _ref1;
  isNewImport = question.get('interventions', Immutable.List()).isEmpty();
  usedInterventionIds = parsedData.get('usedInterventionIds', Immutable.List());
  questionInterventionIds = pluck(question.get('interventions', Immutable.List()), '_id');
  usedInterventions = parsedData.get('interventions', Immutable.List()).filter(function(intervention) {
    return usedInterventionIds.includes(intervention.get('id'));
  });
  _ref1 = partition(usedInterventions, function(intervention) {
    return isNewImport || questionInterventionIds.includes(intervention.get('id'));
  }), matchedInterventions = _ref1[0], unmatchedInterventions = _ref1[1];
  mapping = isNewImport ? _.chain(usedInterventionIds.toJS()).map(function(interventionId) {
    return [interventionId, '_add'];
  }).object().value() : _.chain(pluck(matchedInterventions, 'id').toJS()).map(function(interventionId) {
    return [interventionId, interventionId];
  }).object().value();
  data = parsedData.get('data', Immutable.List());
  return Immutable.fromJS({
    data: data,
    mapping: mapping,
    matchedInterventions: matchedInterventions,
    unmatchedInterventions: unmatchedInterventions
  });
};

EMPTY_DATA = {
  interventions: [],
  comparisons: [],
  outcomes: []
};

pickOneToOneGdtAndNmaOutcomeDataProps = function(outcomeData) {
  return _.pick(outcomeData, 'interventionData', 'comparisonData', 'numberOfStudies', 'interpretation', 'effectClassification', 'totalNumberOfParticipants', 'reverseInterpretation', 'advancedOptions', 'useAdvancedOptions', 'rct', 'estimatesEnabled', 'explanations');
};

gdtOutcomeToNmaComparison = function(interventionId, comparatorId, outcomes) {
  var outcomesData;
  outcomesData = _.chain(outcomes.toJS()).map(function(outcome) {
    var directEffect, effectToUse, estimates, indirectEffect, nmaEffect, outcomeData, outcomeId, outcomeObj;
    outcomeId = outcome._id;
    outcomeData = _.find(outcome.data, function(dataItem) {
      return dataItem.interventionId === interventionId && dataItem.comparatorId === comparatorId;
    });
    if (!outcomeData) {
      return;
    }
    nmaEffect = outcomeData.nmaEffect, directEffect = outcomeData.directEffect, indirectEffect = outcomeData.indirectEffect, effectToUse = outcomeData.effectToUse;
    estimates = _.chain([['network', nmaEffect], ['direct', directEffect], ['indirect', indirectEffect]]).filter(function(_arg) {
      var value, _estimateType;
      _estimateType = _arg[0], value = _arg[1];
      return value != null;
    }).object().value();
    outcomeObj = _.extend({
      outcomeId: outcomeId,
      estimates: estimates,
      estimateToUse: effectToUse
    }, pickOneToOneGdtAndNmaOutcomeDataProps(outcomeData));
    return [outcomeId, outcomeObj];
  }).compact().object().value();
  if (_.isEmpty(outcomesData)) {
    return;
  }
  return {
    interventionId: interventionId,
    comparatorId: comparatorId,
    outcomes: outcomesData
  };
};

comparatorMapper = function(interventionId, outcomes) {
  return function(comparatorId) {
    if (interventionId === comparatorId) {
      return;
    }
    return gdtOutcomeToNmaComparison(interventionId, comparatorId, outcomes);
  };
};

interventionMapper = function(interventionIds, outcomes) {
  return function(interventionId) {
    return _.chain(interventionIds).map(comparatorMapper(interventionId, outcomes)).compact().value();
  };
};

gdtDataToNmaData = function(question, outcomes) {
  var comparisons, explanations, interventionIds, interventions, outcomesOrder, outcomesToPass;
  if (question.isEmpty() || outcomes.isEmpty()) {
    return EMPTY_DATA;
  }
  outcomesOrder = question.get('outcomes', Immutable.List()).toJS();
  interventions = _.map(question.get('interventions', Immutable.List()).toJS(), function(_arg) {
    var name, _id;
    _id = _arg._id, name = _arg.name;
    return {
      id: _id,
      name: name
    };
  });
  interventionIds = _.pluck(interventions, 'id');
  comparisons = _.chain(interventionIds).map(interventionMapper(interventionIds, outcomes)).flatten().value();
  outcomesToPass = _.map(outcomes.toJS(), function(outcome) {
    var outcomeType;
    outcomeType = gdtOutcomeTypeToNmaVizOutcomeType(outcome.type);
    return _.extend({
      id: outcome._id,
      type: outcomeType
    }, _.pick(outcome, 'name', 'higherIsBetter', 'baselineRisk', 'T1', 'T2', 'T3', 'units'));
  }).sort(function(a, b) {
    return outcomesOrder.indexOf(a.id) - outcomesOrder.indexOf(b.id);
  });
  explanations = question.get('explanations', Immutable.Map()).toJS();
  return {
    interventions: interventions,
    comparisons: comparisons,
    outcomes: outcomesToPass,
    explanations: explanations
  };
};

nmaOutcomeDataToGdtData = function(outcomeData, interventionId, comparatorId) {
  var estimateToUse, estimates, nmaEstimates, outcomeId;
  nmaEstimates = outcomeData.estimates, estimateToUse = outcomeData.estimateToUse, outcomeId = outcomeData.outcomeId;
  estimates = _.chain(nmaEstimates).map(function(estimate, estimateType) {
    var key;
    if (!estimate) {
      return;
    }
    key = (function() {
      switch (estimateType) {
        case 'network':
          return 'nmaEffect';
        case 'direct':
          return 'directEffect';
        case 'indirect':
          return 'indirectEffect';
        default:
          return null;
      }
    })();
    if (!key) {
      return;
    }
    return [key, estimate];
  }).compact().object().value();
  return _.extend({
    interventionId: interventionId,
    comparatorId: comparatorId,
    outcomeId: outcomeId,
    effectToUse: estimateToUse
  }, estimates, pickOneToOneGdtAndNmaOutcomeDataProps(outcomeData));
};

nmaDataToGdtData = function(data, outcomeId) {
  return _.chain(data).map(function(_arg) {
    var comparatorId, interventionId, outcomeData, outcomes;
    interventionId = _arg.interventionId, comparatorId = _arg.comparatorId, outcomes = _arg.outcomes;
    outcomeData = outcomes[outcomeId];
    if (!outcomeData) {
      return;
    }
    return nmaOutcomeDataToGdtData(outcomeData, interventionId, comparatorId);
  }).compact().value();
};

nmaDataToVizData = function(interventions, selectedInterventions, comparisons, comparatorId, comparatorTableMode) {
  if (comparatorTableMode === 'CommonComparator') {
    return _.map(selectedInterventions, function(_arg) {
      var interventionId;
      interventionId = _arg.id;
      return {
        rowElement: interventions.find(function(intervention) {
          return intervention.id === interventionId;
        }),
        comparator: interventions.find(function(intervention) {
          return intervention.id === comparatorId;
        }),
        outcomes: getOutcomesData(interventionId, comparatorId, comparisons)
      };
    });
  } else {
    return _.map(selectedInterventions, function(_arg) {
      var interventionId;
      interventionId = _arg.id;
      return {
        rowElement: interventions.find(function(intervention) {
          return intervention.id === comparatorId;
        }),
        comparator: interventions.find(function(intervention) {
          return intervention.id === interventionId;
        }),
        outcomes: getOutcomesData(comparatorId, interventionId, comparisons)
      };
    });
  }
};

getAllGdtDataForOutcome = function(outcome) {
  var alreadyPresentData, comparatorId, data, gdtData, interventionId, interventionIds, nmaComparison, nmaDataFlipped, outcomeData, outcomeId, _i, _j, _len, _len1;
  outcomeId = outcome._id, data = outcome.data;
  interventionIds = _.chain(data).map(function(_arg) {
    var comparatorId, interventionId;
    comparatorId = _arg.comparatorId, interventionId = _arg.interventionId;
    return [comparatorId, interventionId];
  }).flatten().uniq().value();
  alreadyPresentData = {};
  _.each(data, function(dataItem) {
    var comparatorId, interventionId;
    interventionId = dataItem.interventionId, comparatorId = dataItem.comparatorId;
    if (!alreadyPresentData["" + interventionId + "-" + comparatorId]) {
      return alreadyPresentData["" + interventionId + "-" + comparatorId] = dataItem;
    }
  });
  for (_i = 0, _len = interventionIds.length; _i < _len; _i++) {
    interventionId = interventionIds[_i];
    for (_j = 0, _len1 = interventionIds.length; _j < _len1; _j++) {
      comparatorId = interventionIds[_j];
      if (alreadyPresentData["" + interventionId + "-" + comparatorId]) {
        continue;
      }
      nmaComparison = gdtOutcomeToNmaComparison(comparatorId, interventionId, Immutable.List([outcome]));
      if (!nmaComparison) {
        continue;
      }
      outcomeData = nmaComparison.outcomes[outcomeId];
      if (!outcomeData) {
        continue;
      }
      nmaDataFlipped = flipOutcomeData(outcomeData, false);
      gdtData = nmaOutcomeDataToGdtData(nmaDataFlipped, interventionId, comparatorId);
      alreadyPresentData["" + interventionId + "-" + comparatorId] = gdtData;
    }
  }
  return _.values(alreadyPresentData);
};

getTranslationKeyPostFix = function() {
  if (isAcpFeaturesOn()) {
    return '_acp';
  } else {
    return '';
  }
};

module.exports = {
  addMissingInterventions: addMissingInterventions,
  CI_LEVEL: CI_LEVEL,
  gdtDataToNmaData: gdtDataToNmaData,
  gdtInterventionToNmaVizIntervention: gdtInterventionToNmaVizIntervention,
  gdtOutcomeToNmaVizOutcomeForImport: gdtOutcomeToNmaVizOutcomeForImport,
  gdtOutcomeTypeToDefaultEffectMeasure: gdtOutcomeTypeToDefaultEffectMeasure,
  gdtOutcomeTypeToNmaVizOutcomeType: gdtOutcomeTypeToNmaVizOutcomeType,
  getAllGdtDataForOutcome: getAllGdtDataForOutcome,
  getMappingStepData: getMappingStepData,
  getTranslationKeyPostFix: getTranslationKeyPostFix,
  importNMAData: importNMAData,
  nmaDataToGdtData: nmaDataToGdtData,
  nmaDataToVizData: nmaDataToVizData,
  nmaOutcomeDataToGdtData: nmaOutcomeDataToGdtData,
  parseImportResultsToGdtOutcome: parseImportResultsToGdtOutcome
};
