Select = require 'components/common/select_custom'
mediator = require 'mediator'
{ useCoffeeMemo } = require 'lib/react_utils'

ConclusionsSectionTitleRow = ({
  sectionId,
  colSpan,
  title,
  options,
  selectedOption,
  onChange,
  renderMode
}) ->

  selectedOptionText = useCoffeeMemo [renderMode, options, selectedOption], ->
    renderMode is 'printout' and
      options.find((option) -> option.get('value') is selectedOption)?.get('text')

  renderOptionsSelect = not options.isEmpty() and renderMode isnt 'printout' and
    sectionId not in [
      'multipleRecommendations',
      'mdgRecommendation',
      'implementationConsiderationsOrRemarks'
    ]

  showAsterix = title.toLowerCase() is 'recommendation' and
    mediator.services.switches.isServerSwitchOn('tbFeatures')

  titleClassNames = classNames 'flex-grow', 'asterixed': showAsterix

  <tr>
    <td className='recommendation-section-title' colSpan={colSpan}>
      <div className="flex flex-row items-center">
        <h2 className={titleClassNames}>
          {title}{selectedOptionText and <span> - {selectedOptionText}</span>}
        </h2>
        {renderOptionsSelect and <Select
          className="ml-10"
          options={options.toJS()}
          selected={selectedOption}
          onChange={onChange}
        />}
      </div>
    </td>
  </tr>

ConclusionsSectionTitleRow.propTypes =
  colSpan: PropTypes.number.isRequired,
  onChange: PropTypes.func
  options: PropTypes.instanceOf(Immutable.List)
  renderMode: PropTypes.string
  selectedOption: PropTypes.string
  title: PropTypes.string.isRequired,


ConclusionsSectionTitleRow.defaultProps =
  onChange: null
  options: Immutable.List()
  selectedOption: null
  renderMode: 'regular'

module.exports = ConclusionsSectionTitleRow
