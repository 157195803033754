var Editor, annotationConverter, convertFromDraftJSToWordDoc, convertHTMLToWordDoc, defaultDocxSerializer, getImageBuffer, getSchema, insertExtension, prepareState, processExtensions, removeHTMLNode, renderEditorValue, sectionLinksExtension, serializeNode,
  __slice = [].slice;

Editor = Epiditor["default"], processExtensions = Epiditor.processExtensions, getSchema = Epiditor.getSchema, prepareState = Epiditor.prepareState;

defaultDocxSerializer = EpiditorDocx.defaultDocxSerializer;

removeHTMLNode = require('base/lib/utils').removeHTMLNode;

renderEditorValue = require('lib/services/covid_exporter/utils').renderEditorValue;

insertExtension = require('components/document_sections/v2/editor_extensions/insert/insert');

sectionLinksExtension = require('components/document_sections/v2/editor_extensions/section_links/section_links');

annotationConverter = function(annotation) {
  return new Promise(function(resolve) {
    var element, onLoad, reactContainer;
    reactContainer = document.createElement('div');
    onLoad = function(_arg) {
      var div, dom, text, _ref, _ref1;
      dom = _arg.dom;
      div = document.createElement('div');
      text = $(div).addClass('epiditor--container').prop('innerHTML', dom.outerHTML).text();
      removeHTMLNode(div);
      ReactDOM.unmountComponentAtNode(reactContainer);
      removeHTMLNode(reactContainer);
      return resolve({
        author: (_ref = (_ref1 = annotation.author) != null ? _ref1.name : void 0) != null ? _ref : '',
        date: new Date(annotation.time),
        text: text
      });
    };
    element = React.createElement(Editor, {
      extensions: [],
      initialState: annotation.data.text,
      mode: 'readonly',
      onLoad: onLoad
    });
    return ReactDOM.render(element, reactContainer);
  });
};

getImageBuffer = function(imageData) {
  return function(src) {
    var imageDataObj;
    imageDataObj = Object.assign.apply(Object, [{}].concat(__slice.call(imageData)));
    return Buffer.from(imageDataObj["" + src], 'base64');
  };
};

serializeNode = function(node, options) {
  var annotations, annotationsToPass, commentStartIdx, imageData, optionsToPass, orientation, schema;
  annotations = options.annotations, commentStartIdx = options.commentStartIdx, imageData = options.imageData, schema = options.schema, orientation = options.orientation;
  if (imageData == null) {
    imageData = {};
  }
  annotationsToPass = _.chain(annotations != null ? annotations : {}).pairs().reject(function(_arg) {
    var annotation, _id;
    _id = _arg[0], annotation = _arg[1];
    return annotation.resolved;
  }).object().value();
  optionsToPass = {
    annotations: annotationsToPass,
    annotationConverter: annotationConverter,
    commentStartIdx: commentStartIdx,
    getImageBuffer: getImageBuffer(imageData),
    schema: schema,
    properties: {
      sectionProperties: {
        page: {
          size: {
            orientation: orientation
          }
        }
      }
    }
  };
  return defaultDocxSerializer.serialize(node, optionsToPass);
};

convertFromDraftJSToWordDoc = function(content) {
  var html;
  html = renderEditorValue(content);
  return convertHTMLToWordDoc(html);
};

convertHTMLToWordDoc = function(html) {
  var contentState, extensions, schema;
  extensions = processExtensions([sectionLinksExtension(), insertExtension()]);
  schema = getSchema(extensions.nodes, extensions.marks);
  contentState = prepareState(schema, [], html);
  return serializeNode(contentState.doc, {
    schema: schema,
    orientation: 'landscape'
  }).then(function(_arg) {
    var result;
    result = _arg.result;
    return result;
  });
};

module.exports = {
  serializeNode: serializeNode,
  convertHTMLToWordDoc: convertHTMLToWordDoc,
  convertFromDraftJSToWordDoc: convertFromDraftJSToWordDoc
};
