var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "comparatorTest";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <tr>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </tr>\n  <tr>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </tr>\n";
},"4":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <tr class=\"dx-tests\">\n      <td colspan=\"2\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</td>\n      <td colspan=\"2\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</td>\n    </tr>\n";
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <td class=\"label-cell\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sensitivity", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"value-cell\" data-property=\"pooledSensitivity\">\n        "
    + escapeExpression(((helpers.sensitivity || (depth0 && depth0.sensitivity) || helperMissing).call(depth0, depth0, false, {"name":"sensitivity","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"label-cell\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sensitivity", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"value-cell\" data-property=\"pooledSensitivityComparison\">\n        "
    + escapeExpression(((helpers.sensitivity || (depth0 && depth0.sensitivity) || helperMissing).call(depth0, depth0, true, {"name":"sensitivity","hash":{},"data":data})))
    + "\n      </td>\n";
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <td class=\"label-cell\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sensitivity", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"value-cell\" data-property=\"pooledSensitivity\">\n        "
    + escapeExpression(((helpers.sensitivity || (depth0 && depth0.sensitivity) || helperMissing).call(depth0, depth0, false, {"name":"sensitivity","hash":{},"data":data})))
    + "\n      </td>\n";
},"10":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <td class=\"label-cell\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "specificity", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"value-cell\" data-property=\"pooledSpecificity\">\n        "
    + escapeExpression(((helpers.specificity || (depth0 && depth0.specificity) || helperMissing).call(depth0, depth0, false, {"name":"specificity","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"label-cell\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "specificity", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"value-cell\" data-property=\"pooledSpecificityComparison\">\n        "
    + escapeExpression(((helpers.specificity || (depth0 && depth0.specificity) || helperMissing).call(depth0, depth0, true, {"name":"specificity","hash":{},"data":data})))
    + "\n      </td>\n";
},"12":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <td class=\"label-cell\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "specificity", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td class=\"value-cell\" data-property=\"pooledSpecificity\">\n        "
    + escapeExpression(((helpers.specificity || (depth0 && depth0.specificity) || helperMissing).call(depth0, depth0, false, {"name":"specificity","hash":{},"data":data})))
    + "\n      </td>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "&nbsp;\n<table width=\"100%\" class=\"standard-table diagnostic-pooled-values ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTest : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n  cellpadding=\"0\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome.diagnostic", {"name":"i18nNamespace","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</table>\n&nbsp;\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}