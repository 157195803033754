{ CHAPTER_SECTION_DND_TYPE } = require 'lib/document_sections_v2_helper'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
{
  dragSpec, dropSpec, dragCollect, dropCollect
} = require 'components/document_sections/v2/chapter_section_dnd_spec'
MdaTableSection = require 'components/document_sections/v2/mda_table_section'
mediator = require 'mediator'
RecommendationSection = require 'components/document_sections/v2/recommendation_section'
{ scrollIntoView } = require 'lib/epiditor_utils'
TextSection = require 'components/document_sections/v2/text_section'
CustomSection = require 'components/document_sections/v2/custom_section'

SectionContainer = createReactClass
  displayName: 'SectionContainer'

  propTypes:
    acceptedOrRejectedChange: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      changeId: PropTypes.string.isRequired
      accepted: PropTypes.bool.isRequired
    })
    chapterId: PropTypes.string.isRequired
    draftDiscussions: PropTypes.instanceOf(Immutable.Map)
    editingSection: PropTypes.instanceOf(Immutable.Map)
    hasChanges: PropTypes.bool.isRequired
    index: PropTypes.number.isRequired
    mdaTable: PropTypes.instanceOf(Immutable.Map)
    mdaTopicId: PropTypes.string
    onAddCode: PropTypes.func.isRequired
    onDelete: PropTypes.func.isRequired
    onInsertMdaTable: PropTypes.func.isRequired
    onInsertRecommendation: PropTypes.func.isRequired
    onInsertReferences: PropTypes.func.isRequired
    question: PropTypes.instanceOf(Immutable.Map)
    recommendation: PropTypes.instanceOf(Immutable.Map)
    scrollSectionIntoView: PropTypes.bool.isRequired
    section: PropTypes.instanceOf(Immutable.Map).isRequired
    suggesting: PropTypes.bool
    scrollToSectionContent: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      contentPos: PropTypes.number.isRequired
    })
    updatedDraftDiscussion: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      discussionType: PropTypes.string.isRequired
      data: PropTypes.object
    })

  scrollingOnMount: false

  componentDidMount: ->
    { scrollSectionIntoView } = @props
    if scrollSectionIntoView
      @scrollingOnMount = true
      delay =>
        @scrollingOnMount = false
        @processScrolling()

  componentDidUpdate: ->
    { scrollSectionIntoView } = @props
    if scrollSectionIntoView and not @scrollingOnMount
      @processScrolling()

  processScrolling: ->
    scrollIntoView(@item)
    @item.classList.toggle 'highlight-project'
    delay =>
      DocumentSectionsV2Actions.setScrollToSection(null)
      @item.classList.toggle 'highlight-project'

  getRef: (el) ->
    @item = el

  isSuggesting: ->
    { mdaTopicId, suggesting } = @props
    if suggesting
      true
    else
      mdaTopicId? and _.contains mediator.user.getAccessRights(), 'mda_mar'

  isReadOnly: ->
    { mdaTopicId } = @props
    mdaTopicId? and _.contains mediator.user.getAccessRights(), 'mda_mab'

  render: ->
    {
      acceptedOrRejectedChange,
      chapterId,
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      draftDiscussions,
      editingSection,
      hasChanges
      isDragging,
      mdaTable,
      mdaTopicId
      onAddCode,
      onDelete,
      onInsertMdaTable,
      onInsertRecommendation,
      onInsertReferences,
      question,
      recommendation,
      scrollToSectionContent,
      section,
      updatedDraftDiscussion,
      view
    } = @props

    sectionId = section.get('_id')
    className = classNames('chapter-section', {dragging: isDragging})

    hasContentToScrollIntoView = scrollToSectionContent?.sectionId is sectionId

    connectDragPreview(
      connectDropTarget(
        <div ref={@getRef} className={className} id={sectionId}>
          {if recommendation
            <RecommendationSection
              connectDragSource={connectDragSource}
              onDelete={onDelete}
              onInsertRecommendation={onInsertRecommendation}
              question={question}
              recommendation={recommendation}
              section={section}
            />
          else if mdaTable
            <MdaTableSection
              connectDragSource={connectDragSource}
              mdaTopicId={mdaTopicId}
              onDelete={onDelete}
              onInsertMdaTable={onInsertMdaTable}
              scrollToSectionContent={scrollToSectionContent if hasContentToScrollIntoView}
              section={section}
              table={mdaTable}
              onAddCode={onAddCode}
            />
          else if section.get('sectionType', null)
            <CustomSection
              view={view}
              chapterId={chapterId}
              connectDragSource={connectDragSource}
              editingSection={editingSection}
              onDelete={onDelete}
              onInsertRecommendation={onInsertRecommendation}
              readOnly={@isReadOnly()}
              scrollToSectionContent={scrollToSectionContent if hasContentToScrollIntoView}
              section={section}
            />
          else
            <TextSection
              acceptedOrRejectedChange={acceptedOrRejectedChange}
              chapterId={chapterId}
              connectDragSource={connectDragSource}
              draftDiscussions={draftDiscussions}
              editingSection={editingSection}
              hasChanges={hasChanges}
              mdaTopicId={mdaTopicId}
              onDelete={onDelete}
              onInsertMdaTable={onInsertMdaTable}
              onInsertRecommendation={onInsertRecommendation}
              onInsertReferences={onInsertReferences}
              readOnly={@isReadOnly()}
              scrollToSectionContent={scrollToSectionContent if hasContentToScrollIntoView}
              section={section}
              suggesting={@isSuggesting()}
              updatedDraftDiscussion={updatedDraftDiscussion}
              view={view}
            />
          }
        </div>
      )
    )

delay = (callback) -> _.delay(callback, 500)

DragSourced = ReactDnD.DragSource(
  CHAPTER_SECTION_DND_TYPE, dragSpec, dragCollect
) SectionContainer
DropTargeted = ReactDnD.DropTarget(
  CHAPTER_SECTION_DND_TYPE, dropSpec, dropCollect
) DragSourced

module.exports = DropTargeted
