{ useCoffeeCallback, useCoffeeEffect } = require 'lib/react_utils'
{ useState, useEffect, useRef } = React

TextAreaField = ({ onChange, placeholder, value, autosize }) ->
  [text, setText] = useState(value)

  textAreaRef = useRef()

  onBlur = useCoffeeCallback [onChange, text], -> onChange text

  resizeTextArea = _.debounce ->
    if textAreaRef.current and autosize
      # We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = "0px"
      scrollHeight = textAreaRef.current.scrollHeight

      textAreaRef.current.style.height = scrollHeight + "px"
    undefined
  , 250

  onTextAreaChange = useCoffeeCallback [setText, resizeTextArea], (evt) ->
    setText evt.target.value
    resizeTextArea()

  useEffect ->
    setText value
    resizeTextArea()
    undefined
  , [textAreaRef, value, setText]

  <textarea
    ref={textAreaRef}
    onBlur={onBlur}
    onChange={onTextAreaChange}
    placeholder={placeholder}
    value={text}
  />

TextAreaField.propTypes =
  onChange: PropTypes.func.isRequired
  placeholder: PropTypes.string
  value: PropTypes.string.isRequired

TextAreaField.defaultProps =
  autosize: false


module.exports = TextAreaField
