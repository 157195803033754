ConclusionsSectionTitleRow = require 'components/etd/conclusions_section_title_row'
ConclusionsRow = require 'components/etd/conclusions_row'

sectionPath = (sectionId) -> ['templateData', 'conclusions', 'sections', sectionId]

AdolopmentConclusionsSection = ({
  adolopments,
  assessmentSections,
  attachments,
  contentCellClass,
  contentColSpan,
  isConsensus,
  originalSectionCollapsed
  renderMode
  section,
  sectionId,
  templateId,
  withSectionTitle
}) ->
  return null if originalSectionCollapsed
  adolopment = adolopments.last()
  adolopedSection = adolopment.getIn sectionPath(sectionId)
  <tbody>
    {withSectionTitle and
      <ConclusionsSectionTitleRow title={section.get('description')} colSpan={2} />}
      <ConclusionsRow
        assessmentSections={assessmentSections}
        contentCellClass={contentCellClass}
        contentColSpan={contentColSpan}
        attachments={attachments}
        editable={false}
        key={adolopment.get('_id')}
        isConsensus={isConsensus}
        renderMode={renderMode}
        readOnly={true}
        section={adolopedSection}
        sectionId={sectionId}
        templateId={templateId}
      />
  </tbody>

module.exports = AdolopmentConclusionsSection
