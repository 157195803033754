var OverarchingQuestionCreatorToolbar, OverarchingQuestionCreatorView, OverarchingQuestionsCreator, ReactComponent, ReactToolbarView, View, alt,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

OverarchingQuestionsCreator = require('components/overarching_questions/creator/overarching_question_creator');

OverarchingQuestionCreatorToolbar = require('components/overarching_questions/creator/toolbar');

module.exports = OverarchingQuestionCreatorView = (function(_super) {
  __extends(OverarchingQuestionCreatorView, _super);

  function OverarchingQuestionCreatorView() {
    return OverarchingQuestionCreatorView.__super__.constructor.apply(this, arguments);
  }

  OverarchingQuestionCreatorView.prototype.container = '#page-container';

  OverarchingQuestionCreatorView.prototype.className = 'overarching-questions-creator';

  OverarchingQuestionCreatorView.prototype.autoRender = true;

  OverarchingQuestionCreatorView.prototype._stopInputsPropagation = false;

  OverarchingQuestionCreatorView.prototype.initialize = function() {
    OverarchingQuestionCreatorView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: OverarchingQuestionCreatorToolbar
    }));
    return this.enable(ReactComponent);
  };

  OverarchingQuestionCreatorView.prototype.afterRender = function() {
    var creating, groupId, questionId, submodule, _ref;
    OverarchingQuestionCreatorView.__super__.afterRender.apply(this, arguments);
    _ref = this.options, questionId = _ref.id, submodule = _ref.submodule, groupId = _ref.groupId;
    creating = submodule === 'create';
    return this.renderSimpleComponent(OverarchingQuestionsCreator, {
      questionId: questionId,
      creating: creating,
      groupId: groupId
    });
  };

  OverarchingQuestionCreatorView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('QuestionsStore', 'QuestionGroupsStore', 'OverarchingQuestionsStore');
    return OverarchingQuestionCreatorView.__super__.dispose.apply(this, arguments);
  };

  return OverarchingQuestionCreatorView;

})(View);
