ConnectStore = require 'components/enhancers/connect_store'
DBEPPublicationActions = require 'actions/dbep_publication_actions'
DBEPPublicationStore = require 'stores/dbep_publication_store'
DbHelper = require 'base/lib/db_helper'
EllipsizedText = require 'components/common/ellipsized_text'
Exceptions = require 'lib/exceptions'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
QuestionStatus = require './question_status'
Translation = require 'components/mixins/translation'
utils = require 'base/lib/utils'

ICONS =
  preview: 'eye'
  publish: 'bookmark'
  cancel: 'cross'
  republish: 'bookmark'

openModal = (isPreview) -> (questionId, adoloped) ->
  if adoloped
    DBEPPublicationActions.openAdolopmentSettingsModal isPreview, questionId
  else
    DBEPPublicationActions.openModalForOneQuestion isPreview, questionId

ACTIONS =
  preview: openModal true
  publish: openModal false
  cancel: (questionId) ->
    prefixKey = 'dbep:questions_list_screen.publication_cancellation'
    options =
      title: $.t "#{prefixKey}.confirmation.title"
      message: $.t "#{prefixKey}.confirmation.message"
    mediator.dialogs.confirm options, (confirmed) ->
      return unless confirmed
      projectId = mediator.project.id
      mediator.services.dbepPublicationService.cancelPublication projectId, questionId
      .then -> mediator.dialogs.success $.t "#{prefixKey}.success"
      .catch (err) ->
        if window.gdt.debugMode
          console.error err
        else
          utils.reportRavenError err unless err instanceof Exceptions.dbep.server_error
        mediator.dialogs.error new Error $.t "#{prefixKey}.error"
  republish: (questionId) ->
    options =
      title: $.t 'dbep:questions_list_screen.republish_confirmation.title'
      message: $.t 'dbep:questions_list_screen.republish_confirmation.message'
    mediator.dialogs.confirm options, (confirmed) ->
      return unless confirmed
      DBEPPublicationActions.openModalForOneQuestion false, questionId

storeConnector =
  DBEPPublicationStore: (Store) ->
    questionStatuses: Store.getQuestionStatuses()

DBEPQuestionsTableQuestion = createReactClass
  displayName: 'DBEPQuestionsTableQuestion'
  mixins: [Translation('dbep:questions_list_screen')]

  propTypes:
    questionStatuses: PropTypes.instanceOf(Immutable.Map).isRequired
    data: PropTypes.instanceOf(Immutable.Map).isRequired

  performAction: (action) -> =>
    questionId = @props.data.get '_id'
    adoloped = Boolean @props.data.get 'recommendationAdolopmentIds'
    ACTIONS[action] questionId, adoloped

  render: ->
    { data, questionStatuses } = @props
    questionId = data.get '_id'
    status = questionStatuses.getIn ['statuses', questionId]
    status ?= 'unpublished'
    link = if status is 'published'
      profileId = questionStatuses.getIn ['profileIds', questionId]
      if profileId?
        "#{DbHelper.getDBEPUrl()}/profile/#{profileId}"
      else
        null
    else
      null
    actions = switch status
      when 'published'
        ['preview', 'republish']
      when 'in_review'
        ['preview', 'cancel']
      when 'unpublished'
        ['preview', 'publish']

    <div className="dbep-table__row">
      <div className="dbep-table__cell question">
        <div className="cell-content">
          <EllipsizedText text={data.get 'question'} height={50} />
        </div>
      </div>
      <QuestionStatus status={status} link={link} />
      <div className="dbep-table__cell actions">
        <div className="cell-content">
          {_.map(actions, (action) =>
            <IconButton
              key={action}
              label={@i18n "/actions.#{action}"}
              iconName={ICONS[action]}
              onClick={@performAction action}
            />
          )}
        </div>
      </div>
    </div>

module.exports = ConnectStore DBEPQuestionsTableQuestion, [DBEPPublicationStore], storeConnector
