Tooltip = require 'components/common/tooltip'
ProjectsActions = require 'actions/projects_actions'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
Spinner = require 'components/common/spinner_small'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ProjectsStore = require 'stores/projects_store'
mediator = require 'mediator'

storeConnector =
  ProjectsStore: (ProjectsStore, props) ->
    isFetching: ProjectsStore.isFetching()
    projects: ProjectsStore.getProjectsByType 'active', props.organizationId
    hasEverFetched: ProjectsStore.hasEverFetched(props.organizationId)

HeaderProjectsList = createReactClass
  displayName: 'HeaderProjectsList'

  propTypes:
    isFetching: PropTypes.bool.isRequired
    organizationId: PropTypes.string
    projects: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.Map),
      PropTypes.instanceOf(Immutable.OrderedMap)
    ])

  mixins: [
    CustomRenderMixin
  ]

  getDefaultProps: ->
    organizationId: null

  renderProject: (project) ->
    projectId = project.get 'id'
    # project.get 'name' should be escaped in db so we need to unescape it, so it will be correctly
    # displayed, but tooltip needs to be escaped again so it won't trigger nested script
    # it could be unescaped only in <a> tag, but then tooltip would trigger nested script
    # on already existing 'evil' project
    projectName = _.string.unescapeHTML(project.get 'name')
    cls = classNames 'project', 'items-list-container__item',
      active: mediator.project?.id is projectId

    <div className={cls} key={projectId}>
      <Tooltip>
        <span title={_.string.escapeHTML(projectName)}>
          <a href={"/projects/#{projectId}"}>{projectName}</a>
        </span>
      </Tooltip>
    </div>

  componentDidMount: ->
    unless @props.hasEverFetched
      ProjectsActions.fetch organizationId: @props.organizationId

  render: ->
    { projects, isFetching } = @props
    activeProjectName = _.string.unescapeHTML mediator.project?.get 'name'

    <CollapsibleWithControls
      controlsPosition='left'
      withTogglableCaption
      captionClassName="current-project-name"
    >
      <Tooltip>
        <div className='title' title={_.string.escapeHTML(activeProjectName)}>
          {activeProjectName}
        </div>
      </Tooltip>
      {if isFetching
        <Spinner />
      else
        <div className='header-projects-list items-list-container--numbered'>
          {@props.projects.map(@renderProject).toList()}
        </div>
      }
    </CollapsibleWithControls>

module.exports = ConnectStore HeaderProjectsList, [ProjectsStore], storeConnector
