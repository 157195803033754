ConnectStore = require 'components/enhancers/connect_store'
{
  getCreatorFields
  QUESTION_DIAG_TAGS
  QUESTION_MGMT_TAGS
} = require 'lib/overarching_question_helper'
mediator = require 'mediator'
OverarchingQuestionsActions = require 'actions/overarching_questions_actions'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
{ QuestionCreator } = require 'components/common/question_creator'
Router = require 'router'
Translation = require 'components/mixins/translation'

storeConnector =
  OverarchingQuestionsStore: (Store) ->
    isFetching: Store.isFetching()
    overarchingQuestion: Store.getCurrentQuestion()

OverarchingQuestionCreator = createReactClass
  displayName: 'OverarchingQuestionCreator'
  mixins: [Translation()]
  propTypes:
    creating: PropTypes.bool.isRequired
    isFetching: PropTypes.bool.isRequired
    overarchingQuestion: PropTypes.instanceOf(Immutable.Map)
    questionId: PropTypes.string.isRequired
    groupId: PropTypes.string

  componentDidMount: ->
    { creating, questionId, groupId } = @props
    if creating
      OverarchingQuestionsActions.createEmptyQuestion(questionId)
    else
      OverarchingQuestionsActions.fetchOne questionId

  componentWillUnmount: ->
    OverarchingQuestionsActions.destroyCurrentQuestion()

  onChange: (attrName, value) ->
    OverarchingQuestionsActions.updateCurrentQuestion { attrName, value }

  onCancel: ->
    route = Router::getProjectRelativeUrl("/evidence-syntheses")
    mediator.publish '!router:route', route

  onSave: ->
    { creating, questionId, overarchingQuestion, groupId } = @props
    if creating
      OverarchingQuestionsActions.createQuestion(overarchingQuestion, groupId)
    else
      OverarchingQuestionsActions.saveCurrentQuestion(overarchingQuestion)

  getFields: ->
    { creating, overarchingQuestion } = @props

    canChangeType =
      creating and overarchingQuestion.get('recommendationIds', Immutable.List()).isEmpty()
    tags = if overarchingQuestion.get('type') is 'overarching:diagnostic'
      QUESTION_DIAG_TAGS
    else
      QUESTION_MGMT_TAGS

    fields = getCreatorFields overarchingQuestion, OverarchingQuestionsActions, canChangeType, tags
    return fields if mediator.services.switches.isServerSwitchOn('acpFeatures')
    _.filter fields, (field) -> field.fieldKey isnt 'type'

  render: ->
    { creating, isFetching, overarchingQuestion } = @props
    <QuestionCreator
      creating={creating}
      fields={@getFields()}
      isFetching={isFetching}
      onCancel={@onCancel}
      onChange={@onChange}
      onSave={@onSave}
      question={overarchingQuestion}
    />

module.exports = ConnectStore OverarchingQuestionCreator, OverarchingQuestionsStore, storeConnector
