AddMultipleTeamMembersModal = require 'components/team/add_multiple_team_members'
AddOrganizationMemberModal = require 'components/team/add_organization_member'
AddSingleTeamMemberModal = require 'components/team/add_single_team_member'
Body = require 'components/common/editable_table/body'
Button = require 'components/common/button'
CheckboxInput = require 'components/common/checkbox_input'
Confirmation = require 'components/common/confirmation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EditableTable = require 'components/common/editable_table/table'
Header = require 'components/common/editable_table/header'
ImportTeamMembersModal = require 'components/team/import_team_members_modal'
{ isValidLimit } = require 'lib/limits_helper'
{ MemberCollapsed, MemberExpanded } = require './member'
MembersActions = require 'actions/members_actions'
LimitExceededWarningModal = require 'components/common/limit_exceeded_warning_modal'
MembersToolbar = require 'components/team/members_toolbar'
MEMBER_SCHEMA = require 'components/team/member_schema'
Modal = require 'components/common/modal'
{
  filterAdminsAndResearchers
  getMembersWithPendingInvitationsCount
  SORTABLE_FIELDS
  sortDirectionValue
  reduceMembersToValueList
} = require 'lib/members_helper'
Translation = require 'components/mixins/translation'
utils = require 'base/lib/utils'
withLimits = require 'components/enhancers/with_limits'
mediator = require 'mediator'


MembersList = createReactClass

  displayName: 'MembersList'

  mixins: [
    Translation('team:member')
  ]

  propTypes:
    filterByAccessRight: PropTypes.string.isRequired
    limits: PropTypes.instanceOf(Immutable.Map).isRequired,
    members: PropTypes.instanceOf(Immutable.Map).isRequired
    openedAddMemberModal: PropTypes.string,
    projectFromOrganization: PropTypes.bool.isRequired
    sorting: PropTypes.instanceOf(Immutable.Map).isRequired
    validationErrors: PropTypes.instanceOf(Immutable.Map).isRequired

  getDefaultProps: ->
    openedAddMemberModal: '',

  getInitialState: ->
    confirmDeleteModal: false
    membersLimitExceededModalOpen: false
    itemToDelete: null
    selectedMembers: Immutable.fromJS []
    # do not render checkboxes for now because they have nothing to do.
    showCheckboxes: false

  toggleAll: (e) ->
    selectedMembers = if e.target.checked
      @props.members.map((member) -> member.get('_id')).toList()
    else
      @state.selectedMembers.clear()
    @setState selectedMembers: selectedMembers

  onSelectMember: (e) ->
    selectedMembers = @state.selectedMembers
    selectedMembers = if e.target.checked
      selectedMembers.push e.target.value
    else
      index = selectedMembers.findIndex (value) ->
        value is e.target.value
      selectedMembers.delete index unless index is -1

    @setState selectedMembers: selectedMembers
    _.defer =>
      @_toggleSelectAllMembers()

  _toggleSelectAllMembers: ->
    ReactDOM.findDOMNode(@refs.selectAll)?.checked =
      @state.selectedMembers.size is @props.members.size

  edit: (itemKey) ->
    {currentlyEditingKey} = @props
    currentlyEditedMember = if currentlyEditingKey and @props.itemKey isnt currentlyEditingKey
      @_getCurrentlyEditedMember()
    MembersActions.edit itemKey, currentlyEditedMember

  save: (item) ->
    MembersActions.save mediator.project.id, item

  delete: ->
    {itemToDelete} = @state
    return unless itemToDelete
    MembersActions.delete itemToDelete
    @setState itemToDelete: null
    @closeDeleteConfirmation()

  _getCurrentlyEditedMember: ->
    @props.members.find (member) => member.get('_id') is @props.currentlyEditingKey

  _hasValidationErrors: (memberId = null) ->
    return not @props.validationErrors.isEmpty() unless memberId
    not @props.validationErrors.get(memberId, Immutable.Map()).isEmpty()

  showAddTeamMemberModal: (name) -> =>
    { limits, members } = @props
    if isValidLimit(members.size, limits.get('teamMembers'))
      currentlyEditedMember = @_getCurrentlyEditedMember()
      if currentlyEditedMember
        MembersActions.save(mediator.project.id, currentlyEditedMember)
        .then =>
          return if @_hasValidationErrors(currentlyEditedMember.get('_id'))
          MembersActions.openModal name
      else
        MembersActions.openModal name
    else
      @setState membersLimitExceededModalOpen: true

  onApplyOrganization: (members, panelMembers) ->
    members = members.concat panelMembers
    MembersActions.save mediator.project.id, members

  onApplyMultiple: (members) ->
    MembersActions.save mediator.project.id, members

  closeModal: ->
    MembersActions.closeModal()

  onSort: (sortBy) ->
    MembersActions.sort sortBy

  onFilterByAccessRight: (key) ->
    MembersActions.filterByAccessRight key

  renderHeaderCells: ({name, component, tooltip}, idx) ->
    props = _.extend
      el: 'th'
      key: idx,
      name: _.string.underscored name
      title: @i18n _.string.underscored tooltip
      className: classNames 'header', 'cell', name

    sortBy = @props.sorting
    if name in SORTABLE_FIELDS
      _.extend props,
        onSort: @onSort.bind(this, name),
        sortDirection: sortDirectionValue(name, sortBy)
    label = if name is 'panel-member' then 'panel-participation' else name
    React.createElement component, props, @i18n _.string.underscored label

  filteredOrganizationMembers: ->
    { members, organizationMembers } = @props
    projectMembersEmails = reduceMembersToValueList(members, 'email')
    organizationMembers.filter (member) -> not projectMembersEmails.contains member.get('email')

  renderModals: ->
    { openedAddMemberModal } = @props
    <div>
      {openedAddMemberModal is 'single' and
        <AddSingleTeamMemberModal
          onApply={@save}
          onCancel={@closeModal}
        />
      }

      {openedAddMemberModal is 'multiple' and
        <AddMultipleTeamMembersModal
          onApply={@onApplyMultiple}
          onCancel={@closeModal}
          errors={@props.validationErrors}
        />
      }

      {openedAddMemberModal is 'organization' and
        <AddOrganizationMemberModal
          currentMembers={@props.members}
          errors={@props.validationErrors}
          onApply={@onApplyOrganization}
          onCancel={@closeModal}
          organizationId={@props.organizationId}
        />
      }

      {@state.confirmDeleteModal and
        <Modal className='confirmation-modal' isOpen>
          <Confirmation
            question={@i18n 'delete_confirmation'}
            onCancel={@closeDeleteConfirmation}
            onConfirm={@delete}
          />
        </Modal>
      }
      {@state.membersLimitExceededModalOpen and
        <LimitExceededWarningModal
          title={@i18n '../limits.members_limit_reached'}
          onCancel={@closeMembersLimitExceededModal}
        />
      }
    </div>

  openDeleteConfirmation: (itemKey) ->
    @setState confirmDeleteModal: true, itemToDelete: itemKey

  closeDeleteConfirmation: ->
    @setState confirmDeleteModal: false

  closeMembersLimitExceededModal: ->
    @setState membersLimitExceededModalOpen: false

  getDisabledAccessRights: ->
    { limits, members } = @props
    membersWithPendingInvitationsCount = getMembersWithPendingInvitationsCount members
    currentAdminsCount = filterAdminsAndResearchers(members).size
    adminsResearchersCount = currentAdminsCount + membersWithPendingInvitationsCount
    if isValidLimit(adminsResearchersCount, limits.get('teamAdmins'), 'lt')
      []
    else
      ['admin', 'researcher', 'acp_review_team_member', 'acp_panel_member', 'cochrane_author']

  renderMemberRow: (member, key) ->
    props =
      adminsLimit: @props.limits.get('teamAdmins')
      checked: @state.selectedMembers.find((memberId) -> memberId is key)
      delete: @openDeleteConfirmation
      disabledAccessRights: @getDisabledAccessRights()
      edit: @edit
      errors: @props.validationErrors.get(key) or Immutable.Map()
      itemKey: key
      member: member
      showCheckboxes: @state.showCheckboxes
      onSelectMember: @onSelectMember
      save: @save

    if @props.currentlyEditingKey and @props.currentlyEditingKey is key
      <MemberExpanded key={key} {...props} />
    else
      <MemberCollapsed key={key} {...props} />

  renderHeaderRow: ->
    <tr>
      {if @state.showCheckboxes
        <th className="checkbox">
          <CheckboxInput ref="selectAll" type="checkbox" onChange={@toggleAll} />
        </th>
      }
      {MEMBER_SCHEMA.header.map(@renderHeaderCells)}
      <th className="controls"></th>
    </tr>

  render: ->
    <div className="team-members">
      <MembersToolbar
        filterByAccessRight={@props.filterByAccessRight}
        isOnline={@props.isOnline}
        onFilterByAccessRight={@onFilterByAccessRight}
        projectFromOrganization={@props.projectFromOrganization}
        showAddTeamMemberModal={@showAddTeamMemberModal}
        showAddMultipleTeamMemberModal={@showAddTeamMemberModal}
      />
      {@renderModals()}
      <ImportTeamMembersModal />
      <div className="team-members-list">
        <EditableTable className="team-members-table">
          <Header>
            {@renderHeaderRow()}
          </Header>
          <Body>
            {@props.members.map(@renderMemberRow).toList()}
          </Body>
        </EditableTable>
      </div>
    </div>

module.exports =
  MembersList: MembersList
  MembersListWithLimits: withLimits(MembersList)
