var ETD_VOTING_PHASES, INITIAL_QUESTION_STATUSES, ISoFDxConverter, ISoFTxConverter, OUTCOMES_GENERATION_STEPS, QS, QUESTIONS_DB_VIEW, QUESTIONS_VIEW_TYPE_TO_I18N_KEY, QUESTION_DOC_TYPES, QUESTION_GENERATION_DOC_ID, QUESTION_GENERATION_STEPS, QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE, QUESTION_OVER_REVIEW_STUDIES_STATUSES, QUESTION_TITLE_ATTRIBUTES_BY_QUESTION_TYPE, QUESTION_TYPES_BY_QUESTION_DOC_TYPE, QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID, QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE, QuestionHelper, Router, baseHelper, convertListToOrderedMapByElementId, docTypes, filterQuestionsWithoutGroup, getApprovalStatusForRating, getQuestionSubmoduleUrl, groupFormsByQuestion, mapQuestionsToGroup, mediator, pluck, sortByOrderNumber, ungroupedQuestionsGroup, utils;

baseHelper = require('base/lib/questions_helper');

docTypes = require('lib/db_docs/doc_types');

ISoFDxConverter = require('models/evidence_syntheses/isof-dx-converter');

ISoFTxConverter = require('models/evidence_syntheses/isof-tx-converter');

mediator = require('mediator');

Router = require('router');

QS = require('models/outcome/quality_scores');

utils = require('base/lib/utils');

pluck = require('lib/immutable_utils').pluck;

QUESTION_GENERATION_STEPS = ['questions.initial', 'questions.brainstorming', 'questions.moderation', 'questions.rating', 'questions.proposal', 'questions.approval', 'questions.finished'];

INITIAL_QUESTION_STATUSES = {
  completed: false,
  locked: false,
  evidenceTableStatus: {},
  recommendationStatus: {},
  presentationStatus: {}
};

OUTCOMES_GENERATION_STEPS = ['initial', 'brainstorming', 'moderation', 'rating', 'proposal', 'approval', 'finished'];

QUESTION_OVER_REVIEW_STUDIES_STATUSES = {
  INCLUDED: 'included',
  EXCLUDED: 'excluded',
  POSSIBLE: 'possible',
  NONE: 'none'
};

ETD_VOTING_PHASES = ['phase1a', 'phase1b', 'phase2a', 'phase2b', 'finished'];

QUESTION_GENERATION_DOC_ID = 'questions_generation';

QUESTION_DOC_TYPES = ['question', 'prognosticQuestion', docTypes.MDG_QUESTION, docTypes.MDA_TABLE, docTypes.MDA_TOPIC];

QUESTION_TYPES_BY_QUESTION_DOC_TYPE = {
  'mdaTable': docTypes.MDA_TABLE,
  'mdaTopic': docTypes.MDA_TOPIC,
  'question': 'regular',
  'mdgQuestion': docTypes.MDG_QUESTION,
  'prognosticQuestion': 'prognostic'
};

QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE = {
  mdaTable: 'mda_tables_groups',
  mdaTopic: 'mda_topics_groups',
  mdgQuestion: "comparisons_question_groups",
  regular: 'comparisons_question_groups',
  prognosis: 'prognostic_question_groups'
};

QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID = {
  mda_tables_groups: docTypes.MDA_TABLE,
  mda_topics_groups: docTypes.MDA_TOPIC,
  comparisons_question_groups: 'regular',
  prognostic_question_groups: 'prognostic'
};

QUESTION_TITLE_ATTRIBUTES_BY_QUESTION_TYPE = {
  regular: ['intervention', 'comparison', 'questionFormat', 'healthProblemOrPopulation', 'targetCondition', 'indexTest', 'comparatorTest', 'comparatorTestPresent'],
  prognostic: ['healthCondition', 'population', 'overTime', 'populationOther']
};

QUESTIONS_DB_VIEW = {
  map: function(doc) {
    if (doc.docType === 'question') {
      return emit(-doc.added, doc);
    }
  }
};

QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE = {
  OneRow: 'oneRow',
  SOF: 'sof',
  ACCP: 'ACCP_grade',
  SOF_ACCP: 'SOF_ACCP',
  SOF_v3: 'SOF_v3',
  SOF_v4: 'SOF_v4',
  LayerOne: 'layerOne',
  LayerTwo: 'layerTwo',
  LayerOneSof: 'layerOneSof',
  LayerTwoSof: 'layerTwoSof',
  Prognosis: 'prognosis',
  'recommendations-initial': 'recommendations-v1',
  'recommendations-static': 'recommendations-v2'
};

QUESTIONS_VIEW_TYPE_TO_I18N_KEY = {
  OneRow: 'grade_one_row',
  SOF: 'sof_table',
  ACCP: 'grade_v2',
  SOF_ACCP: 'sof_table_v2',
  SOF_v3: 'sof_table_v3',
  SOF_v4: 'sof_table_v4',
  LayerOne: 'diag_l1',
  LayerTwo: 'diag_l2',
  LayerOneSof: 'diag_sof-l1',
  LayerTwoSof: 'diag_sof-l2',
  Prognosis: 'prognosis',
  'recommendations-initial': 'recommendations',
  'recommendations-static': 'recommendations',
  Tx_iSoF: 'isof',
  Dx_iSoF: 'isof'
};

groupFormsByQuestion = function(forms) {
  return Immutable.fromJS(forms).flatMap(function(form) {
    return form.get('questions').map(function(q) {
      return q.set('memberId', form.get('memberId'));
    });
  }).groupBy(function(q) {
    return q.get('id');
  });
};

getApprovalStatusForRating = function(rating) {
  if (rating > 6) {
    return 'accepted';
  } else if (rating > 3) {
    return 'mentioned';
  } else {
    return 'rejected';
  }
};

filterQuestionsWithoutGroup = function(questionGroups, questionsList) {
  var questions;
  questions = questionsList.filter(function(question) {
    var groupExists, groupId;
    groupId = questionGroups.getIn(['mapping', question.get("_id")]);
    if (!groupId) {
      return true;
    }
    groupExists = questionGroups.get('groups', Immutable.List()).find(function(group) {
      return group.get('_id') === groupId;
    });
    return groupExists == null;
  });
  return sortByOrderNumber(questions);
};

mapQuestionsToGroup = function(questionGroups, questionsList, groupId) {
  var filteredMapping;
  filteredMapping = questionGroups.get('mapping', new Immutable.Map()).filter(function(gId) {
    return gId === groupId;
  });
  return new Immutable.OrderedMap().withMutations(function(questions) {
    return filteredMapping.map(function(groupId, questionId) {
      var q;
      q = questionsList.find(function(question) {
        return question.get("_id") === questionId;
      });
      if (q) {
        return questions.set(questionId, q);
      }
    });
  });
};

sortByOrderNumber = function(questions) {
  return questions.sortBy(function(q) {
    return q.get('orderNumber', questions.size + 1);
  });
};

getQuestionSubmoduleUrl = function(moduleName, submoduleName, questionId) {
  return Router.prototype.routeForSubmodule(_.str.dasherize(moduleName), _.str.dasherize(submoduleName), {
    questionId: questionId
  });
};

convertListToOrderedMapByElementId = function(list) {
  return list.toOrderedMap().mapEntries(function(_arg) {
    var el, _k;
    _k = _arg[0], el = _arg[1];
    return [el.get('_id'), el];
  });
};

ungroupedQuestionsGroup = function(questions) {
  return Immutable.Map({
    _id: 'questionsWithoutGroup',
    questions: convertListToOrderedMapByElementId(sortByOrderNumber(questions))
  });
};

QuestionHelper = Object.create(baseHelper);

_.extend(QuestionHelper, {
  INITIAL_QUESTION_STATUSES: INITIAL_QUESTION_STATUSES,
  QUESTION_OVER_REVIEW_STUDIES_STATUSES: QUESTION_OVER_REVIEW_STUDIES_STATUSES,
  QUESTION_GENERATION_STEPS: QUESTION_GENERATION_STEPS,
  QUESTION_GENERATION_DOC_ID: QUESTION_GENERATION_DOC_ID,
  QUESTION_DOC_TYPES: QUESTION_DOC_TYPES,
  QUESTION_TYPES_BY_QUESTION_DOC_TYPE: QUESTION_TYPES_BY_QUESTION_DOC_TYPE,
  QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID: QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID,
  QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE: QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE,
  QUESTION_TITLE_ATTRIBUTES_BY_QUESTION_TYPE: QUESTION_TITLE_ATTRIBUTES_BY_QUESTION_TYPE,
  QUESTIONS_DB_VIEW: QUESTIONS_DB_VIEW,
  ETD_VOTING_PHASES: ETD_VOTING_PHASES,
  OUTCOMES_GENERATION_STEPS: OUTCOMES_GENERATION_STEPS,
  QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE: QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE,
  QUESTIONS_VIEW_TYPE_TO_I18N_KEY: QUESTIONS_VIEW_TYPE_TO_I18N_KEY,
  sortByOrderNumber: sortByOrderNumber,
  convertListToOrderedMapByElementId: convertListToOrderedMapByElementId,
  ungroupedQuestionsGroup: ungroupedQuestionsGroup,
  updateQuestionsWithApprovalData: function(questions, memberApprovals) {
    var memberApprovalsByQuestion;
    if (_.isArray(questions)) {
      questions = Immutable.fromJS(questions);
    }
    memberApprovalsByQuestion = groupFormsByQuestion(memberApprovals);
    return questions.map(function(q) {
      var approvalComments, approvals, qId, _ref;
      qId = q.get('id');
      approvals = Immutable.Map();
      approvalComments = Immutable.Map();
      if ((_ref = memberApprovalsByQuestion.get(qId)) != null) {
        _ref.forEach(function(approvalData) {
          var approvalComment, memberId;
          memberId = approvalData.get('memberId');
          approvalComment = approvalData.get('approvalComment');
          approvals = approvals.set(memberId, approvalData.get('approvalAgree'));
          if (!_.isEmpty(approvalComment)) {
            return approvalComments = approvalComments.set(memberId, approvalComment);
          }
        });
      }
      return q.withMutations(function(q) {
        var approvalCommentsPath;
        approvalCommentsPath = ['memberComments', QUESTION_GENERATION_STEPS[5]];
        q.set('approvals', approvals);
        if (!approvalComments.isEmpty()) {
          return q.setIn(approvalCommentsPath, approvalComments);
        }
      });
    });
  },
  updateQuestionsWithRatingData: function(questions, memberRatings) {
    var memberRatingsByQuestion;
    if (_.isArray(questions)) {
      questions = Immutable.fromJS(questions);
    }
    memberRatingsByQuestion = groupFormsByQuestion(memberRatings);
    return questions.map(function(q) {
      var qId, ratingComments, ratings, _ref;
      qId = q.get('id');
      ratings = Immutable.Map();
      ratingComments = Immutable.Map();
      if ((_ref = memberRatingsByQuestion.get(qId)) != null) {
        _ref.forEach(function(ratingData) {
          var memberId, ratingComment;
          memberId = ratingData.get('memberId');
          ratingComment = ratingData.get('ratingMemberComment');
          ratings = ratings.set(memberId, Number(ratingData.get('rating')));
          if (!_.isEmpty(ratingComment)) {
            return ratingComments = ratingComments.set(memberId, ratingComment);
          }
        });
      }
      return q.withMutations(function(q) {
        var ratingCommentsPath;
        ratingCommentsPath = ['memberComments', QUESTION_GENERATION_STEPS[3]];
        q.set('ratings', ratings);
        if (!ratingComments.isEmpty()) {
          q.setIn(ratingCommentsPath, ratingComments);
        }
        q.set('medianRating', QuestionHelper.calcMedianRating(q.get('ratings', Immutable.Map())));
        return q.set('approvalStatus', getApprovalStatusForRating(q.get('medianRating')));
      });
    });
  },
  calcMedianRating: function(ratings) {
    var values;
    if (_.isArray(ratings)) {
      ratings = Immutable.fromJS(ratings);
    }
    if (ratings.isEmpty()) {
      return 0;
    }
    values = ratings.valueSeq().filter(function(v) {
      return v > -1;
    });
    if (values.isEmpty()) {
      return -1;
    } else {
      return utils.arrayMedian(values.toJS());
    }
  },
  questionsWithoutGroup: function(questionGroups, questionsList) {
    return filterQuestionsWithoutGroup(questionGroups, questionsList);
  },
  grouppedQuestions: function(questionGroups, questionList, groupId) {
    var questions;
    questions = mapQuestionsToGroup(questionGroups, questionList, groupId);
    return sortByOrderNumber(questions);
  },
  questionCustomId: function(question) {
    var _ref;
    return (_ref = question.get('customID')) != null ? _ref : question.get("_id").slice(-4);
  },
  getQuestionSubmoduleUrl: getQuestionSubmoduleUrl,
  sortQuestions: function(questionGroups, questions) {
    var grouppedQuestions;
    if (questionGroups.isEmpty()) {
      return {
        groupedQuestions: Immutable.OrderedMap(),
        ungroupedQuestions: questions
      };
    } else {
      grouppedQuestions = (questionGroups.get('groups') || Immutable.List()).reduce(function(acc, group) {
        var groupId, groupWithQuestions;
        groupId = group.get('_id');
        groupWithQuestions = mapQuestionsToGroup(questionGroups, questions, groupId);
        if (!groupWithQuestions.isEmpty()) {
          return acc.set(groupId, group.set('questions', sortByOrderNumber(groupWithQuestions).toList()));
        } else {
          return acc;
        }
      }, Immutable.OrderedMap());
      return {
        groupedQuestions: grouppedQuestions,
        ungroupedQuestions: filterQuestionsWithoutGroup(questionGroups, questions)
      };
    }
  },
  getQuestionsIdsSorted: function(questionGroups, questions) {
    return this.groupQuestionsByGroup(questions, questionGroups).reduce(function(acc, group) {
      return acc.concat(pluck(group.get('questions', Immutable.List()), '_id'));
    }, Immutable.List());
  },
  getRelatedQuestions: function(questionGroups, questions, questionIds) {
    var groupedQuestions, questionsSorted, ungroupedQuestions, _ref;
    _ref = this.sortQuestions(questionGroups, questions), groupedQuestions = _ref.groupedQuestions, ungroupedQuestions = _ref.ungroupedQuestions;
    questionsSorted = groupedQuestions.reduce(function(acc, group) {
      return acc.concat(group.get('questions'));
    }, Immutable.List()).concat(ungroupedQuestions);
    return questionsSorted.reduce(function(acc, question) {
      var questionId;
      questionId = question.get('_id');
      if (questionIds.includes(questionId)) {
        acc = acc.set(questionId, Immutable.fromJS({
          'question': question.get('question'),
          'recommendationIds': question.get('recommendationIds', {})
        }));
      }
      return acc;
    }, Immutable.Map());
  },
  getQuestionsWithoutEtd: function(questions) {
    return questions.filter(function(q) {
      return q.get('recommendationIds', Immutable.List()).isEmpty();
    });
  },
  getQuestionIsofData: function(questionModel, projectName) {
    if (questionModel && questionModel.isDiagnostic) {
      if (questionModel.isDiagnostic()) {
        if (questionModel.attributes.comparatorTestPresent) {
          return {
            indexIsofData: ISoFDxConverter.convertDiagnostic(questionModel, false, projectName),
            comparatorIsofData: ISoFDxConverter.convertDiagnostic(questionModel, true, projectName)
          };
        } else {
          return ISoFDxConverter.convertDiagnostic(questionModel, false, projectName);
        }
      } else {
        return ISoFTxConverter.convertTreatment(questionModel);
      }
    }
  },
  prepareDiagnosticOutcomes: function() {
    return ['TP', 'FN', 'TN', 'FP', 'Inconclusive', 'Complications'].map(function(name, idx) {
      return {
        name: name,
        largeEffect: QS.No,
        plausibleConfounding: QS.No,
        doseResponseGradient: QS.No,
        orderNumber: idx + 1
      };
    });
  },
  groupQuestionsByGroup: function(questions, questionGroups) {
    var groups, mapping, questionsByGroup, questionsOrdering, questionsWithOrderNumber, questionsWithoutGroup;
    questions = questions.filter(function(q) {
      return q.get('_id').indexOf(':adoloped') === -1;
    });
    if (questionGroups.isEmpty()) {
      return Immutable.OrderedMap({
        'questionsWithoutGroup': ungroupedQuestionsGroup(questions)
      });
    }
    questionsOrdering = questionGroups.get('questionsOrdering', Immutable.Map());
    questionsWithOrderNumber = questions.map(function(q) {
      var qId;
      qId = q.get('_id');
      return q.set('orderNumber', questionsOrdering.get(qId, q.get('orderNumber', 0)));
    });
    mapping = questionGroups.get('mapping', Immutable.Map());
    groups = questionGroups.get('groups', Immutable.List());
    if (mapping.isEmpty()) {
      groups = convertListToOrderedMapByElementId(groups.map(function(group) {
        return group.set('questions', Immutable.OrderedMap());
      }));
      return groups.set('questionsWithoutGroup', ungroupedQuestionsGroup(questionsWithOrderNumber));
    } else {
      questionsByGroup = questionsWithOrderNumber.groupBy(function(q) {
        var groupExists, grpId;
        grpId = mapping.get(q.get('_id'), 'questionsWithoutGroup');
        groupExists = grpId === 'questionsWithoutGroup' || groups.some(function(g) {
          return g.get('_id') === grpId;
        });
        if (groupExists) {
          return grpId;
        } else {
          return 'questionsWithoutGroup';
        }
      });
      groups = convertListToOrderedMapByElementId(groups.map(function(group) {
        var groupId, sortedByOrderNumber;
        groupId = group.get('_id');
        sortedByOrderNumber = sortByOrderNumber(questionsByGroup.get(groupId, Immutable.List()));
        return group.set('questions', convertListToOrderedMapByElementId(sortedByOrderNumber));
      }));
      questionsWithoutGroup = questionsByGroup.get('questionsWithoutGroup', Immutable.List());
      return groups.set('questionsWithoutGroup', ungroupedQuestionsGroup(questionsWithoutGroup));
    }
  },
  isOverarching: function(questionType) {
    if (questionType == null) {
      questionType = '';
    }
    return questionType.indexOf('overarching') === 0;
  },
  filterOutAdoloped: function(q) {
    return q.get('_id').indexOf(":adoloped") === -1;
  },
  filterGoodPracticeStatement: function(q) {
    return q.get('type') === 'good-practice-statement';
  },
  filterKeyMessage: function(q) {
    return q.get('type') === 'keyMessage';
  },
  prepareCode: function(code) {
    return {
      id: code.get('@id') || code.get('id'),
      codeType: code.get('@type') || code.get('codeType'),
      name: code.get('name'),
      value: code.get('value')
    };
  },
  prepareCodeWithSanitizedValue: function(code) {
    var preparedCode;
    preparedCode = QuestionHelper.prepareCode(code);
    preparedCode.value = utils.sanitizeHTML(preparedCode.value);
    return preparedCode;
  }
});

module.exports = QuestionHelper;
