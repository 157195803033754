mediator = require 'mediator'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ string } = PropTypes

Tooltip = createReactClass
  displayName: 'TooltipWrapperComponent'

  propTypes:
    tooltipType: string
    targetJoint: string
    tipJoint: string

  getDefaultProps: ->
    tooltipType: 'info'
    targetJoint: 'bottom'
    tipJoint: 'top center'

  mixins: [CustomRenderMixin]

  _elWithTooltip: ->
    $(@tooltipEl)

  _initTooltips: ->
    fn = mediator.tooltips[@props.tooltipType]
    options = _.extend {cache: false},
      _.pick(@props, 'tooltipType', 'targetJoint', 'tipJoint')
    if @props.tooltipContainer
      options = _.extend options, tooltipContainer: @props.tooltipContainer

    fn.call(mediator.tooltips, @_elWithTooltip(), options) if _.isFunction fn

  _unmountTooltips: ->
    mediator.tooltips.deactivateTooltip @_elWithTooltip()

  componentDidMount: ->
    @_initTooltips()

  componentWillUnmount: ->
    @_unmountTooltips()

  componentDidUpdate: ->
    @_unmountTooltips()
    @_initTooltips()

  tooltipRef: (el) -> @tooltipEl = el
  
  render: ->
    React.cloneElement React.Children.only(@props.children), ref: @tooltipRef

module.exports = Tooltip
