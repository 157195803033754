var EtdStakeholdersChangeToInterestsHolder, Migration, W, get, mediator, migrationName, paths, set, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

_ref = require('lodash'), get = _ref.get, set = _ref.set;

mediator = require('mediator');

migrationName = 'etd_stakeholders_change_to_interests_holder';

paths = ["templateData.assessment.criteria.acceptability.description", "templateData.assessment.criteria.acceptability.ratingDescription", "templateData.assessment.criteria.acceptability.info", "templateData.assessment.criteria.acceptability.details.questions[0].question", "templateData.assessment.criteria.acceptability.details.questions[1].question", "templateData.assessment.criteria.acceptability.details.questions[2].question", "templateData.assessment.criteria.acceptability.details.questions[4].question", "templateData.assessment.criteria.socioCulturalAcceptability.description", "templateData.assessment.criteria.socioCulturalAcceptability.info", "templateData.assessment.criteria.socioCulturalAcceptability.details.questions[4].question", "templateData.assessment.criteria.feasibilityAndHealthSystemConsiderations.info", "templateData.assessment.criteria.acceptabilityEOHR.description", "templateData.assessment.criteria.acceptabilityEOHR.ratingDescription", "templateData.assessment.criteria.acceptabilityEOHR.info", "templateData.assessment.criteria.acceptabilityEOHR.details.questions[1].question"];

module.exports = EtdStakeholdersChangeToInterestsHolder = (function(_super) {
  __extends(EtdStakeholdersChangeToInterestsHolder, _super);

  function EtdStakeholdersChangeToInterestsHolder() {
    return EtdStakeholdersChangeToInterestsHolder.__super__.constructor.apply(this, arguments);
  }

  EtdStakeholdersChangeToInterestsHolder.prototype.construct = function() {
    return EtdStakeholdersChangeToInterestsHolder.__super__.construct.call(this, false);
  };

  EtdStakeholdersChangeToInterestsHolder.prototype.up = function(project, colls) {
    var adapter, docIds, projectId, replaceStakeHolders;
    EtdStakeholdersChangeToInterestsHolder.__super__.up.apply(this, arguments);
    replaceStakeHolders = function(doc) {
      paths.forEach(function(path) {
        var val;
        val = get(doc, path);
        if (val != null) {
          return set(doc, path, val.replace(/stakeholder/g, 'interest-holder'));
        }
      });
      return doc;
    };
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = _.compact(_.uniq(colls.questions.reduce(function(docIds, q) {
      var adolopmentRecIds, recIds, _ref1, _ref2;
      recIds = (_ref1 = q.get('recommendationIds')) != null ? _ref1 : [];
      adolopmentRecIds = (_ref2 = q.get('recommendationAdolopmentIds')) != null ? _ref2 : [];
      return docIds.concat(recIds).concat(adolopmentRecIds);
    }, [])));
    return adapter.fetch(project.id, docIds).then(function(res) {
      var newDocs;
      newDocs = _.chain(res.rows).pluck('doc').compact().map(function(doc) {
        var lang, newDoc;
        lang = get(doc, 'templateDef.lang');
        if ((lang != null) && lang !== 'en') {
          return;
        }
        newDoc = replaceStakeHolders(doc);
        return _.extend(newDoc, {
          rev_author: "project_migration/" + migrationName
        });
      }).value();
      return adapter.bulkDocs(project.id, newDocs);
    });
  };

  return EtdStakeholdersChangeToInterestsHolder;

})(Migration);
