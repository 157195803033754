var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "&nbsp;\n<table class=\"standard-table diagnostic-prevalences\" cellpadding=\"0\">\n  <tr>\n    <td class=\"with-info\">\n      <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.prevalence_info", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.prevalences", {"name":"i18n","hash":{},"data":data})))
    + "\n    </td>\n    <td class=\"value-cell\" data-property=\"prevalences\">"
    + escapeExpression(((helper = (helper = helpers.prevalence1 || (depth0 != null ? depth0.prevalence1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1","hash":{},"data":data}) : helper)))
    + "</td>\n    <td class=\"value-cell\" data-property=\"prevalences\">"
    + escapeExpression(((helper = (helper = helpers.prevalence2 || (depth0 != null ? depth0.prevalence2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2","hash":{},"data":data}) : helper)))
    + "</td>\n    <td class=\"value-cell\" data-property=\"prevalences\">"
    + escapeExpression(((helper = (helper = helpers.prevalence3 || (depth0 != null ? depth0.prevalence3 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3","hash":{},"data":data}) : helper)))
    + "</td>\n  </tr>\n</table>\n&nbsp;\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}