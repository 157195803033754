module.exports = {
  acpVisualGuidelines: 'acp_visual_guidelines_',
  algorithms: 'algorithm_',
  coiCreateFormRequest: function(projectId, memberId) {
    return "request_coi_create_form_" + projectId + "_" + memberId;
  },
  createOrganizationProjectRequest: 'create_organization_project_request',
  createProjectRequest: 'create_project_request',
  createProjectSnapshotRequest: 'create_project_snapshot_request',
  decisionAid: 'decision_aid_',
  etdHighlights: function(projectId) {
    return "" + projectId + ":etd-highlights:";
  },
  etdPreferences: function(projectId) {
    return "" + projectId + ":etd-preferences:";
  },
  generalInformation: 'general_information',
  isofPresentation: 'isof_',
  mcPresentation: 'mc_presentation_',
  mdaChapterTopicDoc: 'mda_chapter_topic_',
  mdaRootTopicDoc: 'mda_root_topic_',
  mdaSectionTopicDoc: 'mda_section_topic_',
  mdaTable: 'mda_table_',
  mdaTopic: 'mda_topic_',
  mdgQuestion: 'mdg_question_',
  mdgSearchStrategy: 'mdg_search_strategy_',
  mdgTopic: 'mdg_topic_',
  multiComparison: 'mc_',
  multiComparisonQuestion: 'mc_question_',
  nmaOutcome: 'nma_outcome_',
  nmaQuestion: 'nma_question_',
  organizationPermissions: 'organization_permissions',
  organizationsData: 'organization_data',
  overReview: 'over_review_',
  panelVoiceAdminComments: function(projectId) {
    return "" + projectId + ":panel-voice-admin-summary:";
  },
  panelVoiceResults: function(projectId) {
    return "" + projectId + ":results:";
  },
  premiumPresentation: 'premium_presentation',
  qualityIndicators: 'quality_indicators_',
  questionStatus: 'question_status_',
  reference: 'ref_',
  study: 'study_',
  task: 'task_',
  tasksToRun: 'tasks_to_run',
  visualGuidelinesChapter: 'visual_guidelines_chapter_',
  visualGuidelinesRoot: 'root_visual_guidelines_',
  visualGuidelinesSection: 'visual_guidelines_section_'
};
