{ CommonComparatorTableWithI18n, TableSettingsContext } = NmaComponents

NmaTablePrintout = ({ props, settings }) ->
  <div>
    <TableSettingsContext.Provider value={settings}>
      <CommonComparatorTableWithI18n {...props} renderMode="printout"/>
    </TableSettingsContext.Provider>
  </div>

module.exports = NmaTablePrintout
