var GeneralInformationActions, INITIAL_STATE, generateStore;

GeneralInformationActions = require('actions/general_information_actions');

generateStore = require('stores/utils/generate_store');

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    error: null
  },
  doc: Immutable.Map()
});

module.exports = generateStore({
  name: 'GeneralInformationStore',
  initialState: INITIAL_STATE,
  boundActions: [GeneralInformationActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'fetching'], true);
        return state.setIn(['meta', 'error'], null);
      }));
    },
    onFetchSuccess: function(doc) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'fetching'], false);
        state.setIn(['meta', 'error'], null);
        return state.set('doc', Immutable.fromJS(doc));
      }));
    },
    onFetchError: function(err) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'fetching'], false);
        return state.setIn(['meta', 'error'], err);
      }));
    },
    onDbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      return this.setState(this.state.set('doc', Immutable.fromJS(doc)));
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'fetching']);
    },
    getError: function() {
      return this.state.getIn(['meta', 'error']);
    },
    getDocField: function(fieldKey) {
      var value;
      value = this.state.getIn(['doc', fieldKey]);
      if (Immutable.List.isList(value) || Immutable.Map.isMap(value)) {
        return value.toJS();
      } else {
        return value;
      }
    },
    onUpdateField: function(_arg) {
      var field, value;
      field = _arg.field, value = _arg.value;
      return this.setState(this.state.setIn(['doc', field], value));
    }
  }
});
