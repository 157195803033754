mediator = require 'mediator'
SelectCustom = require 'components/common/select_custom'
{ useI18n } = require 'lib/react_utils'
{ useMemo } = React
{ getTranslationKeyPostFix } = require 'lib/nma_helper'

THRESHOLD_OPTIONS = ['MagnitudesOfEffect', 'MinimallyImportantDifferences']

QuestionThresholdOptions = ({ value, onChange }) ->
  i18n = useI18n 'nma:questions_list.modal'

  options = useMemo ->
    THRESHOLD_OPTIONS.map (option) -> { 
      value: option,
      text: i18n("threshold_options.#{_.str.underscored(option)}#{getTranslationKeyPostFix()}")
    }
  , []

  <div>
    <SelectCustom
      className='threshold-options'
      options={options}
      selected={value}
      onChange={onChange}
      selectValueComponent={(option) ->
        <div dangerouslySetInnerHTML={{__html: option.text}} />
      }
      itemRenderer={(props) -> <Option {...props} />}
    />
  </div>


Option = ({ onClick, disabled, title, value }) ->
  i18n = useI18n 'nma:questions_list.modal'
  <li
    className="threshold-option"
    onClick={onClick}
    disabled={disabled}
    title={title}
  >
    <div dangerouslySetInnerHTML={{
        __html: i18n("threshold_options.#{_.str.underscored(value)}#{getTranslationKeyPostFix()}")
      }}
    />
  </li>

module.exports = QuestionThresholdOptions
