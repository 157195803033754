var OUTCOME_SCALE_TYPES, THRESHOLDS;

OUTCOME_SCALE_TYPES = ['ordinal', 'ratioOrInterval'];

THRESHOLDS = [0.0165, 0.0312, 0.0577];

module.exports = {
  OUTCOME_SCALE_TYPES: OUTCOME_SCALE_TYPES,
  THRESHOLDS: THRESHOLDS
};
