ConnectStore = require 'components/enhancers/connect_store'
CreateUpdateFormModal = require 'components/common/create_update_form_modal'
NMAQuestionActions = require 'actions/nma_question_actions'
NMAQuestionStore = require 'stores/nma_question_store'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'
{ THRESHOLDS } = require 'lib/outcomes_helper'
{ getTranslationKeyPostFix } = require 'lib/nma_helper'

storeConnector =
  NMAQuestionStore: (Store) ->
    isApplying: Store.isOutcomeModalApplying()
    isOpen: Store.isOutcomeModalOpen()
    mode: Store.getOutcomeModalMode()
    outcome: Store.getModalOutcome()
    question: Store.getCurrentQuestion()

TYPE_OPTIONS = [
  value: 'dich'
  textKey: 'dich'
,
  value: 'cont'
  textKey: 'cont'
,
  value: 'timeToEvent'
  textKey: 'time_to_event'
]

fieldThatMightBeAutocalculated = ['T1', 'T2', 'T3']

isFieldDisabled = (data, item) ->
  return true if item.readOnly or item.disabled
  switch item.fieldKey
    when 'thresholdsAutoRange'
      data['utility'] in [undefined, null, '']
    when 'T1', 'T2', 'T3'
      'use_auto_range' in (data['thresholdsAutoRange'] or [])
    else
      false

getFieldValue = (data, item) ->
  { fieldKey, initialValue, parentValueKeys, additionalProps } = item
  
  if _.isEmpty(parentValueKeys)
    if initialValue and data[fieldKey] in [undefined, null]
      return initialValue
    else
      return data[fieldKey]

  if fieldKey in fieldThatMightBeAutocalculated
    parentValue = _.pick data, parentValueKeys

    if 'use_auto_range' in (parentValue['thresholdsAutoRange'] or []) and parentValue['utility']

      disutility = 1 - parseFloat(parentValue['utility'])
      Number((
        disutility * parseFloat(additionalProps.thresholdValue) * additionalProps.denominator
      ).toFixed(0))
    else data[fieldKey]
  else
    data[fieldKey]

getFormDefAndData = (question, outcome) ->

  { thresholdOptions } = question

  getThresholdLabelKey = (key) ->  

    if thresholdOptions is 'MinimallyImportantDifferences'
      return 'threshold_minimally_important_differences' 
    
    "#{key}#{getTranslationKeyPostFix()}"

  form =
    main: [
      fieldKey: 'name'
      type: 'string'
      showClear: false
      useControlledInput: true
    ,
      fieldKey: 'shortName'
      type: 'string'
      showClear: false
      useControlledInput: true
    ,
      fieldKey: 'type'
      type: 'radioOptions'
      options: TYPE_OPTIONS
      showClear: false
      withOther: false
    ,
      fieldKey: 'units'
      type: 'string'
      showClear: false
      useControlledInput: true
    ,
      fieldKey: 'higherIsBetter'
      type: 'switcher'
      showClear: false
    ,
      fieldKey: 'baselineRisk'
      type: 'string'
      showClear: false
      additionalProps:
        type: 'number'
        step: 0.0001
      useControlledInput: true
    ]
    thresholds: [
      fieldKey: 'utility'
      type: 'string'
      showClear: false
      fill: false
      additionalProps:
        min: 0
        max: 1
        type: 'number'
        step: 0.01
      useControlledInput: true
    , outcome.type isnt 'cont' and {
        fieldKey: 'thresholdsAutoRange'
        type: 'checkboxes'
        options: [
          'use_auto_range'
        ]
        fill: false
        showClear: false
        useControlledInput: true
      }
    ,
      fieldKey: 'T1'
      type: 'string'
      fill: false
      labelKey: getThresholdLabelKey('T1')
      showClear: false  
      parentValueKeys: ['utility', 'thresholdsAutoRange']
      additionalProps:
        type: 'number'
        thresholdValue: THRESHOLDS[0]
        denominator: 1000
      rightElement: outcome.type isnt 'cont' and $.t 'nma:question.outcomes.modal.fields.per_denominator', { denominator: 1000 }
      useControlledInput: true
    , thresholdOptions isnt 'MinimallyImportantDifferences' and {
        fieldKey: 'T2'
        labelKey: getThresholdLabelKey('T2')
        type: 'string'
        fill: false
        showClear: false
        parentValueKeys: ['utility', 'thresholdsAutoRange']
        additionalProps:
          type: 'number'
          thresholdValue: THRESHOLDS[1]
          denominator: 1000
        rightElement: outcome.type isnt 'cont' and $.t 'nma:question.outcomes.modal.fields.per_denominator', { denominator: 1000 }
        useControlledInput: true
    }
    , thresholdOptions isnt 'MinimallyImportantDifferences' and {
      fieldKey: 'T3'
      type: 'string'
      labelKey: getThresholdLabelKey('T3')
      fill: false
      showClear: false
      parentValueKeys: ['utility', 'thresholdsAutoRange']
      rightElement: outcome.type isnt 'cont' and $.t 'nma:question.outcomes.modal.fields.per_denominator', { denominator: 1000 }
      additionalProps:  
        type: 'number'
        thresholdValue: THRESHOLDS[2]
        denominator: 1000
      useControlledInput: true
    }]

  form = _.mapObject(form, (itemGroup, key) ->
    _.compact(_.map(itemGroup, (item) ->
      item && _.extend({}, item,
        value: getFieldValue(outcome, item)
        disabled: isFieldDisabled(outcome, item)
      )
    ))
  )

  indexedForm = _.indexBy(_.flatten(_.values(form)), 'fieldKey')

  recalculatedData = _.mapObject(outcome, (value, key) -> 
    if key in fieldThatMightBeAutocalculated and indexedForm[key]
      getFieldValue(outcome, indexedForm[key])
    else
      value    
  )

  { data: recalculatedData, form }

NMAOutcomeModal = ({ isApplying, isOpen, mode, outcome, question }) ->
  i18n = useI18n 'nma:question.outcomes.modal'

  onCreateOutcome = useCoffeeCallback [outcome, question], ->
    NMAQuestionActions.createOutcome question.get('_id'), outcome.toJS()

  { data, form } = React.useMemo () ->
    getFormDefAndData(question.toJS(), outcome.toJS())
  , [question, outcome]

  <CreateUpdateFormModal
    className="nma-outcome-modal"
    data={data}
    form={form}
    i18n={i18n}
    isApplying={isApplying}
    isOpen={isOpen}
    isSubmitEnabled={not isApplying and outcome.get('name')?.length > 0}
    mode={mode}
    onCancel={NMAQuestionActions.closeOutcomeModal}
    onCreate={onCreateOutcome}
    onChange={NMAQuestionActions.updateOutcomeInModal}
    onUpdate={NMAQuestionActions.updateOutcome}
    title={i18n "title.#{mode}"}
  />

NMAOutcomeModal.propTypes =
  isApplying: PropTypes.bool.isRequired
  isOpen: PropTypes.bool.isRequired
  outcome: PropTypes.instanceOf(Immutable.Map).isRequired
  mode: PropTypes.oneOf(['create', 'edit']).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore NMAOutcomeModal, [NMAQuestionStore], storeConnector
