{ getGrouppedTagsFromQuestion } = require 'lib/question_tags_helper'
Button = require 'components/common/button'
CellContentModeDialog = require 'components/etd/etd_header_cell_content_mode_dialog'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
etdHelper = require 'lib/etd_helper'
HeaderRow = require 'components/etd/etd_header_row'
mediator = require 'mediator'
Modal = require 'components/common/modal'
ProgressSelect = require 'components/evidence_syntheses/progress_select'
Router = require 'router'
Translation = require 'components/mixins/translation'
utils = require 'base/lib/utils'

AUTOGENERATED_CONTENT_SECTIONS = [
  'intent'
  'age'
  'population'
  'option'
  'intervention'
  'comparison'
  'mainOutcomes'
  'setting'
]

Header = createReactClass

  displayName: 'EtdHeaderComponent'
  mixins: [CustomRenderMixin, Translation('')]

  propTypes:
    headerSections: PropTypes.object.isRequired
    headerTransitionEnd: PropTypes.func
    type: PropTypes.oneOf(
      ['overarching', 'keyMessages', 'regular', 'qualityIndicators', 'mdgRecommendation']
    ).isRequired
    keyMessagesData: PropTypes.instanceOf(Immutable.Map)
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map).isRequired
    questions: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.OrderedMap),
      PropTypes.instanceOf(Immutable.Map)]
    )

  getDefaultProps: ->
    keyMessagesData: Immutable.Map()
    questions: Immutable.Map()

  getInitialState: ->
    cellToSwitchContentMode: null

  enableEdit: ->
    { type } = @props
    questionData = @_getQuestionData()
    if type is 'overarching'
      route = Router::getProjectRelativeUrl("/overarching-questions/#{questionData.get('_id')}/edit")
      mediator.publish '!router:route', route
    else
      EtdActions.enableHeaderEdit()

  getHeaderControls: ->
    isEditing = @_isEditing()
    [
      name: if isEditing then 'save' else 'edit'
      handler: if isEditing then @handleSave else @enableEdit
    ]

  handleCollapse: ->
    @handleSave()
    @toggleExpand()

  handleSave: ->
    if @_isEditing()
      EtdActions.saveHeaderData()

  showContentModeSwitchModal: (sectionId) ->
    @setState cellToSwitchContentMode: sectionId

  hideContentModeSwitchModal: ->
    @setState cellToSwitchContentMode: null

  handleContentSwitchDecision: (accepted) ->
    sectionId = @state.cellToSwitchContentMode
    @hideContentModeSwitchModal()
    if accepted
      # update cell html with the default content if new mode is manual (that is current
      # mode is auto)
      if @_isAutoContentCell sectionId
        EtdActions.updateHeaderFieldContent {
          sectionId,
          content: @_getQuestionAttrContent sectionId
        }
      EtdActions.switchCellContentMode(sectionId)
    else
      @refs[sectionId].focusEditCell()

  toggleExpand: ->
    EtdActions.toggleHeader()

  _isDxQuestionWithoutComparatorTest: ->
    @props.questionType is 'dx' and not @props.question.get 'comparatorTestPresent'

  _getQuestionAttrName: (sectionId) ->
    header2questionAttrMap =
      'population': 'healthProblemOrPopulation'
      'setting': 'settings'
      'intervention_tx': 'intervention'
      'option': 'intervention'
      'comparison_tx': 'comparison'
      'intervention_dx': 'indexTest'
      'comparison_dx': 'comparatorTest'

    header2questionAttrMap[sectionId]

  _getCellContent: (sectionId) ->
    defaultContent = @_getQuestionAttrContent sectionId
    if @_hasAutoContentGenerationOn sectionId
      defaultContent
    else
      @props.headerSections.get(sectionId).get('content')

  _getQuestionData: (type) ->
    { type, overarchingQuestionData, keyMessagesData } = @props
    switch type
      when 'overarching' then overarchingQuestionData
      when 'keyMessages' then keyMessagesData

  _getQuestionAttrContent: (sectionId) ->
    { type, overarchingQuestionData, question, questionType, keyMessagesData } = @props


    if type in ['overarching', 'keyMessages']
      questionData = @_getQuestionData()

      switch sectionId
        when 'mainOutcomes', 'outcomes'
          etdHelper.getTagsString 'outcome', getGrouppedTagsFromQuestion(
            questionData)
        when 'age'
          etdHelper.getAgeString questionData.getIn(
            ['metadata', 'age'], Immutable.List())
        when 'intent'
          etdHelper.getIntentString questionData.getIn(
            ['metadata', 'intent'], Immutable.List())
        when 'population', 'intervention', 'comparison'
          etdHelper.getTagsString sectionId, getGrouppedTagsFromQuestion(
            questionData)
        when 'setting', 'author', 'background', 'perspective', 'coi'
          questionData.get(sectionId, "")
        else
          ''
    else
      switch sectionId
        when 'mainOutcomes'
          etdHelper.getOutcomesString(question)
        when 'intervention', 'comparison'
          sectionId = "#{sectionId}_#{questionType}"
          questionAttrName = @_getQuestionAttrName sectionId
          content = question.get questionAttrName
          # do not show "[comparator_test]" text
          placeholderString = utils.bracketize $.t 'es:question.comparator_test'
          if content is placeholderString then '' else content
        when 'population', 'setting', 'option'
          questionAttrName = @_getQuestionAttrName sectionId
          question.get questionAttrName
        else
          ''

  headerRow: (sectionData, sectionId) ->
    return null unless sectionData
    # don't render "comparison" section if this is a dx question without comparator test
    return null if sectionId is 'comparison' and @_isDxQuestionWithoutComparatorTest()
    headerRowProps = @_getHeaderRowProps(sectionData, sectionId)

    <HeaderRow {...headerRowProps} />

  _getHeaderRowProps: (sectionData, sectionId) ->
    return {
      cellContent: @_getCellContent(sectionId)
      cellLabel: sectionData.get 'name'
      hasSwitchButton: @_isAutoContentCell sectionId
      inAutoMode: @_hasAutoContentGenerationOn sectionId
      isEditing: @_isEditing()
      key: sectionId
      questionType: @props.questionType
      ref: sectionId
      renderMode: @props.renderMode
      sectionId: sectionId
      showContentModeSwitchModal: @showContentModeSwitchModal.bind this, sectionId
      updateHeaderFieldContent: EtdActions.updateHeaderFieldContent
    }

  _isAutoContentCell: (sectionId) ->
    sectionId in AUTOGENERATED_CONTENT_SECTIONS

  _hasAutoContentGenerationOn: (sectionId) ->
    return true if @props.type is 'overarching' # always auto for overarching
    if @_isAutoContentCell sectionId
      @props.headerSections.getIn([sectionId, 'autoContentGenerationOn'], true)

  _getQuestionText: ->
    { type } = @props
    if type is 'overarching'
      etdHelper.getOverarchingQuestionText @props.overarchingQuestionData
    else
      etdHelper.getQuestionText @props.question, @props.renderMode

  _isInPrintoutMode: ->
    @props.renderMode is 'printout'

  _isEditing: ->
    if @_isInPrintoutMode() then false else @props.isHeaderEditing

  componentWillUnmount: ->
    if @_isEditing()
      @handleSave()
      mediator.dialogs.success $.t 'messages.changes_saved'

  render: ->
    # return if question hasn't been fetched yet since call to it is async. Later on when Question
    # store is updated render func will re-run and render the content
    {
      headerSections
      headerTransitionEnd
      isHeaderExpanded
      overarchingQuestionData
      projectFromOrganization
      question
      questionType
      questionStatuses
    } = @props

    return null if question.isEmpty() and overarchingQuestionData.isEmpty()

    isMdgFeaturesOn = mediator.services.switches.isServerSwitchOn('mdgFeatures')

    <div className='recommendations-header'>
      {if @_isInPrintoutMode()
        <table>
          <tbody>
            <tr className="title">
              <td colSpan="2">
                <h1 className='title'>
                  {@i18n "/es:question.question"}
                </h1>
              </td>
            </tr>
            <tr>
              <td className="question" colSpan="2">
                <p dangerouslySetInnerHTML={{__html: @_getQuestionText()}}/>
              </td>
            </tr>
            {headerSections.map(@headerRow).toList()}
          </tbody>
        </table>
      else
        <CollapsibleWithControls
          isExpanded={isHeaderExpanded}
          controls={@getHeaderControls()}
          onExpand={@toggleExpand}
          onCollapse={@handleCollapse}
          withTogglableCaption
          onTransitionEnd={headerTransitionEnd}
        >
          <div className='title-container'>
            <h1 className='title'>
              {@i18n "/es:question.question"}
            </h1>
            {projectFromOrganization and not isMdgFeaturesOn and
              <ProgressSelect
                groupKey={'recommendationStatus'}
                questionId={question.get('_id')}
                questionStatuses={questionStatuses}
              />
            }
          </div>
          <table>
            <colgroup>
              <col className="c-1" />
              <col className="c-2" />
              <col className="right-button" />
            </colgroup>
            <tbody>
              <tr>
                <td
                  className="question"
                  colSpan="2"
                  dangerouslySetInnerHTML={{__html: @_getQuestionText()}}
                />
              </tr>
              {headerSections.map(@headerRow).toList()}
            </tbody>
          </table>
        </CollapsibleWithControls>
      }
      {
        unless @_isInPrintoutMode()
          <Modal
            modalSize="standard"
            className="content-mode-switch-modal"
            isOpen={@state.cellToSwitchContentMode isnt null}
            closeButton={true}
            onClose={@hideContentModeSwitchModal}
          >
            <CellContentModeDialog
              currentlyInAutoMode={@_hasAutoContentGenerationOn(@state.cellToSwitchContentMode)}
              handleInput={@handleContentSwitchDecision}
            />
          </Modal>
      }
    </div>

module.exports = Header
