ConnectStore = require 'components/enhancers/connect_store'
{ gdtDataToNmaData } = require 'lib/nma_helper'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
{ LeagueTableWithI18n, TableSettingsContext, STANDARD_DENOMINATOR } = NmaComponents
NMAImportModalActions = require 'actions/nma_import_modal_actions'
NMAImportModal = require 'components/nma/nma_import_modal'
NMAOutcomeActions = require 'actions/nma_outcome_actions'
NMAOutcomeStore = require 'stores/nma_outcome_store'
NMAQuestionActions = require 'actions/nma_question_actions'
NMAQuestionStore = require 'stores/nma_question_store'
Spinner = require 'components/common/spinner'
TextAreaWithApply = require 'components/common/text_area_with_apply'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

storeConnector =
  NMAQuestionStore: (Store) ->
    question: Store.getCurrentQuestion()
  NMAOutcomeStore: (Store) ->
    isFetching: Store.isFetching()
    outcome: Store.getOutcome()

NMAOutcome = ({ isFetching, outcome, question }) ->
  i18n = useI18n 'nma:outcome'
  outcomeId = outcome.get '_id'
  questionId = question.get '_id'
  fileName = outcome.get 'fileName'

  updateDescription = useCoffeeCallback [outcome, outcomeId], (newDescription) ->
    return if outcome.get('description') is newDescription
    NMAOutcomeActions.updateOutcomeDescription outcomeId, newDescription

  onImport = useCoffeeCallback [outcome], ->
    NMAImportModalActions.openImportModal outcome

  onDeleteExplanation = useCoffeeCallback [questionId], (explanationId) ->
    NMAQuestionActions.deleteExplanation questionId, explanationId

  onUpdateExplanations = useCoffeeCallback [questionId], (explanations) ->
    NMAQuestionActions.setExplanations questionId, explanations

  nmaData = useCoffeeMemo [outcome, question], ->
    gdtDataToNmaData question, Immutable.List [outcome]

  tableSettings = useCoffeeMemo [nmaData], ->
    denominator: STANDARD_DENOMINATOR
    outcomes: nmaData.outcomes

  updateOutcomeData = useCoffeeCallback [outcomeId], (interventionId, comparatorId, newData) ->
    NMAOutcomeActions.updateOutcomeData outcomeId, interventionId, comparatorId, newData

  return <Spinner /> if isFetching

  <div className="nma-outcome">
    <div className="nma-outcome__description">
      <h4>{i18n 'description'}</h4>
      <TextAreaWithApply
        className="nma-outcome__description-text"
        content={outcome.get('description', '')}
        onApply={updateDescription}
        placeholder={i18n 'description_placeholder'}
      />
    </div>
    <div className="nma-outcome__league-table">
      <div className="league-table__header">
        <h4>{i18n 'league_table'}</h4>
        <div className="header__controls">
          {if fileName
            <Tooltip>
              <div className="header__file-name" title={fileName}>
                <div>{fileName}</div>
              </div>
            </Tooltip>
          else
            <Tooltip>
              <IconButton
                iconName="import"
                onClick={onImport}
                title={i18n '/actions.import'}
              />
            </Tooltip>
          }
        </div>
      </div>
      <div>
        <TableSettingsContext.Provider value={tableSettings}>
          <LeagueTableWithI18n
            comparisons={nmaData.comparisons}
            deleteExplanation={onDeleteExplanation}
            explanations={nmaData.explanations}
            i18nextInstance={i18next}
            interventions={nmaData.interventions}
            outcome={nmaData.outcomes[0] ? {}}
            showCoeInsufficientLabel={mediator.services.switches.isServerSwitchOn('acpFeatures')}
            thresholdType={question.get('thresholdOptions')}
            updateExplanations={onUpdateExplanations}
            updateOutcomeData={updateOutcomeData}
          />
        </TableSettingsContext.Provider>
      </div>
    </div>
    <NMAImportModal />
  </div>

NMAOutcome.propTypes =
  isFetching: PropTypes.bool.isRequired
  outcome: PropTypes.instanceOf(Immutable.Map).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore NMAOutcome, [NMAOutcomeStore, NMAQuestionStore], storeConnector
