var INITIAL_INTERVENTION_MODAL_STATE, INITIAL_OUTCOME_MODAL_STATE, INITIAL_STATE, NMAQuestionActions, generateStore;

generateStore = require('stores/utils/generate_store');

NMAQuestionActions = require('actions/nma_question_actions');

INITIAL_OUTCOME_MODAL_STATE = Immutable.fromJS({
  applying: false,
  isOpen: false,
  outcome: Immutable.Map(),
  mode: 'create'
});

INITIAL_INTERVENTION_MODAL_STATE = Immutable.fromJS({
  applying: false,
  isOpen: false,
  intervention: Immutable.Map(),
  mode: 'create'
});

INITIAL_STATE = Immutable.fromJS({
  currentQuestionId: null,
  question: Immutable.Map(),
  outcomes: Immutable.Map(),
  isFetching: false,
  isInterventionManagementModalOpen: false,
  outcomeModal: INITIAL_OUTCOME_MODAL_STATE,
  interventionModal: INITIAL_INTERVENTION_MODAL_STATE
});

module.exports = generateStore({
  name: 'NMAQuestionStore',
  initialState: INITIAL_STATE,
  boundActions: [NMAQuestionActions],
  methods: {
    onFetch: function(currentQuestionId) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetching', true);
        return state.set('currentQuestionId', currentQuestionId);
      }));
    },
    onFetchQuestionSuccess: function(_arg) {
      var question, setCurrentQuestionId;
      setCurrentQuestionId = _arg.setCurrentQuestionId, question = _arg.question;
      if (setCurrentQuestionId == null) {
        setCurrentQuestionId = false;
      }
      return this.setState(this.state.withMutations(function(state) {
        state.set('question', Immutable.fromJS(question));
        if (setCurrentQuestionId) {
          return state.set('currentQuestionId', question._id);
        }
      }));
    },
    onFetchOutcomesSuccess: function(outcomes) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('outcomes', Immutable.fromJS(_.indexBy(outcomes, '_id')));
        return state.set('isFetching', false);
      }));
    },
    onFetchError: function() {
      return this.setState(this.state.set('isFetching', false));
    },
    onDbChange: function(change) {
      var _ref;
      if (change.id === this.state.get('currentQuestionId')) {
        return this.setState(this.state.set('question', Immutable.fromJS((_ref = change.doc) != null ? _ref : {})));
      } else {
        if (change.deleted) {
          return this.setState(this.state.deleteIn(['outcomes', change.id]));
        } else {
          return this.setState(this.state.setIn(['outcomes', change.id], Immutable.fromJS(change.doc)));
        }
      }
    },
    onOpenOutcomeModal: function(outcome) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['outcomeModal', 'isOpen'], true);
        state.setIn(['outcomeModal', 'outcome'], Immutable.fromJS(outcome != null ? outcome : {
          type: 'dich'
        }));
        return state.setIn(['outcomeModal', 'mode'], outcome != null ? 'edit' : 'create');
      }));
    },
    onCloseOutcomeModal: function() {
      this.setState(this.state.setIn(['outcomeModal', 'isOpen'], false));
      return setTimeout((function(_this) {
        return function() {
          return _this.setState(_this.state.set('outcomeModal', INITIAL_OUTCOME_MODAL_STATE));
        };
      })(this), 300);
    },
    onCreateOutcome: function() {
      return this.setState(this.state.setIn(['outcomeModal', 'applying'], true));
    },
    onUpdateOutcome: function() {
      return this.setState(this.state.setIn(['outcomeModal', 'applying'], true));
    },
    onUpdateOutcomeInModal: function(_arg) {
      var field, value;
      field = _arg.field, value = _arg.value;
      return this.setState(this.state.setIn(['outcomeModal', 'outcome', field], value));
    },
    onMoveOutcome: function(_arg) {
      var hoverOutcomeId, movingOutcomeId, placementDelta;
      hoverOutcomeId = _arg.hoverOutcomeId, movingOutcomeId = _arg.movingOutcomeId, placementDelta = _arg.placementDelta;
      return this.setState(this.state.updateIn(['question', 'outcomes'], function(outcomes) {
        var index, newOutcomes;
        newOutcomes = outcomes.filterNot(function(id) {
          return id === movingOutcomeId;
        });
        index = newOutcomes.findIndex(function(id) {
          return id === hoverOutcomeId;
        });
        return newOutcomes.insert(index + placementDelta, movingOutcomeId);
      }));
    },
    onOpenInterventionManagementModal: function() {
      return this.setState(this.state.set('isInterventionManagementModalOpen', true));
    },
    onCloseInterventionManagementModal: function() {
      return this.setState(this.state.set('isInterventionManagementModalOpen', false));
    },
    onOpenInterventionEditModal: function(intervention) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['interventionModal', 'isOpen'], true);
        state.setIn(['interventionModal', 'intervention'], Immutable.fromJS(intervention != null ? intervention : {}));
        return state.setIn(['interventionModal', 'mode'], intervention != null ? 'edit' : 'create');
      }));
    },
    onCloseInterventionEditModal: function() {
      this.setState(this.state.setIn(['interventionModal', 'isOpen'], false));
      return setTimeout((function(_this) {
        return function() {
          return _this.setState(_this.state.set('interventionModal', INITIAL_INTERVENTION_MODAL_STATE));
        };
      })(this), 300);
    },
    onCreateIntervention: function() {
      return this.setState(this.state.setIn(['interventionModal', 'applying'], true));
    },
    onUpdateIntervention: function() {
      return this.setState(this.state.setIn(['interventionModal', 'applying'], true));
    },
    onUpdateInterventionInModal: function(_arg) {
      var field, value;
      field = _arg.field, value = _arg.value;
      return this.setState(this.state.setIn(['interventionModal', 'intervention', field], value));
    },
    onMoveIntervention: function(_arg) {
      var hoverInterventionId, movingInterventionId, placementDelta;
      hoverInterventionId = _arg.hoverInterventionId, movingInterventionId = _arg.movingInterventionId, placementDelta = _arg.placementDelta;
      return this.setState(this.state.updateIn(['question', 'interventions'], function(interventions) {
        var index, movingIntervention, newInterventions;
        movingIntervention = interventions.find(function(intervention) {
          return intervention.get('_id') === movingInterventionId;
        });
        newInterventions = interventions.filterNot(function(intervention) {
          return intervention.get('_id') === movingInterventionId;
        });
        index = newInterventions.findIndex(function(intervention) {
          return intervention.get('_id') === hoverInterventionId;
        });
        return newInterventions.insert(index + placementDelta, movingIntervention);
      }));
    },
    getCurrentQuestion: function() {
      return this.state.get('question', Immutable.Map());
    },
    getOutcomes: function() {
      return this.state.get('outcomes');
    },
    isFetching: function() {
      return this.state.get('isFetching');
    },
    isOutcomeModalOpen: function() {
      return this.state.getIn(['outcomeModal', 'isOpen']);
    },
    isOutcomeModalApplying: function() {
      return this.state.getIn(['outcomeModal', 'applying']);
    },
    getModalOutcome: function() {
      return this.state.getIn(['outcomeModal', 'outcome']);
    },
    getOutcomeModalMode: function() {
      return this.state.getIn(['outcomeModal', 'mode']);
    },
    isInterventionManagementModalOpen: function() {
      return this.state.get('isInterventionManagementModalOpen');
    },
    isInterventionEditModalOpen: function() {
      return this.state.getIn(['interventionModal', 'isOpen']);
    },
    isInterventionEditModalApplying: function() {
      return this.state.getIn(['interventionModal', 'applying']);
    },
    getModalIntervention: function() {
      return this.state.getIn(['interventionModal', 'intervention']);
    },
    getInterventionModalMode: function() {
      return this.state.getIn(['interventionModal', 'mode']);
    }
  }
});
