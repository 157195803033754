{ useCoffeeCallback, useCoffeeEffect } = require 'lib/react_utils'
{ useState } = React

StringField = ({
  className
  disabled
  max
  min
  onChange
  placeholder
  step
  type
  useControlledInput
  value
}) ->
  [text, setText] = useState(value)

  onBlur = useCoffeeCallback [onChange, text], -> onChange text

  onStringFieldChange = useCoffeeCallback [onChange, setText, useControlledInput], (evt) ->
    if useControlledInput
      onChange evt.target.value
    else
      setText evt.target.value

  useCoffeeEffect [setText, value], -> setText value

  <input
    className={className}
    disabled={disabled}
    onBlur={if useControlledInput then undefined else onBlur}
    onChange={onStringFieldChange}
    type={type}
    step={step}
    placeholder={placeholder}
    min={min}
    max={max}
    value={if useControlledInput then value else text}
  />

StringField.propTypes =
  className: PropTypes.string
  disabled: PropTypes.bool
  max: PropTypes.number
  min: PropTypes.number
  onChange: PropTypes.func.isRequired
  placeholder: PropTypes.string
  step: PropTypes.number
  type: PropTypes.oneOf(['text', 'number'])
  useControlledInput: PropTypes.bool
  value: PropTypes.string.isRequired

StringField.defaultProps =
  className: ''
  disabled: false
  max: null
  min: null
  placeholder: ''
  type: 'text'
  useControlledInput: false
  value: ''

module.exports = StringField
