ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
{ QuestionTags, QUESTION_TAGS_PROPS } = require 'components/common/question_tags'
Select = require 'components/common/select_custom'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
{ fieldShape } = require 'components/covid_extraction_form/extraction_form_field'

QuestionCreator = createReactClass
  displayName: 'QuestionCreator'
  propTypes:
    creating: PropTypes.bool.isRequired
    fields: PropTypes.arrayOf(fieldShape).isRequired
    isFetching: PropTypes.bool.isRequired
    onChange: PropTypes.func.isRequired
    onSave: PropTypes.func.isRequired
    onCancel: PropTypes.func.isRequired
    question: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [Translation()]

  render: ->
    { creating, fields, isFetching, onCancel, onChange, onSave, question } = @props

    return <Spinner /> if isFetching

    <div className="question-creator">
      <div className="question-creator__form">
        <ExtractionForm
          data={question.toJS()}
          form={{ main: fields }}
          i18n={@i18n}
          inModal={false}
          onChange={onChange}
        />
      </div>
      <ApplyCancelButtons
        applyLabel={@i18n if creating then 'actions.create' else 'actions.save'}
        onApply={onSave}
        onCancel={onCancel}
      />
    </div>

module.exports = {
  fieldShape
  QuestionCreator
}
