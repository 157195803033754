Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
SectionContainer = require 'components/document_sections/v2/section_container'
ConfirmationModal = require 'components/common/confirmation_modal'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
mediator = require 'mediator'
{ scrollIntoView } = require 'lib/epiditor_utils'

ChapterSections = createReactClass
  displayName: 'ChapterSections'

  mixins: [
    Translation('docsec')
  ]

  propTypes:
    acceptedOrRejectedChange: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      changeId: PropTypes.string.isRequired
      accepted: PropTypes.bool.isRequired
    })
    chapterId: PropTypes.string.isRequired
    draftDiscussions: PropTypes.instanceOf(Immutable.Map).isRequired
    editingSections: PropTypes.instanceOf(Immutable.Map).isRequired
    hasChanges: PropTypes.bool.isRequired
    isFetching: PropTypes.bool.isRequired
    mdaTables: PropTypes.instanceOf(Immutable.Map)
    mdaTopicId: PropTypes.string
    onAddCode: PropTypes.func.isRequired
    onInsertMdaTable: PropTypes.func.isRequired
    onInsertRecommendation: PropTypes.func.isRequired
    onInsertReferences: PropTypes.func.isRequired
    questions: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
    recommendations: PropTypes.instanceOf(Immutable.Map)
    scrollToSection: PropTypes.string
    scrollToSectionContent: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      contentPos: PropTypes.number.isRequired
    })
    sections: PropTypes.instanceOf(Immutable.Map)
    sectionsIds: PropTypes.instanceOf(Immutable.List).isRequired
    suggesting: PropTypes.bool.isRequired
    view: PropTypes.string.isRequired
    updatedDraftDiscussion: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      discussionType: PropTypes.string.isRequired
      data: PropTypes.object
    })

  getInitialState: () ->
    deletedSectionId: null

  addSection: ->
    DocumentSectionsV2Actions.addSection(mediator.project.id, @props.chapterId)

  deleteSection: () ->
    projectId = mediator.project.id
    chapterId = @props.chapterId
    DocumentSectionsV2Actions.deleteSection(projectId, chapterId, @state.deletedSectionId)
    @closeDeleteConfirmation()

  confirmDelete: (sectionId) ->
    @setState
      deletedSectionId: sectionId

  closeDeleteConfirmation: ->
    @setState
      deletedSectionId: null

  render: ->
    {
      acceptedOrRejectedChange,
      chapterId,
      discussions,
      draftDiscussions,
      editingSections,
      hasChanges,
      isFetching,
      mdaTables,
      mdaTopicId,
      onAddCode,
      onInsertMdaTable,
      onInsertRecommendation,
      onInsertReferences,
      questions,
      recommendations,
      scrollToSection,
      scrollToSectionContent,
      sections,
      sectionsIds,
      suggesting,
      updatedDraftDiscussion,
      view
    } = @props
    { deletedSectionId } = @state

    if isFetching
      <Spinner />
    else
      <div className="chapter-sections">
        {
          if sections.isEmpty()
            <div className="no-sections-container">
              <div>No sections.</div>
              <div className="no-sections-add-container">
                <span className="no-sections-add-text" onClick={@addSection}>
                  + {@i18n '/docsec:chapter_settings_options.add_new_text_field'}
                </span>
              </div>
            </div>
          else
            sectionsIds.map (sectionId, index) =>
              section = sections.get(sectionId)
              return null unless section
              recommendationId = section.get('recommendationId')
              recommendation = recommendations.get(recommendationId) if recommendationId
              questionId = section.get('questionId')
              question = questions.get(questionId) if questionId
              mdaTableId = section.get('mdaTableId')
              mdaTable = mdaTables.get(mdaTableId) if mdaTableId
              hasDraftDiscussionUpdated = updatedDraftDiscussion?.sectionId is sectionId
              hasChangeDecision = acceptedOrRejectedChange?.sectionId is sectionId
              scrollSectionIntoView = sectionId is scrollToSection

              <SectionContainer
                acceptedOrRejectedChange={acceptedOrRejectedChange if hasChangeDecision}
                chapterId={chapterId}
                draftDiscussions={draftDiscussions.get sectionId}
                editingSection={editingSections.get(sectionId, Immutable.Map())}
                hasChanges={hasChanges}
                index={index}
                key={sectionId}
                mdaTable={mdaTable}
                mdaTopicId={mdaTopicId}
                onAddCode={onAddCode}
                onDelete={@confirmDelete}
                onInsertMdaTable={onInsertMdaTable}
                onInsertRecommendation={onInsertRecommendation}
                onInsertReferences={onInsertReferences}
                question={question}
                recommendation={recommendation}
                scrollSectionIntoView={scrollSectionIntoView}
                scrollToSectionContent={scrollToSectionContent}
                section={section}
                suggesting={suggesting}
                updatedDraftDiscussion={updatedDraftDiscussion if hasDraftDiscussionUpdated}
                view={view}
              />
        }
        <ConfirmationModal
          isOpen={Boolean(deletedSectionId)}
          question={@i18n '/docsec:chapter_section.delete_section_confirmation_question'}
          confirmLabel={@i18n '/docsec:chapter_section.delete_section_confirmation_label'}
          onConfirm={@deleteSection}
          onCancel={@closeDeleteConfirmation}
        />
      </div>

module.exports = ChapterSections
