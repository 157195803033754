CheckboxInput = require 'components/common/checkbox_input'
ContentEditable = require 'components/common/content_editable'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
MemberFormGroup = require 'components/team/member_form_group'
MemberTitleCell = require 'components/team/form/member_title_cell'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'

{ isReadOnly } = require 'lib/members_helper'
{ instanceOf, func, bool } = PropTypes

EditableNameCell = createReactClass

  displayName: "TeamMemberEditableNameCell"

  propTypes:
    member: instanceOf(Immutable.Map).isRequired
    errors: instanceOf(Immutable.Map)
    updateAttr: func.isRequired
    displayNotesGroup: bool

  getDefaultProps: ->
    displayNotesGroup: true

  mixins: [Translation('team:member'), CustomRenderMixin]

  getFormGroup: (attr, disabled = false) ->
    <MemberFormGroup
      attr={attr}
      disabled={disabled}
      error={@props.errors?.get(attr)}
      label={@i18n _.string.underscored(attr)}
      memberId={@props.member.get('_id')}
      updateAttr={@props.updateAttr}
      value={@props.member.get(attr) ? ""} />

  updateNotes: (e) ->
    @props.updateAttr 'notes', e.target.value

  updateTitle: (title) ->
    @props.updateAttr 'title', title

  renderNotesGroup: ->
    <div className="form-group">
      <div className="col-12">
        <label className="pull-left" htmlFor='notes'>{@i18n 'notes'}</label>
        <div className="pull-left textarea-container">
          <textarea
            className="content member-notes"
            value={@props.member.get('notes') ? ""}
            onChange={@updateNotes}
          />
        </div>
      </div>
      <div className="clearfix" />
    </div>

  render: ->
    <div className="editable-name-cell">
      {@getFormGroup('email', isReadOnly(@props.member))}
      {@getFormGroup('lastName')}
      {@getFormGroup('givenNames')}
      <MemberTitleCell member={@props.member} updateAttr={@updateTitle} />
      {if @props.displayNotesGroup
        @renderNotesGroup()
      }
    </div>

module.exports = EditableNameCell
