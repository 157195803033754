AddMemberButton = require 'components/team/add_member_button'
ConnectStores = require 'components/enhancers/connect_store'
EditableMemberRow = require 'components/team/form/editable_member_row'
LimitsWarning = require 'components/common/limits_warning'
MembersActions = require 'actions/members_actions'
MembersLimitsInfo = require 'components/team/members_limits_info'
MembersStore = require 'stores/members_store'
Modal = require 'components/common/modal'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
withLimits = require 'components/enhancers/with_limits'
{
  createMember
  filterAdminsAndResearchers
  getMembersWithPendingInvitationsCount
  parseTeamMembers
} = require 'lib/members_helper'
{ StepsContainer, Step } = require 'components/common/steps'
utils = require 'base/lib/utils'
{ isValidLimit } = require 'lib/limits_helper'


storeConnectors =
  MembersStore: (Store) ->
    members: Store.getMembers()

AddMultipleTeamMembers = createReactClass

  displayName: 'AddMultipleTeamMembers'

  mixins: [Translation('team:member')]

  propTypes:
    onApply: PropTypes.func.isRequired
    onCancel: PropTypes.func.isRequired
    limits: PropTypes.instanceOf(Immutable.Map).isRequired
    members: PropTypes.instanceOf(Immutable.Map).isRequired

  getInitialState: ->
    teamMembersList: ""
    members: new Immutable.List()
    errors: @props.errors
    currentStep: "members-textarea"

  componentWillReceiveProps: (nextProps) ->
    @setState errors: nextProps.errors, =>
      if(not nextProps.errors.isEmpty())
        invalidElements = @listContainer?.getElementsByClassName('invalid')
        return if _.isEmpty invalidElements
        $(invalidElements[0]).closest('.new-member')[0]?.scrollIntoView(true)

  onRemoveMember: (member) ->
    members = @state.members.filterNot (m) -> m is member
    errors = @state.errors.delete member.get('_id')
    # Have to update errors in store
    MembersActions.updateValidationErrors errors
    @setState {members, errors}

  addNewMember: ->
    @setState members: @state.members.push Immutable.fromJS createMember()

  onChangeTeamMembersList: (e) ->
    @setState teamMembersList: e.target.value

  updateAttr: (member, attr, value) ->
    members = @state.members.update (members) ->
      members.map (m) ->
        return m if m.get('_id') isnt member.get('_id')
        m.set(attr, value)

    # clear validation error on update field
    errors = @state.errors.update (member.get('_id')), (errors) ->
      errors.delete(attr) if errors
    @setState members: members, errors: errors

  _getTotalMembersCount: ->
    @state.members.size + @props.members.size

  _getTotalAdminsCount: ->
    { members } = @props
    adminsToAddCount = filterAdminsAndResearchers(@state.members)?.size or 0
    currentAdminsCount = filterAdminsAndResearchers(members).size
    pendingInvitationsCount = getMembersWithPendingInvitationsCount members
    currentAdminsCount + adminsToAddCount + pendingInvitationsCount

  isNextStepEnabled: (step) ->
    { members, limits } = @props

    membersLimit = limits.get('teamMembers')
    adminsLimit = limits.get('teamAdmins')

    switch @state.currentStep
      when 'members-textarea'
        not _.isEmpty(@state.teamMembersList) and isValidLimit members.size, membersLimit, 'lte'
      when 'members-list'
        not @state.members.isEmpty() and
          isValidLimit(@_getTotalMembersCount(), membersLimit, 'lte') and
          isValidLimit(@_getTotalAdminsCount(), adminsLimit, 'lte')

  parseTeamMembers: ->
    @setState members: parseTeamMembers @state.teamMembersList
    @goToStep 'members-list'

  onApply: ->
    return if @state.members.isEmpty()
    @props.onApply(@state.members)

  _refListContainer: (e) -> @listContainer = e

  goToStep: (step) ->
    return unless @isNextStepEnabled step
    @setState currentStep: step

  getDisabledAccessRights: ->
    { limits } = @props
    admins = limits.get('teamAdmins')
    if isValidLimit(@_getTotalAdminsCount(), admins, 'lte') then [] else ['admin', 'researcher']

  render: ->

    { limits } = @props

    currentMembersCount = @props.members.size
    currentAdminsCount = filterAdminsAndResearchers(@props.members).size

    membersLimit = limits.get('teamMembers')
    adminsLimit = limits.get('teamAdmins')
    disabledAccessRights = @getDisabledAccessRights()
    totalAdminsCount = @_getTotalAdminsCount()
    totalMembersCount = @_getTotalMembersCount()
    addMemberButtonEnabled = isValidLimit(totalMembersCount, membersLimit)

    <Modal
      className="add-team-member-modal multiple"
      isOpen={true}
      closeButton={true}
      modalSize="medium"
      title={@i18n 'add_multiple.title'}
      onClose={@props.onCancel}
    >
      <StepsContainer
        applyLabel={@i18n "/actions.apply"}
        cancelLabel={@i18n "/actions.cancel"}
        isNextStepEnabled={@isNextStepEnabled(@state.currentStep)}
        currentStep={@state.currentStep}
      >
        <Step
          stepKey="members-textarea"
          stepLabel={@i18n 'add_multiple.step1'}
          nextLabel={@i18n "/actions.next"}
          onNext={@parseTeamMembers}
          onBack={@props.onCancel}
        >
          <div className="step1">
            <MembersLimitsInfo
              adminsCount={currentAdminsCount}
              i18n={@i18n}
              limits={limits}
              membersCount={currentMembersCount}
            />
            <LimitsWarning
              limit={adminsLimit}
              message={@i18n '../limits.admins_limit_reached'}
              value={totalAdminsCount}
            />
            <div className="description">{@i18n 'add_multiple.description'}</div>
            <div className="textarea-container">
              <textarea
                onChange={@onChangeTeamMembersList}
                defaultValue={@state.teamMembersList}
                placeholder={@i18n 'add_multiple.placeholder'}
                autoFocus={true}
              />
            </div>
          </div>
        </Step>
        <Step
          stepKey="members-list"
          stepLabel={@i18n 'add_multiple.step2'}
          onNext={@onApply}
          onBack={@props.onCancel}
        >
          <div>
            <div className="step2">
              <MembersLimitsInfo
                adminsCount={currentAdminsCount}
                i18n={@i18n}
                limits={limits}
                membersCount={currentMembersCount}
              />
              <LimitsWarning
                limit={membersLimit}
                message={@i18n '../limits.members_limit_reached'}
                value={totalMembersCount}
              />
              <LimitsWarning
                limit={adminsLimit}
                message={@i18n '../limits.admins_limit_reached'}
                value={totalAdminsCount}
              />
              <div className="description">{@i18n 'add_multiple.fill_details'}</div>
                <div className="members-list" ref={@_refListContainer}>
                  {@state.members.map((member) =>
                    <EditableMemberRow
                      adminsLimit={adminsLimit}
                      disabledAccessRights={disabledAccessRights}
                      key={member.get('_id')}
                      member={member}
                      errors={@state.errors?.get(member.get('_id'))}
                      updateAttr={@updateAttr.bind(this, member)}
                      removeMember={@onRemoveMember.bind(this, member)}
                    />
                  ).toList()}
                </div>
            </div>
            <AddMemberButton
              btnLabel={@i18n 'add'}
              disabled={not addMemberButtonEnabled}
              i18n={@i18n}
              onClick={@addNewMember}
            />
          </div>
        </Step>
      </StepsContainer>
    </Modal>



module.exports = withLimits(ConnectStores AddMultipleTeamMembers, [MembersStore], storeConnectors)
