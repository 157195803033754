CustomRenderMixin = require 'components/mixins/custom_render_mixin'
UserProjectDataActions = require 'actions/user_project_data_actions'
EtdActions = require 'actions/etd_actions'
EtdSectionTitle = require 'components/etd/etd_section_title'
IconButton = require 'components/common/icon_button'
QualityIndicatorsSummaryOfJudgements =
  require 'components/etd/quality_indicators_summary_of_judgements'
OverarchingSummaryOfJudgements = require 'components/etd/overarching_summary_of_judgement'
SummaryOfJudgementsSection = require 'components/etd/soj_section'
StarRatingTable = require 'components/overarching_questions/multiintervention_etd/star_rating_table'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ getTagsByKeys } = require 'lib/etd_helper'
{ pluck } = require 'lib/immutable_utils'

{ Card, CardContent } = ReactComponents

RegularSummaryOfJudgements = createReactClass

  displayName: "RegularSummaryOfJudgements"
  mixins: [Translation('es:recommendations.table'), CustomRenderMixin]

  render: ->

    {
      adolopmentData
      adolopments
      comparison
      criterions
      editable
      hiddenSections
      intervention
      isConsensus
      templateId
      renderMode
      sections
      toggleSection
    } = @props

    <table className="summary-of-judgements">
      <thead className="header">
        <tr>
          {if renderMode is 'printout'
            [
              <th className="criteria blank" key="1"></th>
              <th className="summary" key="2" colSpan="7">
                <p>{@i18n 'judgement'}</p>
              </th>
            ]
          else
            [
              <th className="criteria" key="1">{@i18n 'criteria'}</th>
              <th className="summary" key="2">{@i18n 'summary_of_judgements'}</th>
              <th className="importance" key="5">{@i18n 'importance_for_decision'}</th>
            ]
          }
        </tr>
      </thead>
      <tbody>
        {sections.map (section, key) ->
          criterionId = section.get 'criterionId'
          sectionHidden = hiddenSections.get(criterionId, false)

          withAdolopment = adolopmentData?.getIn([criterionId, 'withAdolopment'], false)

          criterion = if not adolopments or adolopments.isEmpty() or withAdolopment
            criterions.get criterionId
          else
            adolopment = adolopments.last()
            adolopment.getIn ['templateData', 'assessment', 'criteria', criterionId]

          <SummaryOfJudgementsSection
            comparison={comparison}
            criterion={criterion}
            criterionId={criterionId}
            editable={editable}
            intervention={intervention}
            isConsensus={isConsensus}
            key={key}
            onHideSection={toggleSection criterionId}
            templateId={templateId}
            renderMode={renderMode}
            section={section}
            sectionHidden={sectionHidden}
            sectionKey={key}
          />
        .toList()}
      </tbody>
    </table>

SummaryOfJudgements = createReactClass
  displayName: 'SummaryOfJudgementsComponent'
  mixins: [Translation('es:recommendations.table'), CustomRenderMixin]

  propTypes:
    etdId: PropTypes.string.isRequired
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map)
    templateId: PropTypes.string.isRequired
    qualityIndicators: PropTypes.instanceOf(Immutable.List)
    type: PropTypes.oneOf(['overarching', 'keyMessages', 'regular', 'qualityIndicators'])

  getDefaultProps: ->
    overarchingQuestionData: Immutable.Map()
    qualityIndicators: Immutable.List()

  toggleSection: (sectionName) -> =>
    UserProjectDataActions.toggleSojSection @props.etdId, sectionName

  getColumnsForGradingTable: ->
    { overarchingQuestionData } = @props
    interventionTags = getTagsByKeys overarchingQuestionData.get('includedTags'),
      ['intervention', 'indexTest']

    # We could do it in one sweep but know we can assure that comparison or comparator is in the end
    comparisonTags = getTagsByKeys overarchingQuestionData.get('includedTags'),
      ['comparison', 'comparatorTest']
    interventionTags.concat comparisonTags

  getRowsForGradingTable: ->
    SECTIONS = [
      'balanceOfEffects',
      'resourcesRequired'
      'costEffectiveness',
      'equity',
      'acceptability',
      'feasibility'
    ]
    { sections } = @props
    sections.filter (section) -> section.get('criterionId') in SECTIONS
    .map (section) -> section.set('id', section.get('criterionId'))

  handleRating: (sectionId, key) -> (newRating) ->
    EtdActions.setStarRating ({ sectionId, key, newRating })

  render: ->

    {
      adolopmentData
      adolopments
      comparison
      criterions
      editable
      hiddenSections
      intervention
      isConsensus
      qualityIndicators
      overarchingQuestionData
      templateId
      type
      renderMode
      sections
    } = @props

    <div className="soj-section">
      <EtdSectionTitle
        renderMode={@props.renderMode} title={@i18n 'summary_of_judgements'}
      />
      <Card>
        <CardContent>
          {switch type
            when 'overarching'
              <OverarchingSummaryOfJudgements
                criterions={criterions}
                editable={editable}
                hiddenSections={hiddenSections}
                overarchingQuestionData={overarchingQuestionData}
                qualityIndicators={qualityIndicators}
                renderMode={renderMode}
                sections={sections}
                templateId={templateId}
                toggleSection={@toggleSection}
                type={type}
              />
            when 'qualityIndicators'
              <QualityIndicatorsSummaryOfJudgements
                criterions={criterions}
                editable={editable}
                hiddenSections={hiddenSections}
                overarchingQuestionData={overarchingQuestionData}
                qualityIndicators={qualityIndicators}
                renderMode={renderMode}
                sections={sections}
                templateId={templateId}
                toggleSection={@toggleSection}
              />
            else
              <RegularSummaryOfJudgements
                adolopmentData={adolopmentData}
                adolopments={adolopments}
                comparison={comparison}
                criterions={criterions}
                editable={editable}
                hiddenSections={hiddenSections}
                intervention={intervention}
                isConsensus={isConsensus}
                renderMode={renderMode}
                sections={sections}
                toggleSection={@toggleSection}
              />
          }

          {if templateId is 'overarchingTx-v2'
            <React.Fragment>
              <br />
              <br />
              <StarRatingTable
                rating={pluck(criterions, 'starRating')}
                rows={@getRowsForGradingTable()}
                columns={@getColumnsForGradingTable()}
                onChangeRating={@handleRating}
              />
            </React.Fragment>
          }

        </CardContent>
      </Card>
    </div>

module.exports = SummaryOfJudgements
