DBEPAdolopmentSettingsModal = require './dbep/adolopment_settings_modal'
DBEPPublicationModal = require './dbep/dbep_publication_modal'
DBEPQuestionsTable = require './dbep/questions_table'

module.exports = createReactClass
  displayName: 'DBEPScreen'

  render: ->
    <div className="dbep-screen">
      <DBEPQuestionsTable />
      <DBEPPublicationModal />
      <DBEPAdolopmentSettingsModal />
    </div>
