AddSectionCommentButton = require 'components/document_sections/v2/add_section_comment_button'
EditorDecorators = require 'lib/editor_decorators'
GdtEditor = Editor.Editor
mediator = require 'mediator'
{ getImmutableJsOrStringContent } = require 'lib/immutable_utils'
{ rawContentToHTML } = require 'lib/draft_utils'
{ removeHTMLNode } = require 'base/lib/utils'
{ useI18n, useCoffeeCallback, useCoffeeMemo } = require 'lib/react_utils'

MdgRecommendationSectionContent = ({ mode, onAddComment, question, questionLinks, recommendation }) ->
  i18n = useI18n('es:recommendations.metadataExtractionForm')
  conclusions = recommendation.getIn ['templateData', 'conclusions']
  sections = conclusions.get('sections')
  sectionsNames = conclusions.get('sectionsOrder')
    .filter (sectionName) -> sectionName isnt 'mdgRecommendation'
  mdgRecommendation = sections.get('mdgRecommendation')
  mdgRecommendationSelectedOption = mdgRecommendation.get('options')
    .find (option) -> option.get('value') is mdgRecommendation.get('selectedOption')
  mdgRecommendationContent = mdgRecommendation.get('content')
  recommendationFieldsOrder = ['strengthOfEvidence', 'levelOfConfidence']
  recommendationTitle =  sections.getIn ['recommendation', 'recommendationTitle'], ''

  openEvidenceInNewWindow = useCoffeeCallback [question], ->
    mediator.services.mdgService.openInNewWindow(i18n, question)

  emptySections = useCoffeeMemo [sections], ->
    tempContainer = document.createElement 'div'
    emptyMap = sections.reduce (acc, section, sectionName) ->
      content = section.get 'content'
      if _.isEmpty content
        acc[sectionName] = true
      else if _.isString content
        acc[sectionName] = false
      else
        tempContainer.innerHTML = rawContentToHTML content
        acc[sectionName] = _.isEmpty tempContainer.innerText.trim()
      acc
    , {}
    removeHTMLNode tempContainer
    emptyMap

  <div className="mdg-recommendation">
    {onAddComment and <AddSectionCommentButton onClick={onAddComment} />}
    <div className="question mt-10 mb-10">
      {recommendationTitle or question.get('question')}
    </div>
    {mdgRecommendationSelectedOption && (
      <div className="mt-10 mb-10 bold">
        {mdgRecommendationSelectedOption.get('text')}
      </div>
    )}
    {not emptySections['mdgRecommendationContent'] && (
      <div className="recommendation-text mt-10 mb-10">
        <GdtEditor
          customDecorators={EditorDecorators.referenceDecorator}
          editorContent={getImmutableJsOrStringContent mdgRecommendationContent}
          readOnly
        />
      </div>
    )}
    {recommendationFieldsOrder.map (recommendationField) ->
      fieldValue = sections.getIn(['recommendation', recommendationField])
      fieldValue && (
        <div
          key={recommendationField}
          className="recommendation-section recommendation-text mt-10 mb-10 flex"
        >
          <div className="recommendation-section-description flex-none">
            {i18n "fields.#{recommendationField}"}
          </div>
          <div>
            {i18n "values.#{recommendationField}.#{fieldValue}"}
          </div>
        </div>
      )
    }
    {sectionsNames.map (sectionName) ->
      showLinks = sectionName is 'evidence' and not _.isEmpty questionLinks
      section = sections.get(sectionName)
      sectionContent = section.get('content')
      (not emptySections[sectionName] or showLinks) and (
        <div
          key={sectionName}
          className="recommendation-section recommendation-text mt-10 mb-10 flex"
        >
          <div className="recommendation-section-description flex-none">
            {section.get('description')}
            {sectionName is 'evidence' and mode is 'regular' and <button
              className="see-evidence-button block"
              onClick={openEvidenceInNewWindow}
            >
              {i18n "/actions.see_evidence"}
            </button>}
          </div>
          <div>
            {not emptySections[sectionName] and <GdtEditor
              customDecorators={EditorDecorators.referenceDecorator}
              editorContent={getImmutableJsOrStringContent sectionContent}
              readOnly
            />}
            {showLinks and <div>
              {_.map questionLinks, (questionLink, idx) ->
                linkTitle = if questionLinks.length is 1
                  i18n '/es:recommendations.mdg_see_evidence'
                else
                  i18n '/es:recommendations.mdg_see_evidence_with_number', number: idx + 1
                <p key={idx}>
                  <a
                    className="evidence-link"
                    href={questionLink}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {linkTitle}
                  </a>
                </p>
              }
            </div>}
          </div>
        </div>
      )
    }
  </div>

MdgRecommendationSectionContent.propTypes =
  mode: PropTypes.oneOf(['export', 'regular'])
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  questionLinks: PropTypes.arrayOf(PropTypes.string)
  recommendation: PropTypes.instanceOf(Immutable.Map).isRequired

MdgRecommendationSectionContent.defaultProps =
  mode: 'regular'
  questionLinks: []

module.exports = MdgRecommendationSectionContent
