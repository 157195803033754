var Code, INITIAL_TAGS, NameWithCodes, QUESTION_MGMT_TAGS, arrayOf, convertToList, docShape, docTypes, documentId, extendedParse, initial, mapOf, number, oneOf, optional, overarchingQuestionDocShape, prepareTags, shape, string, _ref, _ref1, _ref2;

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

_ref = require('lib/db_docs/field_types/type_decorators'), initial = _ref.initial, extendedParse = _ref.extendedParse;

mapOf = require('lib/db_docs/field_types/map_of');

oneOf = require('lib/db_docs/field_types/one_of');

shape = require('lib/db_docs/field_types/shape');

optional = shape.typeDecorators.optional;

_ref1 = require('lib/db_docs/field_types/built_in_types'), string = _ref1.string, number = _ref1.number;

QUESTION_MGMT_TAGS = require('lib/overarching_question_helper').QUESTION_MGMT_TAGS;

_ref2 = require('lib/question_tags_helper'), prepareTags = _ref2.prepareTags, convertToList = _ref2.convertToList;

Code = require('lib/db_docs/field_types/code_type');

NameWithCodes = require('lib/db_docs/field_types/name_with_codes_type');

INITIAL_TAGS = prepareTags(QUESTION_MGMT_TAGS);

overarchingQuestionDocShape = docShape({
  author: initial('')(string),
  docType: initial(docTypes.OVERARCHING_QUESTION)(string),
  includedTags: extendedParse(convertToList)(initial(INITIAL_TAGS)(arrayOf(NameWithCodes))),
  includedQuestions: initial([])(arrayOf(documentId)),
  metadata: initial({})(mapOf(arrayOf(string))),
  question: initial('')(string),
  recommendationIds: initial([])(arrayOf(documentId)),
  setting: initial('')(string),
  coi: optional(string),
  background: optional(string),
  perspective: optional(string),
  type: initial('overarching:management')(oneOf(['overarching:diagnostic', 'overarching:management'])),
  orderNumber: optional(number)
});

module.exports = overarchingQuestionDocShape;
