var DbDoc, QUESTIONS_DB_VIEW, prepareDiagnosticOutcomes, questionDocShape, setDxOutcomes, _ref;

DbDoc = require('lib/db_docs/db_doc');

questionDocShape = require('lib/db_docs/doc_shapes/question_doc_shape');

_ref = require('lib/questions_helper'), QUESTIONS_DB_VIEW = _ref.QUESTIONS_DB_VIEW, prepareDiagnosticOutcomes = _ref.prepareDiagnosticOutcomes;

setDxOutcomes = function(doc) {
  if ((doc != null ? doc.type : void 0) === 'diagnostic' && _.isEmpty(doc != null ? doc.outcomes : void 0)) {
    return R.assoc('outcomes', prepareDiagnosticOutcomes(), doc);
  } else {
    return doc;
  }
};

module.exports = DbDoc(questionDocShape, {
  dbSetup: {
    dbView: {
      name: 'questions',
      view: QUESTIONS_DB_VIEW
    }
  },
  middlewares: {
    create: setDxOutcomes,
    createMany: setDxOutcomes
  },
  methods: {
    setRecommendationsIds: function(recIds) {
      return this.setAt('recommendationIds')(recIds);
    },
    setQualityIndicatorId: function(id) {
      return this.setAt('qualityIndicatorsId')(id);
    },
    toggleCadthPublication: function() {
      return this.updateAt('cadthPublication')(function(enabled) {
        return !(enabled != null ? enabled : false);
      });
    }
  }
});
